import React, {Component} from "react";
import Menu from "../components/menu";
import * as Images from "../components/images";
import * as Constants from "../utils/constants";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import axios from "axios";
import {NotificationManager} from 'react-notifications';
import {confirmAlert} from 'react-confirm-alert';
import LanguageSwitch from "../components/languageSwitch";
import {withTranslation} from "react-i18next"; // Import
import {isTablet} from 'react-device-detect';

const tableHeader = [
  {
    title: "No",
  },
  {
    title: 'setting.station_id',
  },
  {
    title: "user.table.header_6",
  },
];

class ChargingStation extends Component {
  constructor(props) {
    super(props);
    const {t, i18n} = props;
    this.state = {
      user: JSON.parse(localStorage.getItem("user")),
      chargingStation: '',
      existedStations: [],
      loadingStatus: 'loading',
      isShowMenu: false,
      developerMode: localStorage.getItem("developerMode") === 'true',
      // resizeWindow
      width: 0,
      height: 0,
    }
    this.refBodyTable = React.createRef();
    this.refHeaderTable = React.createRef();

    this.getChargingStationList();
    this.handleChangedeveloperMode.bind(this);
    document.documentElement.style.overflow = 'hidden';
  }

  componentDidMount() {
    window.addEventListener('resize', this.useWindowSize.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.useWindowSize.bind(this));
  }

  componentDidUpdate(prevProps, prevStates) {
    if(prevStates.width !== this.state.width || prevStates.height !== this.state.height || this.state.width === 0 || this.state.loadingStatus === 'OK') {
      this.resizeTable();
    }
  }

  useWindowSize() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  resizeTable() {
    const scrollHeight = this.refBodyTable.current?.scrollHeight;
    const clientHeight = this.refBodyTable.current?.clientHeight;
    const headerTable = this.refHeaderTable.current;
    if(headerTable) {
      if(scrollHeight > clientHeight) {
        headerTable.style.paddingRight = '16px';
      } else {
        headerTable.style.paddingRight = 0;
      }
    }
  }

  getHeader(type) {
    let headers = {
      'Content-Type': type,
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': '*',
      'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
      'Authorization': 'Bearer ' + JSON.parse(
          localStorage.getItem('user')).accessToken
    }
    return {headers: headers};
  }

  getChargingStationList() {
    axios.get(Constants.CHARGING_STATION_URL,
        this.getHeader('application/json'))
    .then(res => {
      this.setState({existedStations: res.data});
      if (res.data === null || res.data.length === 0) {
        this.setState({loadingStatus: 'NOK'});
      } else {
        this.setState({loadingStatus: 'OK'});
      }
    }).catch(function (error) {

    });
  }

  handleChangedeveloperMode(event) {
    this.setState({ developerMode: event.target.checked });
    localStorage.setItem("developerMode", event.target.checked);
  }

  renderItemHeaderTable(data, t) {
    return data.map((item, index) => {
      return <div className="clmn" key={index}>{t(item.title)}</div>;
    });
  }

  renderItemTable(data, t) {
    return data.map((item, index) => {
      return (
          <div className="list-item" key={index}>
            <div className="clmn">
              {index + 1}
            </div>
            <div className="clmn">
              {item.stationId}
            </div>
            <div className="actions clmn">
              <button 
                className="btn-small delete position-relative"
                onClick={this.deleteChargingStation.bind(this, item, t)}
              >
                <div className="fix-icon-center-ios">
                  <Images.Icons name='IconDelete' color='#707070'/>
                </div>
              </button>
            </div>
          </div>
      );
    });
  }

  handleChange(field, e) {
    let object = this.state;
    object[field] = e.target.value;
    this.setState({object});
  }

  deleteChargingStation(item, t) {
    confirmAlert({
      title: t('setting.do_you_want_delete_it'),
      message: '',
      buttons: [
        {
          label: t('common.ok'),
          onClick: () => {
            axios.delete(Constants.CHARGING_STATION_DELETE_URL, {
              headers: this.getHeader('application/json').headers,
              params: {stationId: item.id}
            })
            .then(response => {
              this.getChargingStationList();
              NotificationManager.success(t('setting.charging_station_deleted'),
                  '', 2000);
            }).catch(function (error) {
              NotificationManager.error(
                  t('setting.could_not_add_charging_station'),
                  "", 2000);
            });

          }
        },
        {
          label: t('common.back')
        }
      ]
    });

  }

  addChargingStation(t, e) {
    e.preventDefault();
    this.form.validateAll();
    if (this.state.chargingStation.trim() == "") {
      //NotificationManager.error(t('setting.could_not_add_email'), "", 2000);
      return;
    }
    if (document.getElementsByClassName("input-error").length === 0) {
      axios.post(Constants.CHARGING_STATION_ADD_URL,
          {stationId: this.state.chargingStation.toUpperCase()},
          this.getHeader('application/json'))
      .then(response => {
        this.state.chargingStation = "";
        this.getChargingStationList();

        NotificationManager.success(t('setting.charging_station_added'), '',
            2000);
        setTimeout(function () {
          let divsToHide = document.getElementsByClassName("input-error"); //divsToHide is an array
          for (let i = 0; i < divsToHide.length; i++) {
            divsToHide[i].style.visibility = "hidden"; // or
          }
        }, 100);
      }).catch(function (error) {
        if (error.response.data.message === "station_existed") {
          NotificationManager.error(t('setting.charging_station_existed'),
              "", 2000);
        } else {
          NotificationManager.error(t('setting.could_not_add_charging_station'),
              "", 2000);
        }
      });
    } else {
      NotificationManager.error(t('setting.could_not_add_charging_station'),
          "", 2000);
    }

  }

  handleShowMenu() {
    this.setState({isShowMenu: !this.state.isShowMenu});
  }

  render() {
    const {t, i18n} = this.props;
    const {isShowMenu} = this.state;
    return (
        <>
          <div className={"dps-menu-hamburger-button " + (isTablet
              ? ("dps-menu-hamburger-button-tablet " + (isShowMenu ? ''
                  : 'dps-menu-hamburger-button-not-show')) : '')}
               onClick={() => this.handleShowMenu()}>
            <Images.HamburgerButton/>
          </div>
          <div className={"dps-menu " + (isTablet ? ("dps-menu-tablet "
              + (isShowMenu ? '' : 'dps-menu-not-show')) : '')}>
            <Menu currentRouter={this.props.location.pathname}/>
          </div>
          {
              isShowMenu && isTablet &&
              <div className="dps-menu-back-ground"></div>
          }

          <div className={"dps-content " + (isTablet ? "dps-content-tablet"
              : '')}>
            <div className="dps-content-area">
              <div className="dps-title main-title">
                <h1>{t('menu-admin.setting')}</h1>
                <LanguageSwitch />
                <div className="d-flex align-content-center detail_setting_mode">
                  <p className="mr-3 mb-0 d-flex align-items-center" style={{color: this.state.developerMode ? '#6E34AA' : null, marginRight: '10px'}}>
                    {t("common.developer_mode")}
                  </p>
                  <label className="switch mb-0">
                    <input
                      type="checkbox"
                      checked={Boolean(this.state.developerMode)}
                      onChange={this.handleChangedeveloperMode.bind(this)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div className="user-option">
                  <a href="/setting/avatar" className="btn-setting">
                    <Images.Icons name="IconSetting" />
                  </a>
                  <span className="textAva">{this.state.user.corpName}</span>
                  <img className="image" alt="user logo"
                      src={this.state.user.avatar ? Constants.getResourceUrl()
                        + this.state.user.avatar : Constants.getResourceUrl() + Constants.CORP_LOGO_DEFAULT}
                      />
                </div>
              </div>
              <div className=" dps-main-content dps-main-content-setting" style={{height: `calc(100vh - 60px - 30px)`}}>
                <div className="dps-main-content-area">

                  <div className="dps-tabs">
                    <a href="/setting/avatar">{t(
                        'setting.corporate_intelligence')}</a>
                    <a href="/setting/email">{t('setting.email_management')}</a>
                    <a href="/setting/station" className="active">{t(
                        'setting.charging_station')}</a>
                  </div>

                  <div className="dps-tabs-content">
                    <div className="dps-main-content-area flex-column">
                      <Form
                        className="add-item f-row f-center position-relative"
                        ref={c => { this.form = c }}
                        onSubmit={this.addChargingStation.bind(this, t)}
                      >
                          
                        <Input
                            type="text"
                            className="dps-input"
                            name="charging-station"
                            placeholder={t('setting.station_id')}
                            value={this.state.chargingStation}
                            message={t('common.message_input')}
                            onChange={this.handleChange.bind(this,
                                "chargingStation")}
                            maxLength="6"
                        />
                        <button type="submit" className="btn-save position-relative">
                            {t("common.add_st")}
                        </button>
                      </Form>

                      {
                        {
                          loading: (
                            <div className="loading">
                              <img src={require("../assets/images/loading.gif")} alt="loading" />
                            </div>
                          ),
                          NOK: (
                            <h1 className='txt-red'>
                              {t('setting.please_add_a_charging_station')}
                            </h1>
                          ),
                          OK: (
                            <div className='dps-list-f table-setting'>
                              <div className='header' ref={this.refHeaderTable}>
                                {this.renderItemHeaderTable(tableHeader, t)}
                              </div>
                              <div className="body" ref={this.refBodyTable}>
                                {this.renderItemTable(this.state.existedStations, t)}
                              </div>
                            </div>
                          )
                        }[this.state.loadingStatus]
                      }
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </>
    );
  }
}

export default withTranslation()(ChargingStation);
