import React, { Component } from "react";
import Menu from "../components/menu";
import * as Images from "../components/images";
import * as Constants from "../utils/constants";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import axios from "axios";
import * as Validator from "../utils/validator";
import { NotificationManager } from "react-notifications";
import { withTranslation } from "react-i18next";
import LanguageSwitch from "../components/languageSwitch";
import { isTablet } from "react-device-detect";
import { isValidHttpUrl } from "../utils/utils";
import { doGet, doPost } from "../services/api.service";

class Avatar extends Component {
  constructor(props) {
    super(props);
    const { t, i18n } = props;
    this.state = {
      user: JSON.parse(localStorage.getItem("user")),
      loadingStatus: "loading",
      avatarChange: false,
      selectedFile: false,
      isShowMenu: false,
      developerMode: localStorage.getItem("developerMode") === 'true',
      notificationUrlError: "",
      notificationUrl: "",
    };
    this.btnSub = React.createRef()

    this.handleSave.bind(this);
    this.handleShowMenu.bind(this);
    this.fakeLoad();
    this.handleChangedeveloperMode.bind(this);
    document.documentElement.style.overflow = "hidden";
  }

  componentDidMount() {
    console.log("componentDidMount");
    const params = {
      id: this.state.user.id
    }
    doGet(
      Constants.GET_NOTIFICATION_FOR_DOWNLOAD_MEDIA_URL,
      params,
      (res) => {
        console.log("res", res);
        this.setState({
          notificationUrl: res.data
        });
      },
      (error) => console.log(error)
    )
  }

  fakeLoad() {
    const p = new Promise((resolve) => {
      window.setTimeout(() => {
        resolve("OK");
      }, 0);
    });

    p.then((res) => this.setState({ loadingStatus: "OK" }));
  }

  handleChangedeveloperMode(event) {
    this.setState({ developerMode: event.target.checked });
    localStorage.setItem("developerMode", event.target.checked);
  }

  getHeader(type) {
    let headers = {
      "Content-Type": type,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "*",
      "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
    };
    return { headers: headers };
  }
  fileUploadButton() {
    document.getElementById("fileUpload").click();
  }

  handleChangeFile(e) {
    let user = this.state.user;
    user["file"] = e.target.files[0];
    var reader = new FileReader();
    var url = reader.readAsDataURL(user["file"]);
    reader.onloadend = function (e) {
      this.state.selectedFile = true;
      user["avatar"] = reader.result;
      this.setState({ user: user });
    }.bind(this);
    this.btnSub.current.click()
  }
  handleSave(e, t) {
    e.preventDefault();
    let header = this.getHeader("multipart/form-data");

    var formData = new FormData();

    for (var key in this.state.user) {
      formData.append(key, this.state.user[key]);
    }

    let request = Constants.CHANGE_AVATAR_URL + this.state.user.id;

    axios
      .post(request, formData, {
        headers: header.headers,
        params: { uuid: this.state.user.uuid },
      })
      .then((response) => {
        let user = JSON.parse(localStorage.getItem("user"));
        user.avatar = response.data.avatar;
        localStorage.setItem("user", JSON.stringify(user));
        this.setState({
          avatarChange: true,
          selectedFile: false,
        });
        NotificationManager.success(t("avatar.notifi_success"), "", 2000);
      })
      .catch(function (error) {
        NotificationManager.error(t("avatar.notifi_error"), "", 2000);
      });
  }

  handleShowMenu() {
    this.setState({ isShowMenu: !this.state.isShowMenu });
  }

  handleChangeNotificationUrl(e) {
    const value = e.target.value;
    this.setState({ notificationUrl: value });
    const isValidUrl = isValidHttpUrl(value);
    if (!isValidUrl && value !== "") {
      this.setState({ notificationUrlError: "Invalid URL" });
    } else {
      this.setState({ notificationUrlError: "" });
    }
  }

  saveNotificationUrl(t) {
    if (this.state.notificationUrl !== null && this.state.notificationUrlError === "") {
      const requestBody = {
        id: this.state.user.id,
        notificationUrl: this.state.notificationUrl
      }

      doPost(
        Constants.CHANGE_NOTIFICATION_FOR_DOWNLOAD_MEDIA_URL,
        requestBody,
        () => { NotificationManager.success(t("setting.update_success"), "", 2000) },
        () => { NotificationManager.error(t("setting.update_fail"), "", 2000) },
      )
    }
  }

  render() {
    const { t, i18n } = this.props;
    const { isShowMenu } = this.state;
    return (
      <>
        <div
          className={
            "dps-menu-hamburger-button " +
            (isTablet
              ? "dps-menu-hamburger-button-tablet " +
              (isShowMenu ? "" : "dps-menu-hamburger-button-not-show")
              : "")
          }
          onClick={() => this.handleShowMenu()}
        >
          <Images.HamburgerButton />
        </div>
        <div
          className={
            "dps-menu " +
            (isTablet
              ? "dps-menu-tablet " + (isShowMenu ? "" : "dps-menu-not-show")
              : "")
          }
        >
          <Menu
            currentRouter={this.props.location.pathname}
            avatarChange={this.state.avatarChange}
          />
        </div>
        {isShowMenu && isTablet && <div className="dps-menu-back-ground"></div>}

        <div
          className={"dps-content " + (isTablet ? "dps-content-tablet" : "")}
        >
          <div className="dps-content-area">
            <div className="dps-title main-title">
              <h1 className="margin-0">{t("avatar.main_title")}</h1>
              <LanguageSwitch />
              <div className="d-flex align-content-center detail_setting_mode">
                <p className="mr-3 mb-0 d-flex align-items-center" style={{color: this.state.developerMode ? '#6E34AA' : null, marginRight: '5px'}}>
                  {t("common.developer_mode")}
                </p>
                <label className="switch mb-0">
                  <input
                    type="checkbox"
                    checked={Boolean(this.state.developerMode)}
                    onChange={this.handleChangedeveloperMode.bind(this)}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
              <div className="user-option">
                <a href="/setting/avatar" className="btn-setting">
                  <Images.Icons name="IconSetting" />
                </a>
                <span className="textAva">{this.state.user.corpName}</span>
                <img className="image" alt="user logo"
                    src={this.state.user.avatar ? Constants.getResourceUrl()
                      + this.state.user.avatar : Constants.getResourceUrl() + Constants.CORP_LOGO_DEFAULT}
                    />
              </div>
            </div>
            <div className=" dps-main-content dps-main-content-setting" style={{height: `calc(100vh - 60px - 30px)`}}>
              <div className="dps-main-content-area">
                <div className="dps-tabs">
                  <a href="/setting/avatar" className="active">
                    {t("avatar.setting_avatar")}
                  </a>
                  <a href="/setting/email">{t("avatar.setting_mail")}</a>
                  <a href="/setting/station">{t("setting.charging_station")}</a>
                </div>

                <div className="dps-tabs-content admin-setting">
                  <Form
                    onSubmit={(e) => this.handleSave(e, t)}
                    ref={(c) => {
                      this.form = c;
                    }}
                    className="form-icon"
                  >
                    <div className="list-icon">
                      <div className="item">
                        <div
                          className="preview-image"
                          onClick={() => this.fileUploadButton()}
                        >
                          {this.state.user.avatar &&
                            this.state.user.avatar !== "" ? (
                            this.state.user.avatar.includes("data:image") ? (
                              <img alt="back" src={this.state.user.avatar} />
                            ) : (
                              <img
                                alt="back"
                                src={
                                  Constants.getResourceUrl() +
                                  this.state.user.avatar
                                }
                              />
                            )
                          ) : (
                            ""
                          )}
                          <p>{t("avatar.upload_image")}</p>
                        </div>

                        <Input
                          id="fileUpload"
                          type="file"
                          className="dps-input "
                          name="flightPlanName"
                          placeholder={t("avatar.place_holder")}
                          accept="image/*"
                          validations={[Validator.required]}
                          onChange={this.handleChangeFile.bind(this)}
                        />
                      </div>
                      <div className="item">
                        <span className="textAva">{this.state.user.corpName}</span>
                        <button
                          className="dps-button hidden"
                          // disabled={!this.state.selectedFile}
                          ref={this.btnSub}
                        >
                          <span>{t("avatar.button_update")}</span>
                        </button>
                      </div>
                    </div>
                  </Form>

                  <div className="d-flex flex-column mt-3 align-content-center notification-url">
                    <p className="mr-3 mb-0 d-flex align-items-center" style={{font: "400 12px 'Inter', sans-serif"}}>
                      {t("avatar.user_notification_url") + ": "}
                    </p>
                    <div className="ml-4 d-flex notification-url-input">
                      <input
                        type="text"
                        onChange={this.handleChangeNotificationUrl.bind(this)}
                        value={this.state.notificationUrl}
                      />
                      <button type="submit" onClick={this.saveNotificationUrl.bind(this, t)}>
                        {t('common.save')}
                      </button> 
                    </div>
                    <div className="ml-4 notification-url-error">
                      <span>{this.state.notificationUrlError}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withTranslation()(Avatar);
