import { useRef, useEffect, memo, useState } from "react";
import * as Images from "../images";
import { useTranslation } from "react-i18next";

const SelectCustom = ({planNameSelected, data, onChange}) => {
  const { t } = useTranslation();
  const textSelected = useRef(null);
  const selectBox = useRef(null);

  const [planSelected, setPlanSelected] = useState(null)

  useEffect(() => {
    function handleClick(event) {
      if (!event.target.closest(".custom-select")) {
        selectBox.current.classList.remove("active");
        selectBox.current.classList.remove("drop-up");
      }
    }

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  const handleSelect = (e) => {
    const hasActiveClass = selectBox.current.classList.contains("active");
    const hasDropUpClass = selectBox.current.classList.contains("drop-up");

    if(hasDropUpClass) {
      selectBox.current.classList.remove("drop-up");
    }

    if (!hasActiveClass) {
      const windowHeight = window.innerHeight;
      const dropdownPosition = e.target.getBoundingClientRect().top;
      const dropdownHeight = selectBox.current.clientHeight;

      if (dropdownPosition + dropdownHeight + 50 > windowHeight) {
        selectBox.current.classList.add("drop-up");
      } else {
        selectBox.current.classList.remove("drop-up");
      }
    }

    selectBox.current.classList.toggle("active");
  };

  const handleSelected = (data) => {
    onChange(data.planNo, data.name)
    setPlanSelected(data.planNo + " / " + data.name)
    selectBox.current.classList.remove("active");
    selectBox.current.classList.remove("drop-up");
  };

  return (
    <div class="custom-select">
      <Images.IconScan />
      <div className="select-content">
        <div class="selected" onClick={handleSelect}>
          <span class="text" ref={textSelected}>
            {planSelected || planNameSelected || t("schedule.label.select")}
          </span>
        </div>
        <div class="select-box" ref={selectBox}>  
          <ul class="select-list">
            {data.map((item, index)=> {
              return (
                <li key={item.id} data-row={index} data-value={item.name} onClick={() => handleSelected(item)}>
                  {item.planNo} / {item.name}
                </li>
              )
            })}
          </ul>
        </div>
      </div>
      <Images.IconSelect color="currentColor" />
    </div>
  );
}
export default memo(SelectCustom);