import { memo, useState, useRef, useEffect, useMemo, useCallback } from "react";
import { AgGridReact } from 'ag-grid-react';
import { Modal } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import { t } from "i18next";
import { CSVLink } from 'react-csv';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import * as Images from "../images";
import ClinCellEditor from "./customCellEditor";

const faceField = {
  faceNo: {
    field: "faceNo",
    label: "Face No.",
    isIntVal: true
  },
  depth: {
    field: "depth",
    label: "Depth (m)",
    isFloatVal: true
  },
  client_field1: {
    field: "client_field1",
    label: "client_field1",
  },
  client_field2: {
    field: "client_field2",
    label: "client_field2"
  },
}
const markerField = {
  markerId: {
    field: "markerId",
    label: "Marker ID",
    isIntVal: true
  },
  X: {
    field: "X",
    label: "X (m)",
    isFloatVal: true
  },
  Y: {
    field: "Y",
    label: "Y (m)",
    isFloatVal: true
  },
  Z: {
    field: "Z",
    label: "Z (m)",
    isFloatVal: true
  },
  client_field1: {
    field: "client_field1",
    label: "client_field1"
  },
  client_field2: {
    field: "client_field2",
    label: "client_field2"
  },
}

const faceKirahaMode = "face";
const markerKirihaMode = "marker";

const NoRowsOverlay = () => {
  return (
    <div
      className="text-no-data"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        fontSize: '16px',
        color: '#888',
      }}
    >
      No data available
    </div>
  );
};

const KirihaEditTableComponent = (props) => {
  const { isOpenEditKiriha, setIsOpenEditKiriha, kirihaData, handleUpdate, csvUploadedInfo, mode, dataCsv } = props;

  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [undoSize, setUndoSize] = useState(0);
  const [isInValid, setIsInValid] = useState(false);

  const initFaceKirahaTable = [
    {
      headerName: faceField.faceNo.label,
      field: faceField.faceNo.field,
      cellDataType: "number",
      cellEditor: params => <ClinCellEditor params={params} onChangeIsInValid={setIsInValid} />,
      valueSetter: params => {
        setIsInValid(false);
        const { newValue, colDef: { min, max, field } } = params;
        const val = parseInt(newValue);
        
        if (val < min || val > max) return false;
        params.data[field] = isNaN(val) ? null : val;
        return true;
      },
      width: 100,
      min: 0,
      max: 999,
    },
    {
      headerName: faceField.depth.label,
      field: faceField.depth.field,
      cellDataType: "number",
      cellEditor: params => <ClinCellEditor params={params} onChangeIsInValid={setIsInValid} />,
      valueSetter: params => {
        setIsInValid(false);
        const { newValue, colDef: { min, max, field } } = params;
        const val = parseFloat(newValue).toFixed(1);
        if (val < min || val > max) return false;
        params.data[field] = isNaN(val) ? null : +val;
        return true;
      },
      valueFormatter: params => {
        const { value } = params;
        return value || value === 0 ? value.toFixed(1) : null;
      },
      width: 110,
      min: 0.0,
      max: 9999.9,
    },
    {
      headerName: faceField.client_field1.label,
      field: faceField.client_field1.field,
      cellDataType: "string",
      cellEditor: params => <ClinCellEditor params={params} onChangeIsInValid={setIsInValid} />,
      valueSetter: params => {
        setIsInValid(false);
        const { newValue, colDef: { maxLength, field } } = params;
        const length = newValue.length;
        if (length > maxLength) return false;
        params.data[field] = newValue;
        return true;
      },
      maxLength: 64
    },
    {
      headerName: faceField.client_field2.label,
      field: faceField.client_field2.field,
      cellDataType: "string",
      cellEditor: params => <ClinCellEditor params={params} onChangeIsInValid={setIsInValid} />,
      valueSetter: params => {
        setIsInValid(false);
        const { newValue, colDef: { maxLength, field } } = params;
        const length = newValue.length;
        if (length > maxLength) return false;
        params.data[field] = newValue;
        return true;
      },
      maxLength: 64
    },
  ];
  const initMarkerKirahaTable = [
    {
      headerName: markerField.markerId.label,
      field: markerField.markerId.field,
      cellDataType: "number",
      cellEditor: params => <ClinCellEditor params={params} onChangeIsInValid={setIsInValid} />,
      valueSetter: params => {
        setIsInValid(false);
        const { newValue, colDef: { min, max, field } } = params;
        const val = parseInt(newValue);
        if (val < min || val > max) return false;
        params.data[field] = isNaN(val) ? null : val;
        return true;
      },
      width: 110,
      min: 0,
      max: 999
    },
    {
      headerName: markerField.X.label,
      field: markerField.X.field,
      cellDataType: "number",
      cellEditor: params => <ClinCellEditor params={params} onChangeIsInValid={setIsInValid} />,
      valueSetter: params => {
        setIsInValid(false);
        const { newValue, colDef: { min, max, field } } = params;
        const val = parseFloat(newValue).toFixed(1);
        if (val < min || val > max) return false;
        params.data[field] = isNaN(val) ? null : +val;
        return true;
      },
      valueFormatter: params => {
        const { value } = params;
        return value || value === 0 ? value.toFixed(1) : null;
      },
      width: 100,
      min: 0.0,
      max: 9999.9
    },
    {
      headerName: markerField.Y.label,
      field: markerField.Y.field,
      cellDataType: "number",
      cellEditor: params => <ClinCellEditor params={params} onChangeIsInValid={setIsInValid} />,
      valueSetter: params => {
        setIsInValid(false);
        const { newValue, colDef: { min, max, field } } = params;
        const val = parseFloat(newValue).toFixed(1);
        if (val < min || val > max) return false;
        params.data[field] = isNaN(val) ? null : +val;
        return true;
      },
      valueFormatter: params => {
        const { value } = params;
        return value || value === 0 ? value.toFixed(1) : null;
      },
      width: 100,
      min: 0.0,
      max: 9999.9
    },
    {
      headerName: markerField.Z.label,
      field: markerField.Z.field,
      cellDataType: "number",
      cellEditor: params => <ClinCellEditor params={params} onChangeIsInValid={setIsInValid} />,
      valueSetter: params => {
        setIsInValid(false);
        const { newValue, colDef: { min, max, field } } = params;
        const val = parseFloat(newValue).toFixed(1);
        if (val < min || val > max) return false;
        params.data[field] = isNaN(val) ? null : +val;
        return true;
      },
      valueFormatter: params => {
        const { value } = params;
        return value || value === 0 ? value.toFixed(1) : null;
      },
      width: 100,
      min: 0.0,
      max: 9999.9
    },
    {
      headerName: markerField.client_field1.label,
      field: faceField.client_field1.field,
      cellDataType: "string",
      cellEditor: params => <ClinCellEditor params={params} onChangeIsInValid={setIsInValid} />,
      valueSetter: params => {
        setIsInValid(false);
        const { newValue, colDef: { maxLength, field } } = params;
        const length = newValue.length;
        if (length > maxLength) return false;
        params.data[field] = newValue;
        return true;
      },
      maxLength: 64
    },
    {
      headerName: markerField.client_field2.label,
      field: faceField.client_field2.field,
      cellDataType: "string",
      cellEditor: params => <ClinCellEditor params={params} onChangeIsInValid={setIsInValid} />,
      valueSetter: params => {
        setIsInValid(false);
        const { newValue, colDef: { maxLength, field } } = params;
        const length = newValue.length;
        if (length > maxLength) return false;
        params.data[field] = newValue;
        return true;
      },
      maxLength: 64
    },
  ];

  const defaultColDef = useMemo(() => {
    return {
      editable: true,
      enableCellChangeFlash: true,
      sortable: false,
      resizable: false,
    };
  }, []);

  const heightTable = useMemo(() => {
    if (rowData.length) {
      return {
        height: 40 + rowData.length * 40 + 3
      }
    }
    return {
      height: 80
    }
  }, [rowData])

  const containerRef = useRef(null);
  const gridRef = useRef(null);
  const actionsRowRef = useRef(null);

  useEffect(() => {
    let fieldMode;
    switch (mode) {
      case faceKirahaMode:
        fieldMode = faceField;
        setColumnDefs(initFaceKirahaTable);
        break;
      case markerKirihaMode:
        fieldMode = markerField;
        setColumnDefs(initMarkerKirahaTable);
        break;
      default: break;
    }

    if (kirihaData.length) {
      const kirihaDataHandle = [...kirihaData];
      kirihaDataHandle.shift();
      const rowDataCustom = kirihaDataHandle.map((item) => {
        const itemMap = item.split(',');
        const record = Object.keys(fieldMode).reduce((prev, val, ind) => ({
          ...prev,
          [val]: itemMap[ind] ?
            fieldMode[val].isIntVal ? parseInt(itemMap[ind]) :
            fieldMode[val].isFloatVal ? parseFloat(itemMap[ind]) :
            itemMap[ind]
            : undefined
        }), {})
        return record;
      })

      setRowData(rowDataCustom);
    } else {
      setRowData([]);
    }
  }, [kirihaData])

  useEffect(() => {
    if (containerRef.current) {
      const gridWidth = columnDefs.reduce((prev, item) => {
        return prev + item.width;
      }, 0)
      containerRef.current.style.width = `${gridWidth + 2}px`;
    }
  }, [rowData, columnDefs])

  const onCellValueChanged = useCallback((params) => {
    const undoSize = params.api.getCurrentUndoSize();
    setUndoSize(undoSize);
  }, [])

  const onGridReady = () => {
    const columnWidth = columnDefs.reduce((prev, item) => {
      return prev + item.width;
    }, 2);
    containerRef.current.style.width = `${columnWidth}px`;
  }

  const handleClearAllKirihaTable = () => {
    confirmAlert({
      title: "Do you want to delete all the data?",
      message: "",
      buttons: [
        {
          label: t("common.back"),
        },
        {
          label: "OK",
          onClick: () => {
            setRowData([]);
            setUndoSize(undoSize + 1);
          },
        },
      ],
    });
  }

  const handleSaveKirihaTable = async () => {
    const realRowData = [...rowData].map(item => {
      return Object.values(item).join(',');
    })
    const header = columnDefs.map(item => item.headerName).join(',');
    realRowData.unshift(header);

    const isUpdateSuccess = await handleUpdate(realRowData, mode);
    isUpdateSuccess && setUndoSize(0);
  }

  const handleAddNewRow = (index) => {
    const fields = columnDefs.map(item => item.field);
    
    const record = fields.reduce((prev, item) => {
      return { ...prev, [item]: undefined };
    }, {})

    const newRowData = [...rowData];
    newRowData.splice(index + 1, 0, record)

    setRowData(newRowData);
    setUndoSize(undoSize + 1);
  }

  const handleDelRow = (index) => {
    const rowDataClone = [...rowData];
    rowDataClone.splice(index, 1);

    setRowData(rowDataClone);
    setUndoSize(undoSize + 1);
  }

  const handleScrollBody = (event) => {
    const { top } = event;

    if (actionsRowRef.current) {
      actionsRowRef.current.scrollTop = top;
    }
  }

  const handleCloseModal = () => {
    if (undoSize) {
      confirmAlert({
        title: t("privacy_supports.confirm_alert"),
        message: "",
        buttons: [
          {
            label: t("common.ok"),
            onClick: () => {
              handleSaveKirihaTable();
              setIsOpenEditKiriha(false);
            },
          },
          {
            label: t("common.no"),
            onClick: () => {
              setIsOpenEditKiriha(false);
            },
          },
          {
            label: t("common.back"),
          },
        ],
      });
    } else {
      setIsOpenEditKiriha(false);
    }
  }

  return (
    <Modal
      show={isOpenEditKiriha}
      onHide={handleCloseModal}
      centered
      dialogClassName="kiriha-modal-wrapper"
      fullscreen
      animation={false}
    >
      <Modal.Header>
        <h3>
          {
            {
              "face": "Face",
              "marker": "Marker"
            }[mode]
          } data
        </h3>
        {csvUploadedInfo &&
          <CSVLink
            filename={csvUploadedInfo.filename}
            data={dataCsv}
            className="btn-download-csv"
          >
            <Images.IconDownloadCsv color="currentColor" /> CSV
          </CSVLink>
        }
        <button className="btn-clear-data" onClick={handleClearAllKirihaTable} disabled={!rowData.length}>Clear all data</button>
        <button className="btn-save-csv" onClick={handleSaveKirihaTable} disabled={!undoSize}>Save</button>
        <button className="btn-close-edit" onClick={handleCloseModal}><Images.IconCloseSensors /></button>
        {isInValid &&
          <span className="mess-invalid">Enter a valid value</span>
        }
      </Modal.Header>
      <Modal.Body className="kiriha-modal-body">
        <div className="kiriha-container">
          <div
            className="ag-theme-alpine"
            ref={containerRef}
            style={{...heightTable, maxHeight: '100%'}}
          >
            <AgGridReact
              ref={gridRef}
              rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              undoRedoCellEditing={true}
              onCellValueChanged={onCellValueChanged}
              stopEditingWhenCellsLoseFocus={true}
              onGridReady={onGridReady}
              headerHeight={40}
              rowHeight={40}
              onBodyScroll={handleScrollBody}
              noRowsOverlayComponent={NoRowsOverlay}
              suppressMovableColumns={true}
            />
          </div>
          <div className="actions-row" ref={actionsRowRef}>
            {rowData.length ? 
              rowData.map((_, index) => (
                <div className="item">
                  <button className="btn-add-row" onClick={() => handleAddNewRow(index)}>＋</button>
                  <button className="btn-del-row" onClick={() => handleDelRow(index)}>－</button>
                </div>
              ))
              :
              <div className="item">
                <button className="btn-add-row" onClick={() => handleAddNewRow(-1)}>＋</button>
              </div>
            }
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default memo(KirihaEditTableComponent);