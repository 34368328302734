import { useRef, useState } from "react";

export const PanelGroup = ({ children, minMain, minSub, defaultMain, onLayout, className }) => {
  const [ratioHeightMain, setRatioHeightMain] = useState(defaultMain || 50);

  const panelWrapper = useRef(null);

  const startResize = (e) => {
    const wrapHeight = panelWrapper.current.offsetHeight;
    
    e.preventDefault();
    window.addEventListener('mousemove', handleResize);
    window.addEventListener('mouseup', stopResize);

    const style = document.createElement('style');
    style.type = 'text/css';

    let css = "";


    function handleResize(event) {
      const mainRef = document.getElementById('panel-main');
      if (mainRef) {
        const newHeight = event.clientY - mainRef.getBoundingClientRect().top;
        
        const newRatioHeightMain = Number((newHeight / wrapHeight * 100).toFixed(2));

        // style cursor
        if(minMain && minMain > newRatioHeightMain) {
          css = '* { cursor: s-resize !important }';
        } else if (minSub && minSub > 100 - newRatioHeightMain) {
          css = '* { cursor: n-resize !important }';
        } else {
          css = '* { cursor: ns-resize !important }';
        }

        if (style.styleSheet) {
          style.styleSheet.cssText = css;
        } else {
          style.appendChild(document.createTextNode(css));
        }

        document.head.appendChild(style);

        // handle ratio
        if (!(minMain || minSub)) {
          if (newRatioHeightMain > 0 && newRatioHeightMain < 100) {
            setRatioHeightMain(newRatioHeightMain);
          }
        }

        if (!minSub && minMain && minMain < newRatioHeightMain) {
          setRatioHeightMain(newRatioHeightMain);
        }

        if (!minMain && minSub && minSub < 100 - newRatioHeightMain) {
          setRatioHeightMain(newRatioHeightMain);
        }

        if(
          minMain && 
          minSub &&
          minMain < newRatioHeightMain &&
          minSub < 100 - newRatioHeightMain
        ) {
          setRatioHeightMain(newRatioHeightMain);
        }

        onLayout([newRatioHeightMain, 100 - newRatioHeightMain])
      }
    }

    function stopResize() {
      window.removeEventListener('mousemove', handleResize);
      window.removeEventListener('mouseup', stopResize);

      document.head.removeChild(style);
    }
  };

  return (
    <div className={'panel-wrap' + (className ? ` ${className}` : "")}
      style={{ 
        '--main': ratioHeightMain,
        '--sub': 100 - ratioHeightMain,
      }}
      ref={panelWrapper}
    >
      <PanelHandle startResize={startResize} className="panel-resize-handle" />
      {children}
    </div>
  )
}

export const Panel = ({ children, onDoubleClick, className, id, style }) => {
  return (
    <div
      id={(id ? id : "")}
      className={"panel card-content" + (className ? ` ${className}` : "")}
      onDoubleClick={onDoubleClick}
      style={{...style}}
    >
      {children}
    </div>
  )
}

const PanelHandle = ({ children, startResize, className }) => {
  return (
    <div
      className={"resize-handle" + (className ? ` ${className}` : "")}
      onMouseDown={(e) => startResize(e)}
    >
      {children}
    </div>
  )
}