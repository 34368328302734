import React from "react";
import ReactDOM from "react-dom";
import App from "./navigation/App";
import * as serviceWorker from "./serviceWorker";
import {I18nextProvider} from 'react-i18next';
import i18n from "i18next";
import lang_en from "./i18n/en"
import lang_ja from "./i18n/ja"
import {DEFAULT_LANGUAGE} from "./utils/constants"
import "bootstrap/scss/bootstrap.scss"
import "./assets/scss/main.scss";
import { createRoot } from "react-dom/client"

require('typeface-roboto');

i18n.init({
  lng: localStorage.getItem('lang') ? localStorage.getItem('lang')
      : DEFAULT_LANGUAGE,
  // fallbackLng: localStorage.getItem('lang') ?  localStorage.getItem('lang') : DEFAULT_LANGUAGE,
  interpolation: {escapeValue: false},  // React already does escaping
  debug: false,
  // have a common namespace used around the full app
  ns: ["dps"],
  defaultNS: "dps",
  resources: {
    en: {dps: lang_en},
    ja: {dps: lang_ja}
  }
});

const root = createRoot(document.getElementById("root"))

root.render(
  <I18nextProvider i18n={i18n}>
    <App/>
  </I18nextProvider>
);

serviceWorker.unregister();
