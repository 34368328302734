import { Modal } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import * as Constants from "../../utils/constants";
import * as Images from "../images";
import { timeSmallConverter } from "../../utils/utils";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const PreviewGallery = (props) => {
  const { t } = useTranslation();
  const { mediaList, mediaTime, mediaPreview, isOpen, handleClose, currentMarker } = props;
  const [activePreviewImage, setActivePreviewImage] = useState(0);

  useEffect(() => {
    setActivePreviewImage(0);
  }, [isOpen]);

  const handleImagePreviewClick = (index) => {
    setActivePreviewImage(index);
  };

  const handleNextPreviewImg = (index) => {
    if (index === mediaPreview.length - 1) {
      setActivePreviewImage(0);
    } else {
      setActivePreviewImage(index + 1);
    }
  };

  const handlePrePreviewImg = (index) => {
    if (index === 0) {
      setActivePreviewImage(mediaPreview.length - 1);
    } else {
      setActivePreviewImage(index - 1);
    }
  };

  if (mediaList && mediaPreview) {
    //get image content;
    return (
      <Modal
        className="edit-form image-preview"
        show={isOpen}
        onHide={handleClose}
        size="lg"
        style={{zIndex: 999999}}
      >
        <Modal.Header closeButton>
          <Modal.Title className="image-title-font">
            <span>{currentMarker}</span>
            {mediaTime ? timeSmallConverter(mediaTime, t) : ""}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="gallery">
            <div className="img-area">
              {mediaPreview.length > 0 ? (
                <img
                  src={
                    Constants.getResourceUrl() +
                    mediaPreview[activePreviewImage].raw
                  }
                  alt=""
                  className="img-preview"
                />
              ) : (
                ""
              )}
            </div>
            <div className="gallery-slide">
              {mediaPreview.map((item, index) => {
                return (
                  <div className="d-flex flex-column m-auto" key={index}>
                    <div
                      className={
                        "img-item " +
                        (activePreviewImage === index ? "active" : "")
                      }
                      style={{
                        backgroundImage:
                          "url(" + Constants.getResourceUrl() + item.hd + ")",
                      }}
                      onClick={() => handleImagePreviewClick(index)}
                    />
                    <label className="text-center m-0 time-up">
                      {item.utc ? 
                        mediaTime ? timeSmallConverter(item.utc, t) : ""
                        :
                        mediaTime ? timeSmallConverter(mediaTime, t) : ""
                      }
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="actions">
            <button onClick={() => handlePrePreviewImg(activePreviewImage)}>
              <Images.IconArrowHorizontal color="currentColor" />
            </button>
            <button onClick={() => handleNextPreviewImg(activePreviewImage)}>
              <Images.IconArrowHorizontal color="currentColor" />
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  } else {
    return "";
  }
};

export default PreviewGallery;
