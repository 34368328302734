import React, {useState, useEffect} from 'react';
import Pagination from 'react-bootstrap/Pagination';
import * as Images from "./images";
import { useTranslation } from 'react-i18next';

const maxSize = 5;

const PaginationComponent = ({total, pageSize, pageNumber, handleChangePageSize, handleChangePagination}) => {
  const { t, i18n } = useTranslation();

  const [totalPages, setTotalPages] = useState(0);
  const [isCheckMaxSize, setIsCheckMaxSize] = useState(false);

  useEffect(() => {
    setTotalPages(Math.ceil(total / pageSize));
  }, [total, pageSize])

  useEffect(() => {
    if(maxSize < totalPages) {
      setIsCheckMaxSize(true)
    }
  }, [totalPages])

  const handlePageChange = (index) => {
    handleChangePagination(index)
  }
  
  const renderPagination = () => {
    return(
      <>
        {isCheckMaxSize && totalPages > maxSize &&
          <Pagination.First onClick={() => handlePageChange(1)} disabled={pageNumber === 1} />
        }
        {
          totalPages > 1 &&
          <Pagination.Prev
            onClick={() => handlePageChange(pageNumber - 1)}
            disabled={pageNumber === 1}
          />
        }

        {renderPaginationItem()}

        {
          totalPages > 1 &&
          <Pagination.Next
            onClick={() => handlePageChange(pageNumber + 1)}
            disabled={pageNumber === totalPages}
          />
        }
        {isCheckMaxSize && totalPages > maxSize &&
          <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={pageNumber === totalPages} />
        }
      </>
    )
  }

  const renderPaginationItem = () => {
    const paginationItems = []
    let totalPgaesRender;

    if(isCheckMaxSize && totalPages > maxSize) {
      if(pageNumber <= maxSize - 2) {
        totalPgaesRender = maxSize - 1;
        for (let i = 1; i <= totalPgaesRender; i++) {
          paginationItems.push(
            <Pagination.Item key={i} active={i === pageNumber} onClick={() => handlePageChange(i)} disabled={totalPages === 1}>
              {i}
            </Pagination.Item>
          );
        }
        paginationItems.push(<Pagination.Ellipsis key={'ellipsis-next'} disabled/>);
      } else if(pageNumber > totalPages - (maxSize - 2)) {
        totalPgaesRender = maxSize - 1;
        paginationItems.push(<Pagination.Ellipsis key={'ellipsis-prev'} disabled/>);
        for (let i = totalPages - (maxSize - 2); i <= totalPages; i++) {
          paginationItems.push(
            <Pagination.Item key={i} active={i === pageNumber} onClick={() => handlePageChange(i)} disabled={totalPages === 1}>
              {i}
            </Pagination.Item>
          );
        }
      } else {
        totalPgaesRender = maxSize - 2;
        paginationItems.push(<Pagination.Ellipsis key={'ellipsis-prev'} disabled/>);
        for (let i = pageNumber - Math.floor((maxSize - 2) / 2); i <= pageNumber + Math.floor((maxSize - 2) / 2); i++) {
          paginationItems.push(
            <Pagination.Item key={i} active={i === pageNumber} onClick={() => handlePageChange(i)} disabled={totalPages === 1}>
              {i}
            </Pagination.Item>
          );
        }
        paginationItems.push(<Pagination.Ellipsis key={'ellipsis-next'} disabled/>);
      }
    } else {
      totalPgaesRender = totalPages;
      for (let i = 1; i <= totalPgaesRender; i++) {
        paginationItems.push(
          <Pagination.Item key={i} active={i === pageNumber} onClick={() => handlePageChange(i)} disabled={totalPages === 1}>
            {i}
          </Pagination.Item>
        );
      }
    }

    return paginationItems
  }

  return (
    <div className='pagination-component'>
      <label>{t('Search_Pagination.pageNum')}</label>
      <div className="mask-select">
        <select
          defaultValue={pageSize}
          onChange={handleChangePageSize}
        >
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
        <Images.Icons name="IconSelect" color="currentColor" />
      </div>
      <Pagination className="paginationInfo">
        {renderPagination()}
      </Pagination>
    </div>
  );
};

export default PaginationComponent;