import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import Menu from "../components/menu";
import { useTranslation } from "react-i18next";
import * as Images from "../components/images";
import { doDelete, doGet, getHeader } from "../services/api.service";
import * as Constants from "../utils/constants";

import cloneDeep from "lodash/cloneDeep";
import axios from "axios";
import { isTablet } from "react-device-detect";
import { timeConverter } from "../utils/utils";
import ScheduleActions from "../components/schedule/scheduleActions";
import { NotificationManager } from "react-notifications";
import { confirmAlert } from "react-confirm-alert";
import SearchTable from "../components/searchTable";
import PaginationComponent from "../components/pagination";

export default function Schedule(props) {
  const initialScheduleItem = {
    id: null,
    valid_flag: false,
    drone_id: "",
    minute: "",
    hour: "",
    day: "",
    month: "",
    dayOfWeek: "",
    marker: false,
    plan_no: "",
    scheduleName: "",
  };
  const tableHeader = [
    "schedule.label.name",
    "schedule.label.flight_schedule",
    "schedule.label.status",
    "schedule.label.drone",
    "schedule.label.flight_plan",
    "schedule.label.number_of_flights",
    "schedule.label.last_flight_date_and_time",
  ];

  const initialFormSearch = {
    pageSize: 20,
    pageNumber: 1,
    direction: 'desc',
    sort: 'utc',
    scheduleName: null,
    deviceNo: null,
    planName: null,
    dateFrom: null,
    dateTo: null,
  }

  const { t, i18n } = useTranslation();

  const [loadingStatus, setLoadingStatus] = useState('loading'); // loading, OK, NOK
  const [lstSchedule, setLstSchedule] = useState([]);
  const [editScheduleItem, setEditScheduleItem] = useState(initialScheduleItem);
  const [droneIds, setDroneIds] = useState([]);
  const [actions, setActions] = useState(null); // list, edit, add
  const [planNoList, setPlanNoList] = useState([]);
  const [isShowMenu, setIsShowMenu] = useState(false);
  const [user, setUser] = useState({});
  const [isSort, setIsSort] = useState(false);
  const [sortName, setSortName] = useState("");
  // Search Table and Pagination
  const [pageSize, setPageSize] = useState(20);
  const [pageNumber, setPageNumber] = useState(1);
  const [scheduleTotal, setScheduleTotal] = useState(0);
  const [uniqDeviceNo, setUniqDeviceNo] = useState([]);
  const [formSearch, setFormSearch] = useState(initialFormSearch);
  // resizeWindow
  const [size, setSize] = useState([0, 0]);

  const refBodyTable = useRef();
  const refHeaderTable = useRef();

  useEffect(() => {
    document.documentElement.style.overflow = "hidden";
    setUser(JSON.parse(localStorage.getItem("user")));
    fetchData();
    if (props.history.action === "PUSH") {
      if(props.location.state.isAddSchedulePage){
        setActions("add");
      } else if(props.location.state.data) {
        handleSlectedSchedule(props.location.state.data)
      }
    } else {
      setActions("list");
    }
  }, []);

  useEffect(() => {
    const params = {
      ...formSearch,
      pageSize: pageSize,
      pageNumber: pageNumber,
    }
    getListSchedule(params)
  }, [pageNumber, pageSize, formSearch])

  useEffect(() => {
    resizeTable();
  }, [useWindowSize()])

  useEffect(() => {
    if(loadingStatus === 'OK') {
      resizeTable()
    }
  }, [lstSchedule])

  function useWindowSize() {
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

  const resizeTable = () => {
    const scrollHeight = refBodyTable.current?.scrollHeight;
    const clientHeight = refBodyTable.current?.clientHeight;
    const headerTable = refHeaderTable.current;

    if(headerTable) {
      if(scrollHeight > clientHeight) {
        headerTable.style.paddingRight = '16px';
      } else {
        headerTable.style.paddingRight = 0;
      }
    }
  }

  const fetchData = () => {
    const promiseDroneIdList = axios.get(Constants.LIST_DEVICE_URL, getHeader());
    const promisePlanList = axios.get(Constants.PLAN_LIST_URL, getHeader());
    const promiseFullSchedule = axios.get(Constants.SCHEDULE_URL, getHeader());

    Promise.all([
      promiseDroneIdList,
      promisePlanList,
      promiseFullSchedule
    ]).then((responseArr) => {
      setUniqDeviceNo([...new Set(responseArr[2].data.takeoff_schedule.map(item => item.drone_id))])
      setDroneIds(responseArr[0].data);
      const lstId = responseArr[1].data ? responseArr[1].data : [];
      setPlanNoList(lstId);
    });
  };

  function getListSchedule(params) {
    doGet(Constants.SCHEDULE_SEARCH_URL, params, (res) => {
      if(res.data.items && res.data.items.length > 0) {
        setLoadingStatus('OK')
        setLstSchedule(res.data.items);
        setScheduleTotal(res.data.totalItems)
      } else {
        setLoadingStatus('NOK')
      }
    }, () => {
      setLoadingStatus('NOK')
    });
  }

  const renderItemHeaderTable = (data, t) => {
    return data.map((item, index) => {
      if (
        item.includes("status") ||
        item.includes("drone") ||
        item.includes("flight_plan") ||
        item.includes("flight_schedule")
      ) {
        return (
          <div
            onClick={() => sort(item)}
            className="list_sort clmn"
            key={index}
            style={{ cursor: "pointer", userSelect: "none", color: "#473DAF" }}
          >
            <div className="l-sort">
              {t(item)}
              <span
                style={{
                  transform: `${
                    isSort && item.includes(sortName) ? "rotate(180deg)" : ""
                  }`,
                }}
              >
                <Images.IconSort color="currentColor" />
              </span>
            </div>
          </div>
        );
      }
      return <div className="clmn" key={index}>{t(item)}</div>;
    });
  };

  const renderItemTable = (data, t) => {
    return data.map((item, index) => {
      return (
        <div
          className="list-item"
          key={index}
          onClick={() => handleSlectedSchedule(item)}
          style={{ cursor: "pointer" }}
        >
          <div className="clmn">{item.scheduleName}</div>
          <div className="clmn">{item.utc !== "false" ? timeConverter(item.utc, t) : undefined}</div>
          <div className="clmn">
            <span style={item.active ? { color: "#198754", background: "#19875425" } : null}>
              {item.active ? t("schedule.valid") : t("schedule.invalid")}
            </span>
          </div>
          <div className="clmn">
            <Images.IconPlane />
            {item.deviceType} {item.deviceNo}
          </div>
          <div className="clmn">
            {item.planName && 
              <>
                <Images.IconScan /> 
                {item.planName}
              </>
            }
          </div>
          <div className="clmn">
            <span>{item.flightNumber}</span>
          </div>
          <div className="clmn">
            {item.flightLastDate
              ? timeConverter(item.flightLastDate, t)
              : undefined}
          </div>
        </div>
      );
    });
  };

  const handleAddSchedule = () => {
    setActions("add");
  };

  const handleDelete = (e, item) => {
    e.preventDefault();

    confirmAlert({
      title: t("setting.do_you_want_delete_it"),
      message: "",
      buttons: [
        {
          label: t("common.ok"),
          onClick: () => {
            let request = Constants.SCHEDULE_UD_URL + item.id;
            doDelete(request, () => {
              NotificationManager.success(
                t("dialog_delete.confirm_success"),
                "",
                2000
              );
              setActions('list')
              getListSchedule(initialFormSearch);
            }, () => {
              NotificationManager.error(
                t("dialog_delete.confirm_error"),
                "",
                2000
              );
            })
          },
        },
        {
          label: t("common.back"),
        },
      ],
    });
  };

  const handleSlectedSchedule = (item) => {
    axios.get(Constants.SCHEDULE_UD_URL + item.id).then(res => {
      const editItem = res.data;
      const editSchedule = {
        id: editItem.id,
        valid_flag: editItem.active,
        drone_id: editItem.deviceNo,
        minute: editItem.minute,
        hour: editItem.hour,
        day: editItem.day,
        month: editItem.month,
        dayOfWeek: editItem.dayOfWeek,
        marker: editItem.planMarker,
        plan_no: editItem.planNo,
        utc: editItem.utc,
        scheduleName: editItem.scheduleName,
      };

      setEditScheduleItem(editSchedule);
      setActions("edit");
    })
  };

  const renderListSchedule = (t) => {
    return (
      <>
        <div className="dps-main-content-area schedule-content ">
          {
            {
              loading: (
                <div className="loading">
                  <img
                    alt="loading"
                    src={require("../assets/images/loading.gif")}
                  />
                </div>
              ),
              NOK: (
                <h1 className="txt-red no-data text-center w-100 padding-top-label">
                  {t("schedule.label.no_schedule")}
                </h1>
              ),
              OK: (
                <>
                  <div className="wrap_table">
                    <div className="dps-list-f">
                      <div className="header" ref={refHeaderTable}>
                        {renderItemHeaderTable(tableHeader, t)}
                      </div>
                      <div className="body" ref={refBodyTable}>
                        {renderItemTable(lstSchedule, t)}
                      </div>
                    </div>
                  </div>
                  <div className="footer-page">
                    <p className="total-list">{scheduleTotal} {t('schedule.total')}</p>
                    <PaginationComponent 
                      total={scheduleTotal} 
                      pageSize={pageSize} 
                      pageNumber={pageNumber}
                      handleChangePagination={handleChangePagination} 
                      handleChangePageSize={handleChangePageSize}
                    />
                  </div>
                </>
              ),
            }[loadingStatus]
          }
        </div>
      </>
    );
  };

  const handleSearch = (event, dataSearch) => {
    event.preventDefault();
    
    setFormSearch({
      ...formSearch,
      pageNumber: 1,
      ...dataSearch
    })
    setPageNumber(1)
  }

  const handleChangePagination = (curentPage) => {
    setPageNumber(curentPage)
  }

  const handleChangePageSize = (e) => {
    setPageSize(e.target.value)
    setPageNumber(1)
  }

  const renderScheduleActions = (action) => {
    return (
      <ScheduleActions
        action={action}
        handleSubmitSuccess={handleSubmitSuccess}
        droneIds={droneIds}
        planNoList={planNoList}
        editScheduleDataItem={editScheduleItem}
        handleDelete={handleDelete}
      />
    );
  };

  const handleSubmitSuccess = () => {
    setActions("list");
    getListSchedule(initialFormSearch);
  };

  const handleShowMenu = () => {
    setIsShowMenu(!isShowMenu);
  };

  const sort = (fieldName) => {
    let sortNameCurent, direction, iSort = false
    if (fieldName.includes("status")) {
      sortNameCurent = "active";
      setSortName("status")
    } else if (fieldName.includes("drone")) {
      sortNameCurent = "deviceNo";
      setSortName("drone")
    } else if (fieldName.includes("flight_plan")) {
      sortNameCurent = "planName";
      setSortName("flight_plan")
    } else if (fieldName.includes("flight_schedule")) {
      sortNameCurent = "utc"
      setSortName("flight_schedule")
    }

    if(sortName === fieldName) {
      iSort = !isSort
    } else {
      iSort = true
    }

    direction = iSort ? 'asc' : 'desc'

    setIsSort(iSort)
    setSortName(fieldName)

    const params = {...formSearch, pageNumber: 1, pageSize, sort: sortNameCurent, direction}
    getListSchedule(params)
  };

  return (
    <>
      <div
        className={
          "dps-menu-hamburger-button " +
          (isTablet
            ? "dps-menu-hamburger-button-tablet " +
              (isShowMenu ? "" : "dps-menu-hamburger-button-not-show")
            : "")
        }
        onClick={() => handleShowMenu()}
      >
        <Images.HamburgerButton />
      </div>
      <div
        className={
          "dps-menu " +
          (isTablet
            ? "dps-menu-tablet " + (isShowMenu ? "" : "dps-menu-not-show")
            : "")
        }
      >
        <Menu currentRouter={props.location.pathname} />
      </div>
      {isShowMenu && isTablet && <div className="dps-menu-back-ground"></div>}
      <div className={"dps-content " + (isTablet ? "dps-content-tablet" : "")}>
        <div className="dps-content-area" style={{display: 'flex', flexDirection: 'column'}}>
          {actions === "list" &&
            <div className="schedule-header">
              <SearchTable 
                data={[
                  {
                    name: 'scheduleName',
                    label: t("Search_Pagination.schedule_name"),
                    typeSearch: 'text',
                  },
                  {
                    name: 'deviceNo',
                    label: t("Search_Pagination.device_no"),
                    typeSearch: 'select',
                    subData: uniqDeviceNo
                  },
                  {
                    name: 'planName',
                    label: t('Search_Pagination.plan_name'),
                    typeSearch: 'text',
                  },
                  {
                    name: 'dateFrom dateTo',
                    label: t('Search_Pagination.flight_date'),
                    typeSearch: 'date',
                  },
                ]}
                formSearch={formSearch}
                onSearch={handleSearch}
                className={actions === 'list' ? undefined : 'hidden'}
              />

              <div className="schedule-actions">
                <button
                  className="dps-button btn-add-schedule"
                  style={{ color: "#000", textDecoration: "none" }}
                  onClick={() => handleAddSchedule()}
                >
                  <Images.Icons name="IconAddSquare" />
                  {t("common.add")}
                </button>
              </div>
            </div>
          }

          {/* <button
                className="delete"
                onClick={(e) => handleDelete( e, editScheduleItem)}
              >
                <img src={require("../assets/images/delete.png")} alt="" />
              </button> */}

          <div
            className={`dps-main-content ${
              actions === "add" ? "schedule_add" : "schedule"
            }`}
          >
            {actions === "list"
              ? renderListSchedule(t)
              : renderScheduleActions(actions)}
          </div>
        </div>
      </div>
    </>
  );
}
