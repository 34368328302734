import React, {useState, useEffect} from 'react';
import {withTranslation} from "react-i18next";
import axios from "axios";
import { Link } from 'react-router-dom';
import ReactQuill from 'react-quill';
import * as Constants from "../utils/constants";
import * as Images from "../components/images";
import { timeConverter } from '../utils/utils'
import { isMobile } from "react-device-detect";

const TermsOfUse = (props) => {
  const {t} = props
  const [editorState, setEditorState] = useState(null)
  const [updateAt, setUpdateAt] = useState(null)

  useEffect(() =>{
    axios.get(Constants.CONTENT_URL + 'terms-of-use').then((res) => {
      if (res.data.content) {
        setEditorState(res.data.content)
        setUpdateAt(Date.parse(res.data.updateAt) / 1000)
      }
    })
  }, [])
  return (
    <div className={"dps-content-area public_route " + (isMobile ? "public_route_mobile" : '')}>
      <div className="nav_public_route">
        <h1 className="logo">
          <img
            alt="spiral logo"
            src={Images.Logo}
          />
        </h1>

        
        <Link className='btn-login' to='/'>{t("login.button")}</Link>
      </div>
      <h2>{t("privacy_supports.terms_of_service")}</h2>
      {updateAt && <p>{t('privacy_supports.last_update')} {timeConverter(updateAt, t)}</p>}
      <ReactQuill 
        theme="snow" 
        value={editorState} 
        readOnly
        modules={{
          toolbar: false
        }}
      />
    </div>
  );
};

export default withTranslation("dps")(TermsOfUse);