import React from 'react';
import {useDrag} from 'react-dnd';
import MarkerEditPlan from '../plan/makerEditPlan';

function getStyles(props, isDragging) {
  if (props.displayType == 'drag-area') {
    return {
      margin: 0,
    };
  } else {
    return {
      margin: 0,
      opacity: props.isDragChange ? 1 : 0
    };
  }
}

export const Marker = (props) => {
  const [{isDragging}, drag] = useDrag({
    item: {type: 'marker', ...props},

    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  if (isDragging) {
    return null;
  }

  if (props.displayType === 'drag-area') {
    return (
      <div ref={drag} id={'makerId' + props.id} style={getStyles(props, isDragging)}>
        <MarkerEditPlan {...props} />
      </div>
    );
  } else {
    return (
      <div ref={drag} className='marker_wrap' style={getStyles(props, isDragging)}>
        <MarkerEditPlan {...props} />
      </div>
    );
  }
};
