import React, {Component} from "react";
import * as Constants from "../utils/constants";
import axios from "axios";
import {NotificationManager} from 'react-notifications';

import SockJsClient from 'react-stomp';

//import SockJsClient from 'react-stomp';

export default class Testing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      corpNo: 102,
      droneNo: 1,
      flightPlanNo: 101,
      flightNo: 1595980000,
      orthermessage: 'thong tin khac '

    }
  }

  handleDroneStatusData(data) {
    let result = JSON.parse(data);
    //console.log(result);
  }

  getHeader(type) {
    let headers = {
      'Content-Type': type,
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': '*',
      'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
      'appkey': 'eyJhbGciOiJIUzI1NiJ9.eyJuYW1lIjoiUGhhbiBUdWFuIiwiaWF0IjoxNTE2MjM5MDIyfQ.4kRajk02G8fwDSn-P_KI1XJt2Yu3rcddWibvfHvOKYA'
    }
    return headers;
  }

  handleChange(field, e) {
    let object = this.state;
    object[field] = e.target.value;
    this.setState({object});
  }

  registerDrone() {
    axios.get(Constants.getBackendUrl() + "api/drone/flight_plan", {
      headers: this.getHeader(), params: {
        corp_id: this.state.corpNo,
        drone_no: this.state.droneNo,
        flight_plan_no: this.state.flightPlanNo,
      }
    })
    .then(res => {
      NotificationManager.success('Success', 2000);
    }).catch(function (error) {
      NotificationManager.error('Fail', 'common.error', 2000);
    });
  }

  sendNoneCharing() {
    axios.post(Constants.getBackendUrl() + "api/drone/status", {
      corp_id: this.state.corpNo,
      drone_no: this.state.droneNo,
      flight_plan_no: this.state.flightPlanNo,
      "flight_no": false,
      "status": "none_charging",
      "msg": {
        "utc": Math.round(new Date().getTime() / 1000),
        "battery": this.state.message,
        "other_msg": this.state.orthermessage
      },
      "email_flg": false

    }, {headers: this.getHeader()})
    .then(res => {
      NotificationManager.success('Success', 2000);
    }).catch(function (error) {
      NotificationManager.error('Fail', 'common.error', 2000);
    });
  }

  sendCharging() {
    axios.post(Constants.getBackendUrl() + "api/drone/status", {
      corp_id: this.state.corpNo,
      drone_no: this.state.droneNo,
      flight_plan_no: this.state.flightPlanNo,
      "flight_no": false,
      "status": "charging",
      "msg": {
        "utc": Math.round(new Date().getTime() / 1000),
        "battery": this.state.message,
        "other_msg": this.state.orthermessage
      },
      "email_flg": false
    }, {headers: this.getHeader()})
    .then(res => {
      NotificationManager.success('Success', 2000);
    }).catch(function (error) {
      NotificationManager.error('Fail', 'common.error', 2000);
    });
  }

  sendReady() {
    axios.post(Constants.getBackendUrl() + "api/drone/status", {
      corp_id: this.state.corpNo,
      drone_no: this.state.droneNo,
      flight_plan_no: this.state.flightPlanNo,
      "flight_no": false,
      "status": "ready",
      "msg": {
        "utc": Math.round(new Date().getTime() / 1000),
        "battery": "100%",
        "other_msg": this.state.orthermessage
      },
      "email_flg": false
    }, {headers: this.getHeader()})
    .then(res => {
      NotificationManager.success('Success', 2000);
    }).catch(function (error) {
      NotificationManager.error('Fail', 'common.error', 2000);
    });
  }

  sendAlert() {
    axios.post(Constants.getBackendUrl() + "api/drone/status", {
      corp_id: this.state.corpNo,
      drone_no: this.state.droneNo,
      flight_plan_no: this.state.flightPlanNo,
      "flight_no": false,
      "status": "alert",
      "msg": {
        "utc": Math.round(new Date().getTime() / 1000),
        "msg": this.state.message,
        "battery": "10%" + Math.round(new Date().getTime() / 1000),
        "other_msg": this.state.orthermessage
      },
      "email_flg": true
    }, {headers: this.getHeader()})
    .then(res => {
      NotificationManager.success('Success', 2000);
    }).catch(function (error) {
      NotificationManager.error('Fail', 'common.error', 2000);
    });
  }

  sendTakeoff() {
    axios.post(Constants.getBackendUrl() + "api/drone/status", {
      corp_id: this.state.corpNo,
      drone_no: this.state.droneNo,
      flight_plan_no: this.state.flightPlanNo,
      "flight_no": this.state.flightNo,
      "status": "takeoff",
      "msg": {
        "utc": Math.round(new Date().getTime() / 1000),
        "battery": "10%" + Math.round(new Date().getTime() / 1000),
        "other_msg": this.state.orthermessage
      },
      "email_flg": false
    }, {headers: this.getHeader()})
    .then(res => {
      NotificationManager.success('Success', 2000);
    }).catch(function (error) {
      NotificationManager.error('Fail', 'common.error', 2000);
    });
  }

  sendMakerArv() {
    axios.post(Constants.getBackendUrl() + "api/drone/status", {
      corp_id: this.state.corpNo,
      drone_no: this.state.droneNo,
      flight_plan_no: this.state.flightPlanNo,
      "flight_no": this.state.flightNo,
      "status": "marker_arv",
      "msg": {
        "utc": Math.round(new Date().getTime() / 1000),
        "id": this.state.message,
        "battery": "10%" + Math.round(new Date().getTime() / 1000),
        "other_msg": this.state.orthermessage
      },
      "email_flg": false
    }, {headers: this.getHeader()})
    .then(res => {
      NotificationManager.success('Success', 2000);
    }).catch(function (error) {
      NotificationManager.error('Fail', 'common.error', 2000);
    });
  }

  sendMakerLeft() {
    axios.post(Constants.getBackendUrl() + "api/drone/status", {
      corp_id: this.state.corpNo,
      drone_no: this.state.droneNo,
      flight_plan_no: this.state.flightPlanNo,
      "flight_no": this.state.flightNo,
      "status": "marker_left",
      "msg": {
        "utc": Math.round(new Date().getTime() / 1000),
        "id": this.state.message,
        "battery": "10%" + Math.round(new Date().getTime() / 1000),
        "other_msg": this.state.orthermessage
      },
      "email_flg": false
    }, {headers: this.getHeader()})
    .then(res => {
      NotificationManager.success('Success', 2000);
    }).catch(function (error) {
      NotificationManager.error('Fail', 'common.error', 2000);
    });
  }

  sendLanding() {
    axios.post(Constants.getBackendUrl() + "api/drone/status", {
      corp_id: this.state.corpNo,
      drone_no: this.state.droneNo,
      flight_plan_no: this.state.flightPlanNo,
      "flight_no": this.state.flightNo,
      "status": "landed",
      "msg": {
        "utc": Math.round(new Date().getTime() / 1000),
        "other_msg": this.state.orthermessage
      },
      "email_flg": false
    }, {headers: this.getHeader()})
    .then(res => {
      NotificationManager.success('Success', 2000);
    }).catch(function (error) {
      NotificationManager.error('Fail', 'common.error', 2000);
    });
  }

  sendImage() {
    let formData = new FormData();
    formData.append("corp_id", this.state.corpNo);
    formData.append("drone_no", this.state.droneNo);
    formData.append("flight_plan_no", this.state.flightPlanNo);
    formData.append("flight_no", this.state.flightNo);
    formData.append("flight_marker_id", this.state.message);

    let files = document.querySelector('input[type="file"]').files;

    Array.prototype.forEach.call(files, function (file) {
      formData.append("files", file);
    });

    //formData.append("files", files);
    console.log(files)
    axios.post(Constants.getBackendUrl() + 'api/drone/upload', formData,
        {headers: this.getHeader()}).then(res => {
      NotificationManager.success('Success', 2000);
    }).catch(function (error) {
      NotificationManager.error('Fail', 'common.error', 2000);
    });
  }

  render() {
    return (
        <>
          <SockJsClient url={Constants.getBackendUrl() + 'drone-status'}
                        topics={['/topic/drone/' + this.state.droneNo]}
                        onConnect={() => {
                          console.log("connected" + this.state.droneNo);
                        }}
                        onMessage={(msg) => {
                          console.log(msg);
                        }}
                        ref={(client) => {
                          this.clientRef = client
                        }}/>
          <p>corpNo</p>
          <input
              type="text"
              className="dps-input"
              name="email"
              placeholder="Corp No"
              value={this.state.corpNo}
              onChange={this.handleChange.bind(this, "corpNo")}
          />
          <p>flightPlanNo</p>
          <input
              type="text"
              className="dps-input"
              placeholder="Flight Plan No"
              value={this.state.flightPlanNo}
              onChange={this.handleChange.bind(this, "flightPlanNo")}
          />

          <p>flightNo</p>
          <input
              type="text"
              className="dps-input"
              placeholder="Flight No"
              value={this.state.flightNo}
              onChange={this.handleChange.bind(this, "flightNo")}
          />

          <p>droneNo</p>
          <input
              type="text"
              className="dps-input"
              placeholder="Drone No"
              value={this.state.droneNo}
              onChange={this.handleChange.bind(this, "droneNo")}
          />

          <p>Baterry / marker-id</p>

          <input
              type="text"
              className="dps-input"
              placeholder="message"
              value={this.state.message}
              onChange={this.handleChange.bind(this, "message")}
          />

          <p>Baterry / marker-id</p>

          <input
              type="text"
              className="dps-input"
              placeholder="orthermessage"
              value={this.state.orthermessage}
              onChange={this.handleChange.bind(this, "orthermessage")}
          />


          <p> Files</p>
          <input
              type="file"
              className="dps-input"
              placeholder="upload file"
              value={this.state.files}
              multiple
              onChange={this.handleChange.bind(this, "files")}
          />


          <button type="submit" className=""
                  onClick={this.registerDrone.bind(this)}>
            Register Drone
          </button>

          <button type="submit" className=""
                  onClick={this.sendNoneCharing.bind(this)}>
            Send Non-charging
          </button>
          <button type="submit" className=""
                  onClick={this.sendCharging.bind(this)}>
            Send charging
          </button>

          <button type="submit" className=""
                  onClick={this.sendReady.bind(this)}>
            Send ready
          </button>

          <button type="submit" className=""
                  onClick={this.sendAlert.bind(this)}>
            Send alert
          </button>
          <button type="submit" className=""
                  onClick={this.sendTakeoff.bind(this)}>
            Send takeoff
          </button>

          <button type="submit" className=""
                  onClick={this.sendMakerArv.bind(this)}>
            Send maker arv
          </button>

          <button type="submit" className=""
                  onClick={this.sendMakerLeft.bind(this)}>
            Send maker left
          </button>

          <button type="submit" className=""
                  onClick={this.sendLanding.bind(this)}>
            Send landing
          </button>

          <button type="submit" className=""
                  onClick={this.sendImage.bind(this)}>
            Send photo
          </button>
          <button type="submit" className=""
                  onClick={this.sendImage.bind(this)}>
            Send video
          </button>
        </>
    );
  }
}
