import React, { Suspense } from "react";
import PropTypes from "prop-types";
import * as Images from "./images";
import { STAND_ALONE, SYSTEM_INFO_URL, CORP_LOGO_DEFAULT, getBackendUrl, getResourceUrl } from "../utils/constants";
import { confirmAlert } from "react-confirm-alert"; // Import
import { withTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import QRCode from "react-qr-code";
import { doGet } from "../services/api.service";
import { withRouter } from "react-router-dom";

const qr = React.lazy(() => import(QRCode));

class Menu extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      user: JSON.parse(localStorage.getItem("user")),
      showModal: false,
      systemInfo: null,
      menuDrones: {
        body: [
          {
            icon: "IconDashboard",
            title: "menu.dashboard",
            url: "/home",
          },
          {
            icon: "IconPlane",
            title: "menu.drone_status",
            url: "/status",
          },
          {
            icon: "IconChart",
            title: "menu.check_the_result",
            url: "/inspection-result",
          },
          {
            icon: "IconScan",
            title: "menu.flight_file",
            url: "/plan",
          },
          {
            icon: "IconSchedule",
            title: "menu.schedule",
            url: "/schedule",
          },
        ],
        footer: [
          {
            icon: "IconSetting",
            title: "menu-admin.setting",
            url: "/setting/avatar",
          },
          {
            icon: "",
            title: "menu-admin.logout",
            url: "/logout",
          },
        ],
      },
      menuRobots: {
        body: [
          {
            icon: "IconRobot",
            title: "menu.robot_status",
            url: "/status",
          },
          {
            icon: "IconChart",
            title: "menu.report",
            url: "/inspection-result",
          },
          {
            icon: "IconScan",
            title: "plan.robot.title",
            url: "/plan",
          },
        ],
        footer: [
          {
            icon: "IconSetting",
            title: "menu-admin.setting",
            url: "/setting/avatar",
          },
          {
            icon: "",
            title: "menu-admin.logout",
            url: "/logout",
          },
        ],
      },
    };
    this.handleLogout.bind(this);
    this.renderQRCode.bind(this);
    this.onClickAvatar.bind(this);
    this.handleClose.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.avatarChange !== this.props.avatarChange) {
      this.setState({
        user: JSON.parse(localStorage.getItem("user")),
      });
    }
  }

  handleLogout(e, t) {
    e.preventDefault();

    confirmAlert({
      title: t("menu-admin.do_you_want_to_logout"),
      message: "",
      buttons: [
        {
          label: t("common.ok"),
          onClick: () => {
            this.props.history.push("/logout");
          },
        },
        {
          label: t("common.back"),
        },
      ],
    });
  }

  onClickAvatar() {
    if (STAND_ALONE) {
      doGet(SYSTEM_INFO_URL, null, (res) => {
        this.setState({
          showModal: true,
          systemInfo: res.data,
        });
      });
    }
  }

  handleClose() {
    if (STAND_ALONE) {
      this.setState({ showModal: false });
    }
  }

  renderQRCode(t) {
    if (STAND_ALONE) {
      if (this.state.systemInfo) {
        const { ipAddress, hostName } = this.state.systemInfo;
        
        // const location = window.location;
        // const accessPoint =
        //   location.protocol +
        //   "//" +
        //   ipAddress +
        //   (location.port ? ":" + location.port : "");

        const tempAccessPoint = getBackendUrl().split(':')
        tempAccessPoint.pop();
        const accessPoint = tempAccessPoint.join(':');
        return (
          <Modal
            centered
            show={this.state.showModal}
            onHide={() => this.handleClose()}
          >
            <Modal.Header closeButton>
              <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {
                <Suspense fallback={<div>Loading...</div>}>
                  <div style={{ textAlign: "center" }}>
                    <div style={{ fontSize: "36px" }}>{accessPoint}</div>
                    <QRCode value={accessPoint} />
                    <h5>Hostname:{hostName}</h5>
                  </div>
                </Suspense>
              }
            </Modal.Body>
          </Modal>
        );
      } else {
        return (
          <Modal
            centered
            show={this.state.showModal}
            onHide={() => this.handleClose()}
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <div>{t("setting.can_not_get_ip_address")}</div>
            </Modal.Body>
          </Modal>
        );
      }
    }
  }

  renderItemMenu(data, t) {
    return data.map((item, index) => {
      if (item.url === "/logout") {
        return (
          <div key={index}>
            <a
              href="#"
              onClick={(event) => this.handleLogout(event, t)}
              className={
                this.props.currentRouter.includes(item.url)
                  ? "active menu-item"
                  : "menu-item"
              }
            >
              <Images.Icons name={item.icon} color="currentColor" />
              <span className="icon-text">{t(item.title)}</span>
            </a>
            <div
              className={
                this.props.currentRouter === item.url
                  ? "choose-btn"
                  : "display-none"
              }
            >
            </div>
          </div>
        );
      } else {
        return (
          <div key={index} style={{display: 'flex', justifyContent: 'space-between'}}>
            <a
              href={item.url}
              className={
                this.props.currentRouter.includes(item.url)
                  ? "active menu-item"
                  : "menu-item"
              }
            >
              <Images.Icons name={item.icon} color="currentColor" />
              <span className="icon-text">{t(item.title)}</span>
            </a>
            <div
              className={
                this.props.currentRouter === item.url
                  ? "choose-btn"
                  : "display-none"
              }
            >
            </div>
          </div>
        );
      }
    });
  }

  render() {
    const { t } = this.props;
    return (
      <section className="tab-menu f-column">
        {this.renderQRCode(t)}

        <div className="mheader">
          <div className="user-option">
            <img
              className="image"
              alt="user logo"
              src={
                this.state.user.avatar
                  ? getResourceUrl() + this.state.user.avatar
                  : getResourceUrl() + CORP_LOGO_DEFAULT
              }
            />
            <span className="textAva">{this.state.user.corpName}</span>
          </div>
          
          {this.state.user.accountType === "robot"
            ? this.renderItemMenu(this.state.menuRobots.body, t)
            : this.renderItemMenu(this.state.menuDrones.body, t)}
        </div>

        <div className="mfooter">
          {this.state.user.accountType === "robot"
            ? this.renderItemMenu(this.state.menuRobots.footer, t)
            : this.renderItemMenu(this.state.menuDrones.footer, t)}

          <div className="avatar f-row f-center">
            <img
              className="image"
              alt="spiral logo"
              src={Images.LogoTextWhite}
              onClick={() => this.onClickAvatar()}
            />
          </div>
        </div>
      </section>
    );
  }
}

export default withRouter(withTranslation()(Menu));

Menu.defaultProps = {
  currentRouter: "",
};

Menu.propTypes = {
  currentRouter: PropTypes.string,
};
