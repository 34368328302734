import axios from "axios";
import React, { useEffect, useRef, useState, useLayoutEffect } from "react";
import { getHeader } from "../services/api.service";
import { timeMinSecConverter, timeSmallConverter } from "../utils/utils";
import * as Images from "./images";
import * as Constants from "../utils/constants";
import PreviewGallery from "./result/previewGallery";
import { isMobile, isMobileOnly } from "react-device-detect";
import picture from "../assets/images/svg/picture.svg";

const StatusPlayingTracking = (props) => {
  const { 
    data, 
    inspectionId, 
    isFlying,
    contentForPreviewNotFlying,
    refPlan,
    stationStart, 
    stationEnd, 
    isStationStartNotFound, 
    isStationEndNotFound, 
    t 
  } = props;

  const [mediaTime, setMediaTime] = useState(null);
  const [mediaList, setMediaList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [mediaPreview, setMediaPreview] = useState([]);
  const [currentMarkerPreview, setCurrentMarkerPreview] = useState(null);
  const [heightBrowser, setHeightBrowser] = useState(0);

  const playingWrapper = useRef(null)
  
  const flightProcessRef = useRef(null);
  const processWrapRef = useRef(null);
  const droneRef = useRef(null);

  const heightDrone = useRef(0);
  const isDroneLeftPosition = useRef(false);

  useEffect(() => {
    processWrapRef.current?.addEventListener('scroll', autoHandleShadowProcessBar);

    return () => {
      processWrapRef.current?.removeEventListener('scroll', autoHandleShadowProcessBar);
    }
  }, [])

  useEffect(() => {
    autoScrollWhenPlayVideo();
  }, [data, heightDrone.current]);

  useEffect(() => {
    autoScrollWhenPlayVideo();
    autoHandleShadowProcessBar();
  }, [useWindowSize()])

  function useWindowSize() {
    useLayoutEffect(() => {
      function updateSize() {
        setHeightBrowser(window.innerHeight);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return heightBrowser;
  }

  const autoScrollWhenPlayVideo = () => {
    const heightWrap = processWrapRef.current.clientHeight;
    const heightScroll = processWrapRef.current.scrollHeight;
    
    if (heightScroll > heightWrap) {
      const positonDrone = isDroneLeftPosition.current ? 14 : 17;
      processWrapRef.current.scrollTop = 12 + heightDrone.current - heightWrap / 2 - positonDrone;
    }
  };

  const autoHandleShadowProcessBar = () => {
    const heightWrap = processWrapRef.current.clientHeight;
    const scrollTop = processWrapRef.current.scrollTop;
    const heightScroll = processWrapRef.current.scrollHeight;

    if (scrollTop) {
      flightProcessRef.current.classList.add("shadow-t");
    } else {
      flightProcessRef.current.classList.remove("shadow-t");
    }

    if(heightScroll > scrollTop + heightWrap) {
      flightProcessRef.current.classList.add("shadow-bt");
    } else {
      flightProcessRef.current.classList.remove("shadow-bt");
    }
  }

  const renderStatusPlayingTracking = (device) => {
    if (device.length > 0) {
      const isImageLeftAxis = !!device.find(item => item.content?.markerLeftImages);
      const isImageAxis = !!device.find(item => item.content?.url);

      const utcTakeoff = device[0].content?.utc || 0;
      let nextMarker = 0,
        markerArv = false,
        markerLeft = false,
        styleDronePlay;

      [...device].forEach((item, index, arr) => {
        if (
          item.status === "marker_arv" ||
          item.status === "image" ||
          item.status === "video"
        ) {
          markerLeft = false;
          markerArv = true;
        }
        if (item.status === "marker_left") {
          markerArv = false;
          markerLeft = true;
        }
        if (!item.isTakeoff && !item.trackingPassItem) {
          nextMarker = index;
          arr.length = index + 1;
        }
      });

      if (nextMarker === 1) {
        // drone playing position takeoff
        styleDronePlay = 60 + 28;
        markerLeft = true;
      } else if (markerArv) {
        // drone playing position marker_arv
        let height = 60 + (nextMarker - 1) * 28 + (nextMarker - 1) * 34;
        styleDronePlay = height;
      } else if (markerLeft) {
        // drone playing position marker_left
        let height = 60 + nextMarker * 28 + (nextMarker - 1) * 34;
        styleDronePlay = height;
      }

      const isLanded = Boolean(device.find(item => item.isLanding)?.content);

      let isLandedNotSuccessPlan = false;

      for (let index = 0; index < device.length; index++) {
        const item = device[index];
        if (isLanded && !item.isTakeoff && !item.isLanding && !item.trackingPassItem) {
          isLandedNotSuccessPlan = true;
          break;
        }
      }

      if (isLanded) {
        let height = 120 + nextMarker * 28 + (nextMarker - 1) * 34;
        styleDronePlay = height;
      }

      heightDrone.current = styleDronePlay;
      isDroneLeftPosition.current = markerLeft;

      return (
        <div className="status-process" ref={playingWrapper}>
          {device.map((item, index) => {
            if(item.isTakeoff) return renderTakeoffMarker(item);
            if(item.isLanding) return renderLandingMarker(item, utcTakeoff, isLanded, isLandedNotSuccessPlan);
            return renderMarkerItem(item, utcTakeoff, markerArv && nextMarker - 1 === index);
          })}

          {isFlying && !isLanded &&
            <div className="drone-process" style={{ "--height": `${styleDronePlay}px` }} ref={droneRef}>
              <div 
                className="drone" 
                style={{
                  "--width": markerLeft ? '16px': '34px',
                  transform: markerLeft ? 'translateY(-6px)' : 'translateY(0)'
                }}
              ></div>
            </div>
          }
        </div>
      );
    }
  };

  const renderTakeoffMarker = (data) => {
    const { content } = data;
    return (
      <div 
        className={"marker-item marker-station marker-takeoff " +
          (isFlying ? 'tracking-pass-item' : '')
        } 
        key={Math.random()}
      >
        <div className="icon">
          {renderStationIcon(stationStart?.lid, isStationStartNotFound)}
        </div>
        <div className="marker-info marker-station-info">
          <p className="name">{stationStart ? stationStart.stationId : "Empty"}</p>
          <p className={"status " + (isStationStartNotFound ? 'not-found' : getClassName(stationStart?.lid))}>{isStationStartNotFound ? "Not Found" : stationStart?.lid}</p>
          {isFlying &&
            <p className="time">
              {timeMinSecConverter(0) +  " | " + timeSmallConverter(+content.utc)}
            </p>
          }

          {content && content.markerLeftImages && 
            <button
              onClick={() => handleOpen(data, true)}
              className="capture-letf"
            >
              <img
                src={picture}
                alt=""
              />
            </button>
          }
        </div>
      </div>
    )
  }

  const renderLandingMarker = (data, utcTakeoff, isLanded, isLandedNotSuccessPlan) => {
    return (
      <div 
        className={
          "marker-item marker-station marker-landing" + 
          (isLanded ? " tracking-pass-item" : "") +
          (isLandedNotSuccessPlan ? " not-success-plan" : "")
        } 
        key={Math.random()}
      >
        <div className="icon">
          {renderStationIcon(stationEnd?.lid, isStationEndNotFound)}
        </div>
        <div className="marker-info marker-station-info">
          <p className="name">{stationEnd ? stationEnd.stationId : "Empty"}</p>
          <p className={"status " + (isStationEndNotFound ? 'not-found' : getClassName(stationEnd?.lid))}>
            {isStationEndNotFound ? "Not Found" : stationEnd?.lid}
          </p>
          {isLanded &&
            <p className="time">
              {timeMinSecConverter(+data.content.utc - utcTakeoff) +  " | " + timeSmallConverter(+data.content.utc)}
            </p>
          }
        </div>
        <span className="line"></span>
      </div>
    )
  }

  const renderMarkerItem = (data, utcTakeoff, isCurrentMarker) => {
    const { trackingPassItem, makerArrTime, content } = data;
    return (
      <div className={"marker-item " + (trackingPassItem ? 'tracking-pass-item' : '')} key={Math.random()}>
        <div className="marker-item-icon">
          <span style={isCurrentMarker ? { color: "#FFF" } : undefined}>{data.id}</span>
        </div>
        {trackingPassItem &&
          <div className="marker-info">
            <span className="time marker-arv">
              {timeMinSecConverter(makerArrTime - utcTakeoff) +  " | " + timeSmallConverter(makerArrTime)}
            </span>

            {content.url && 
              <button
                onClick={() => handleOpen(data, false)}
                className="capture"
              >
                <img
                  src={picture}
                  alt=""
                />
              </button>
            }
            
            {content.markerLeftImages && 
              <button
                onClick={() => handleOpen(data, true)}
                className="capture-letf"
              >
                <img
                  src={picture}
                  alt=""
                />
              </button>
            }
          </div>
        }
        <span className="line"></span>
      </div>
    )
  }

  const renderStationIcon = (status, isCheckNotFound) => {
    if (isCheckNotFound) return <img src={require("../assets/images/cs-closed.png")} alt="" className="not-found" />;

    switch (status) {
      case "closed": return <img src={require("../assets/images/cs-closed.png")} alt="" />;
      case "closing": return <img src={require("../assets/images/cs-closeing.png")} alt="" />;
      case "opening": return <img src={require("../assets/images/cs-opening.png")} alt="" />;
      case "opened": return <img src={require("../assets/images/cs-opened.png")} alt="" />;

      default: return <img src={require("../assets/images/cs-closed.png")} alt="" className="not-found" />;
    }
  };

  const getClassName = (param) => {
    switch (param) {
      //drone
      case "idle":
        return "not-found";
      case "charging":
        return "charging";
      case "non-charging":
        return "non-charging";
      case "ready":
        return "ready";
      case "landed":
        return "landed";
      case "empty":
        return "empty";
      case "now-flying":
        return "now-flying";
      case "charging-empty":
        return "charging-empty";

      //station
      case "opened":
        return "opened";
      case "closed":
        return "closed";
      case "opening":
        return "opening";
      case "closing":
        return "closing";

      default:
        return;
    }
  };

  const handleOpen = (item, isMarkerLeft) => {
    const { marker_id, utc, url, markerLeftImages } = item.content;
    setMediaList(isMarkerLeft ? markerLeftImages : url);
    setMediaTime(utc);
    setIsEdit(true);
    setMediaPreview([]);
    setCurrentMarkerPreview(marker_id);

    axios.all((isMarkerLeft ? markerLeftImages : url).trim().split(" ").map((item) => axios.get(Constants.MEDIA_URL + item + "/inspectionId/" + inspectionId, getHeader())))
      .then(
        axios.spread((...response) => {
          setMediaPreview(response.map((res) => res.data).sort((a, b) => a.utc - b.utc));
        })
      );
  };

  const handleClose = () => {
    setMediaList(null);
    setMediaTime(null);
    setIsEdit(false);
    setMediaPreview([]);
  };

  return (
    <div className="flight-process" ref={flightProcessRef}>
      <div className="wrap-result-tracking ct-scroll" ref={processWrapRef}>
        {!isFlying && !contentForPreviewNotFlying ?
          (
            refPlan !== "marker" &&
            <div className="no-process">
              <p>No progress information</p>
              <p>Select a flight plan</p>
            </div>
          )
          :
          renderStatusPlayingTracking(data, t)
        }
        <PreviewGallery
          mediaList={mediaList}
          mediaTime={mediaTime}
          mediaPreview={mediaPreview}
          isOpen={isEdit}
          handleClose={handleClose.bind(this)}
          currentMarker={currentMarkerPreview}
        />
      </div>
    </div>
  );
};

export default StatusPlayingTracking;
