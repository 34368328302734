import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import * as Images from "../components/images";
import AuthService from "../services/auth.service";
import * as Validator from "../utils/validator";
import CheckButton from "react-validation/build/button";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import SendEmail from "../components/SendEmail";

class Login extends Component {
  constructor(props) {
    // const { t, i18n } = this.props;
    super(props);
    // Init component state
    this.state = {
      email: "",
      password: "",
      loading: false,
      message: "",
      error: false,
      showModal: false,
      sendEmail: "",
      errorSendEmail: false,
      validMessage: false,
    };
  }

  handleClose = () => this.setState({ showModal: false });
  handleShow = () => this.setState({ showModal: true });

  // Handle input change then set to state
  handleChange(field, e) {
    let updateState = this.state;
    updateState[field] = e.target.value;
    this.setState({ updateState, error: false });
  }

  // Handle login submit to validate and call to services
  handleLogin(e) {
    e.preventDefault();

    //validate input data
    if (this.checkBtn.context._errors.length === 0) {
      //active loading mode
      this.setState({
        loading: true,
      });

      //Call authservice
      AuthService.login(this.state.email, this.state.password).then(
        (e) => {
          var user = JSON.parse(localStorage.getItem("user"));

          // Check user role
          if (user.roles.includes("ROLE_ADMIN")) {
            // redirect to the admin site
            this.props.history.push("/user");
          } else {
            //redirect to the corporate site
            this.props.history.push("/home");
          }
        },
        (error) => {
          this.setState({
            loading: false,
            error: true,
          });
        }
      );
    } else {
      this.form.validateAll();
    }
  }

  componentDidMount() {}

  //Render Ui for screen
  render() {
    const { t, i18n } = this.props;
    return (
      <section className="background w-100 dps-h-100 f-col">
        <Form
          onSubmit={this.handleLogin.bind(this)}
          ref={(c) => {
            this.form = c;
          }}
          className="card f-column f-center"
        >
          <img src={Images.Logo} alt="spiral logo" className="logo" />
          {/* <p>{t("login.welcome")} <br/> {t("login.in_notice")}</p> */}

          <label htmlFor="email">{t("login.email_label")}</label>
          <Input
            type="text"
            className="dps-input login-font-size-revert"
            name="email"
            id="email"
            value={this.state.email}
            message={t("login.email_message")}
            validations={[Validator.required, Validator.email]}
            onChange={this.handleChange.bind(this, "email")}
          />

          <label htmlFor="password">{t("login.password_label")}</label>
          <Input
            type="password"
            className="dps-input login-font-size-revert"
            name="password"
            id="password"
            message={t("login.password_message")}
            value={this.state.password}
            validations={[Validator.required]}
            onChange={this.handleChange.bind(this, "password")}
          />

          <button
            className="forgot-pass"
            type="button"
            onClick={this.handleShow}
          >
            {t("login.forgot_pass")}
          </button>

          <small
            className={
              "alert-text login-alert-text txt-red " +
              (this.state.error ? "visible" : "invisible")
            }
          >
            {t("login.error")}
          </small>

          <CheckButton
            style={{ display: "none" }}
            ref={(c) => {
              this.checkBtn = c;
            }}
          />

          <button className="dps-button" disabled={this.state.loading}>
            <span className="spinner" hidden={!this.state.loading}></span>
            <span className="text-uppercase">{t("login.button")}</span>
          </button>
        </Form>
        <SendEmail
          showModal={this.state.showModal}
          handleClose={this.handleClose}
        />

        <div className="privacy_support">
          <Link to="/content/support">{t("login.support")}</Link>
          <Link to="/content/terms-of-use">{t("login.terms_of_use")}</Link>
          <Link to="/content/privacy">{t("login.privacy")}</Link>
        </div>
      </section>
    );
  }
}

export default withTranslation("dps")(Login);
