import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import {Modal} from "react-bootstrap";
import MenuAdmin from "../../components/menu-admin";
import {confirmAlert} from 'react-confirm-alert';
import * as Images from "../../components/images";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import * as Validator from "../../utils/validator";
import CheckButton from "react-validation/build/button";
import * as Constants from "../../utils/constants";
import {doDelete, doGet, doPost, doPut, getHeader} from "../../services/api.service";
import cloneDeep from 'lodash/cloneDeep';
import {useTranslation} from 'react-i18next';
import LanguageSwitch from "../../components/languageSwitch";
import {NotificationManager} from 'react-notifications';
import pdfjs from "pdfjs-dist/legacy/build/pdf.js"
import axios from "axios";
import authHeader from "../../services/auth-header";

export default function DroneType(props) {
  const initialDroneType = {
    id: null,
    name: "",
    flightTime: "",
    flightDistance: "",
    maxSpeed: "",
    doneTypeImg: "",
    file: null,
  };
  const tableHeader = ['setting.droneType.table.header_1',
    'setting.droneType.table.header_2',
    'setting.droneType.table.header_3',
    'setting.droneType.table.header_4',
    'setting.droneType.table.header_5',
    'setting.droneType.table.header_6',
    'setting.droneType.table.header_7'
  ];
  const {t, i18n} = useTranslation();
  const inputDroneRef = useRef(null);
  const checkBtn = useRef(null);
  const [droneTypes, setDroneTypes] = useState([]);
  const [addDroneType, setAddDroneType] = useState(initialDroneType);
  const [editDroneType, setEditDroneType] = useState(initialDroneType);
  const [editing, setEditing] = useState(false);
  const [canSaveInEdit, setCanSaveInEdit] = useState(false);
  const [droneTypeImageFile, setDroneTypeImageFile] = useState(null);
  const [isCheckAdd, setIsCheckAdd] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState('loading');
  // resizeWindow
  const [size, setSize] = useState([0, 0]);

  const refBodyTable = useRef();
  const refHeaderTable = useRef();
  const btnRef = useRef(null);

  useEffect(() => {
    getListDroneTypes();
    if (btnRef.current) {
      btnRef.current.setAttribute("disabled", "disabled");
    }
  }, []);

  useEffect(() => {
    if(loadingStatus === 'OK') {
      resizeTable()
    }
  }, [droneTypes])
  
  useEffect(() => {
    resizeTable();
  }, [useWindowSize()])

  function useWindowSize() {
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

  const resizeTable = () => {
    const scrollHeight = refBodyTable.current?.scrollHeight;
    const clientHeight = refBodyTable.current?.clientHeight;
    const headerTable = refHeaderTable.current;

    if(headerTable) {
      if(scrollHeight > clientHeight) {
        headerTable.style.paddingRight = '16px';
      } else {
        headerTable.style.paddingRight = 0;
      }
    }
  }

  function getListDroneTypes() {
    doGet(Constants.DRONE_TYPE_URL, null,
        (res) => {
          setDroneTypes(res.data);
          if(res.data.length) {
            setLoadingStatus('OK')
          } else {
            setLoadingStatus('NOK')
          }
        },
        (error) => console.log(error)
    )
  }

  //<editor-fold desc="Table generate">
  function renderItemHeaderTable(data, t) {
    return data.map((item, index) => {
      return <div className="clmn" key={index}>{t(item)}</div>;
    });
  }

  function renderItemTable(data) {
    return data.map((item, index) => {
      return (
          <div className="list-item" key={index}>
            <div className="clmn">
              {index + 1}
            </div>
            <div className="clmn">
              {item.name}
            </div>
            <div className="clmn">
              {item.flightTime}
            </div>
            <div className="clmn">
              {item.flightDistance}
            </div>
            <div className="clmn">
              {item.maxSpeed}
            </div>
            <div className="drone_img clmn">
              <img src={Constants.getResourceUrl() + item.imagePath} alt="" />
            </div>

            <div className="actions d-flex clmn">
              <button className="btn-small edit position-relative"
                      onClick={() => handleShow(item)}>
                <div className="fix-icon-center-ios">
                  <Images.Icons name="IconEdit"/>
                </div>
              </button>
              <button className={"btn-small delete position-relative"}
                      onClick={() => handleDelete(item)}>
                <div className="fix-icon-center-ios">
                  <Images.Icons name='IconDelete'/>
                </div>
              </button>
            </div>
          </div>
      );
    });
  }

  //</editor-fold>

  //<editor-fold desc="Handle Create">
  const changeDroneTypeImg = (e) => {
    e.preventDefault()
    document.getElementById('doneTypeImgSub').click()
  }

  function handleChange(event) {
    const {name, value} = event.target;
    setAddDroneType({...addDroneType, [name]: value});
    setIsCheckAdd(true)
    if (btnRef.current) {
      btnRef.current.removeAttribute("disabled");
    }
  }

  function handleSubmit(event) {
    event.preventDefault();
    setIsCheckAdd(true)

    var formData = new FormData();

    formData.append('name', addDroneType.name);
    formData.append('flightTime', addDroneType.flightTime);
    formData.append('flightDistance', addDroneType.flightDistance);
    formData.append('maxSpeed', addDroneType.maxSpeed);
    if(droneTypeImageFile) {
      formData.append('file', droneTypeImageFile);
    }
    inputDroneRef.current.validateAll();
    if (checkBtn.current.context._errors.length === 0) {
      if (btnRef.current) {
        btnRef.current.setAttribute("disabled", "disabled");
      }
      axios.post(Constants.DRONE_TYPE_URL, formData, getHeader('multipart/form-data')).then(() => {
        NotificationManager.success(t('post.success'), '', 2000);
        setIsCheckAdd(false)
        setAddDroneType(initialDroneType);
        setDroneTypeImageFile(null)
        getListDroneTypes();
      }).catch(() => {
        setIsCheckAdd(false)
      })
    }
  }

  //</editor-fold>

  function handleClose() {
    setEditing(false);
  }

  function handleShow(item) {
    setEditing(true);
    setEditDroneType(cloneDeep(item));
    setCanSaveInEdit(false);
    setDroneTypeImageFile(null)
  }

  //<editor-fold desc="Handle Edit">
  const changeDroneTypeImgEdit = (e) => {
    e.preventDefault()
    document.getElementById('doneTypeImgEdit').click()
  }

  function handleEditChange(event) {
    const {name, value} = event.target;
    setEditDroneType({...editDroneType, [name]: value});
    setCanSaveInEdit(true);
  }

  function handleEditSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    let url = Constants.DRONE_TYPE_UD_URL + editDroneType.id;
    var formData = new FormData();
    formData.append('name', editDroneType.name);
    formData.append('flightTime', editDroneType.flightTime);
    formData.append('flightDistance', editDroneType.flightDistance);
    formData.append('maxSpeed', editDroneType.maxSpeed);
    if(droneTypeImageFile) {
      formData.append('file', droneTypeImageFile);
    }
    axios.put(url, formData, getHeader('multipart/form-data')).then(() => {
      NotificationManager.success(t('put.success'), '', 2000);
      setEditDroneType(initialDroneType);
      setDroneTypeImageFile(null)
      setEditing(false);
      getListDroneTypes();
    })
    // doPut(
    //     url,
    //     editDroneType,
    //     () => {
    //     }
    // )

  }

  function renderEditModal() {
    return (
        <Modal className="edit-form" show={editing} centered
               onHide={handleClose}>

          <Modal.Header closeButton>
            <Modal.Title>{t('setting.droneType.edit')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form className="edit-user f-column f-center"
                  onSubmit={handleEditSubmit}>
              <div className="item">
                <label>{t('setting.droneType.table.header_2')}</label>
                <label>{editDroneType.name}</label>
              </div>
              <div className="item">
                <label>{t('setting.droneType.table.header_3')}</label>
                <input
                    type="number"
                    className="dps-input"
                    name="flightTime"
                    min={0}
                    step="any"
                    placeholder={t('setting.droneType.table.header_3')}
                    value={editDroneType.flightTime}
                    onChange={handleEditChange}
                />
              </div>
              <div className="item">
                <label>{t('setting.droneType.table.header_4')}</label>
                <input
                    type="number"
                    className="dps-input"
                    name="flightDistance"
                    min={0}
                    step="any"
                    placeholder={t('setting.droneType.table.header_4')}
                    value={editDroneType.flightDistance}
                    onChange={handleEditChange}
                />
              </div>
              <div className="item">
                <label>{t('setting.droneType.table.header_5')}</label>
                <input
                    type="number"
                    className="dps-input"
                    name="maxSpeed"
                    min={0}
                    step="any"
                    placeholder={t("setting.droneType.table.header_5")}
                    value={editDroneType.maxSpeed}
                    onChange={handleEditChange}
                />
              </div>
              <div className="item">
                <label>{t('setting.droneType.table.header_6')}</label>
                <input
                  id="doneTypeImgEdit"
                  type="file"
                  className="dps-input hidden"
                  name="file"
                  value={editDroneType.file}
                  onChange={handleChangeImageFile}
                />
                <div className="mash_input dps-input" style={{padding: '0 20px 0 12px'}}>
                  {t('setting.droneType.file_select')}
                </div>
                <button className="icon_folder" onClick={(e) => changeDroneTypeImgEdit(e)}>
                  <img src={require('../../assets/images/file1.png')} alt="" />
                </button>
              </div>
              <button type="submit" className="btn-save position-relative"
                      disabled={!canSaveInEdit}
                      style={{marginRight: '2rem'}}>
                <div className="fix-icon-center-ios">
                  <Images.Icons name="IconSave"/>
                </div>
              </button>
            </Form>
          </Modal.Body>
        </Modal>
    );
  }

  //</editor-fold>

  function handleDelete(item) {
    confirmAlert({
      title: t('setting.do_you_want_delete_it'),
      message: '',
      buttons: [
        {
          label: t('common.ok'),
          onClick: () => {
            let request = Constants.DRONE_TYPE_UD_URL + item.id;
            doDelete(request, () => {
              getListDroneTypes();
              NotificationManager.success(
                t("dialog_delete.confirm_success"),
                "",
                2000
              );
            }, () => {
              NotificationManager.error(
                t("dialog_delete.confirm_error"),
                "",
                2000
              );
            })
          }
        },
        {
          label: t('dialog_delete.option_no')
        }
      ]
    });
  }

  // 
  const handleChangeImageFile = (e) => {
    if (e.target.files[0]) {
      if (e.target.files[0].type !== "application/pdf") {
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        setDroneTypeImageFile(e.target.files[0])
        setCanSaveInEdit(true)
      } else {
        let file = e.target.files[0];
        let fileReader = new FileReader();
        fileReader.onload = function () {
          let pdfData = new Uint8Array(this.result);
          // Using DocumentInitParameters object to load binary data.
          let loadingTask = pdfjs.getDocument({ data: pdfData });
          loadingTask.promise.then(
            function (pdf) {
              console.log("PDF loaded");

              // Fetch the first page
              let pageNumber = 1;
              pdf.getPage(pageNumber).then(function (page) {
                console.log("Page loaded");

                let scale = 1.0;
                let viewport = page.getViewport({ scale: scale });

                // Prepare canvas using PDF page dimensions
                let canvas = document.createElement("canvas");
                let context = canvas.getContext("2d");
                canvas.height = viewport.height;
                canvas.width = viewport.width;

                // Render PDF page into canvas context
                let renderContext = {
                  canvasContext: context,
                  viewport: viewport,
                };
                let renderTask = page.render(renderContext);
                renderTask.promise.then(function () {
                  let url = canvas.toDataURL();
                  // Split the base64 string in data and contentType
                  let block = url.split(";");
                  // Get the content type
                  let contentType = block[0].split(":")[1]; // In this case "image/gif"
                  // get the real base64 content of the file
                  let realData = block[1].split(",")[1]; // In this case "iVBORw0KGg...."

                  // Convert to blob
                  let blob = b64toBlob(realData, contentType);
                  setDroneTypeImageFile(blob)
                  setCanSaveInEdit(true)
                });
              });
            },
            function (reason) {
              // PDF loading error
              console.error(reason);
            }
          );
        };
        fileReader.readAsArrayBuffer(file);
      }
    }
  };

  function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    let byteCharacters = atob(b64Data);
    let byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);

      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      let byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    let blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
 
  // Main render
  return (
      <>
        {renderEditModal()}
        <div className="dps-menu ">
          <MenuAdmin currentRouter={props.location.pathname}/>
        </div>
        <div className="dps-content admin-page">
          <div className="dps-content-area">
            <div className="dps-title main-title">
              <h1>{t('avatar.main_title')}</h1>
              <LanguageSwitch/>
            </div>
            <div className="dps-main-content">
              <div className="dps-main-content-area">
                <div className="dps-tabs">
                  <a href="/setting/profile">{t('setting.menu.profile')}</a>
                  <a href="/setting/icon">{t('setting.menu.icon')}</a>
                  <a href="/setting/parameter">{t('setting.menu.parameter')}</a>
                  <a href="/setting/drone-type" className="active">{t('setting.menu.droneType')}</a>
                  <a href="/setting/privacy-supports">{t('setting.menu.privacySupports')}</a>
                </div>
                <div
                    className="dps-tabs-content admin-setting d-flex flex-column position-relative bg-light">
                  <div className="dps-list-f table-setting">
                    <div className="header" ref={refHeaderTable}>
                      {renderItemHeaderTable(tableHeader, t)}
                    </div>
                    <div className="body" ref={refBodyTable}>
                      {renderItemTable(droneTypes)}
                    </div>

                  </div>
                  <Form 
                    className="add-item f-row f-center position-relative"
                    ref={inputDroneRef} 
                    onSubmit={handleSubmit}
                    style={{padding: 0, marginTop: '20px'}}
                  >
                    <Input
                        type="text"
                        name="name"
                        placeholder={t('setting.droneType.table.header_2')}
                        value={addDroneType.name}
                        isCheckAdd = {isCheckAdd}
                        typeCheck={true}
                        message={t('setting.droneType.required')}
                        validations={[Validator.required]}
                        onChange={handleChange}
                    />
                    <Input
                        type="number"
                        name="flightTime"
                        placeholder={t('setting.droneType.table.header_3')}
                        value={addDroneType.flightTime}
                        onChange={handleChange}
                    />
                    <Input
                        type="number"
                        name="flightDistance"
                        placeholder={t('setting.droneType.table.header_4')}
                        value={addDroneType.flightDistance}
                        onChange={handleChange}
                    />
                    <Input
                        type="number"
                        name="maxSpeed"
                        placeholder={t('setting.droneType.table.header_5')}
                        value={addDroneType.maxSpeed}
                        onChange={handleChange}
                    />
                    <div className="mash_drone_type">
                      <Input
                        id='doneTypeImgSub'
                        className='hidden'
                        type="file"
                        name="file"
                        value={addDroneType.file}
                        onChange={handleChangeImageFile}
                      />
                      <button className="btn_choose_drone_type" type="button" onClick={(e) => changeDroneTypeImg(e)}>
                        {t('setting.droneType.file_select')}
                        <img src={require('../../assets/images/file1.png')} alt="" />
                      </button>
                    </div>
                    <CheckButton style={{display: 'none'}} ref={checkBtn}/>
                    <button type="submit" ref={btnRef}
                            className="btn-save position-relative">
                      <div className="fix-icon-center-ios">
                        <Images.Icons name="IconSave"/>
                      </div>
                    </button>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
  );
}