export const generateUUIDv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
};

export const timeConverterFull = (UNIX_timestamp, t) => {
  if (!UNIX_timestamp) {
    return "";
  }
  const a = new Date(UNIX_timestamp * 1000);
  const year = a.getFullYear();
  const month = ('0' + (a.getMonth() + 1)).substr(-2);
  const date = ('0' + a.getDate()).substr(-2);
  const hour = ('0' + a.getHours()).substr(-2);
  const min = ('0' + a.getMinutes()).substr(-2);
  const sec = ('0' + a.getSeconds()).substr(-2);

  return year + t('common.year') + month + t('common.month') + date + t(
    'common.date') + ' ' + hour + ':' + min + ':' + sec;
}

export const timeConverter = (UNIX_timestamp, t) => {
  if (!UNIX_timestamp) {
    return "";
  }
  const a = new Date(UNIX_timestamp * 1000);
  const year = a.getFullYear();
  const month = ('0' + (a.getMonth() + 1)).substr(-2);
  const date = ('0' + a.getDate()).substr(-2);
  const hour = ('0' + a.getHours()).substr(-2);
  const min = ('0' + a.getMinutes()).substr(-2);

  return year + t('common.year') + month + t('common.month') + date + t(
    'common.date') + ' ' + hour + ':' + min;
}

export const timeConverterDate = (UNIX_timestamp, t) => {
  if (!UNIX_timestamp) {
    return "";
  }
  const a = new Date(UNIX_timestamp * 1000);
  const year = a.getFullYear();
  const month = ('0' + (a.getMonth() + 1)).substr(-2);
  const date = ('0' + a.getDate()).substr(-2);

  return year + t('common.year') + month + t('common.month') + date + t(
    'common.date');
}

export const timeSmallConverter = (UNIX_timestamp) => {
  if (UNIX_timestamp != null) {
    var a = new Date(UNIX_timestamp * 1000);
    var hour = ('0' + a.getHours()).substr(-2);
    var min = ('0' + a.getMinutes()).substr(-2);
    var sec = ('0' + a.getSeconds()).substr(-2);
    var time = hour + ':' + min + ':' + sec;
    return time;
  }
}

export const timeMinSecConverter = (UNIX_timestamp) => {
  if (UNIX_timestamp !== null && UNIX_timestamp >= 0) {
    var a = new Date(UNIX_timestamp * 1000);
    var min = ('0' + a.getMinutes()).substr(-2);
    var sec = ('0' + a.getSeconds()).substr(-2);
    var time = min + ':' + sec;
    return time;
  }
}

export const isValidHttpUrl = (string) => {
  let url;

  try {
    url = new URL(string);
  } catch (e) {
    console.log("url'error: " + e);
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

