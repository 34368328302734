import axios from "axios";
import authHeader from "./auth-header";
import {NotificationManager} from "react-notifications";
import i18n from "i18next";

export const doGet = (url, params, callbackSuccess, callbackError) => {
  try {
    return axios
      .get(url, {
        params: params,
        headers: authHeader(),
      })
      .then((res) => {
        if (callbackSuccess && callbackSuccess instanceof Function) {
          callbackSuccess(res);
        } else {
          NotificationManager.success(i18n.t("get.success"), "", 2000);
        }
      })
      .catch((error) => {
        console.log(error);
        if (callbackError && callbackError instanceof Function) {
          callbackError(error);
        } else if (error.response && error.response.data && error.response.data.err_code) {
          NotificationManager.error(i18n.t(error.response.data.err_code), "", 2000);
        } else {
          NotificationManager.error(i18n.t("another.error"), "", 2000);
        }
      });
  } catch (ex) {
    console.log("Another error");
  }
};

export const doPost = (url, data, callbackSuccess, callbackError) => {
  try {
    return axios
      .post(url, data, {
        headers: authHeader(),
      })
      .then((res) => {
        if (callbackSuccess && callbackSuccess instanceof Function) {
          callbackSuccess(res);
        } else {
          NotificationManager.success(i18n.t("post.success"), "", 2000);
        }
      })
      .catch((error) => {
        if (callbackError && callbackError instanceof Function) {
          callbackError(error);
        } else if (error.response && error.response.data && error.response.data.err_code) {
          NotificationManager.error(i18n.t(error.response.data.err_code), "", 2000);
        } else {
          NotificationManager.error(i18n.t("another.error"), "", 2000);
        }
      });
  } catch (ex) {
    console.log("Another error");
  }
};

export const doPut = (url, data, callbackSuccess, callbackError, params) => {
  try {
    return axios
      .put(url, data, {
        headers: authHeader(),
        params: params,
      })
      .then((res) => {
        if (callbackSuccess && callbackSuccess instanceof Function) {
          callbackSuccess(res);
        } else {
          NotificationManager.success(i18n.t("put.success"), "", 2000);
        }
      })
      .catch((error) => {
        if (callbackError && callbackError instanceof Function) {
          callbackError(error);
        } else if (error.response && error.response.data && error.response.data.err_code) {
          NotificationManager.error(i18n.t(error.response.data.err_code), "", 2000);
        } else {
          NotificationManager.error(i18n.t("another.error"), "", 2000);
        }
      });
  } catch (ex) {
    console.log("Another error");
  }
};

export const doDelete = (url, callbackSuccess, callbackError) => {
  try {
    return axios
      .delete(url, {
        headers: authHeader(),
      })
      .then((res) => {
        if (callbackSuccess && callbackSuccess instanceof Function) {
          callbackSuccess(res);
        } else {
          NotificationManager.success(i18n.t("delete.success"), "", 2000);
        }
      })
      .catch((error) => {
        if (callbackError && callbackError instanceof Function) {
          callbackError(error);
        } else if (error.response && error.response.data && error.response.data.err_code) {
          NotificationManager.error(i18n.t(error.response.data.err_code), "", 2000);
        } else {
          NotificationManager.error(i18n.t("another.error"), "", 2000);
        }
      });
  } catch (ex) {
    console.log("Another error");
  }
};

export const getHeader = (contentType) => {
  let headers = {
    "Content-Type": contentType,
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "*",
    "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
    Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
  };
  return { headers: headers };
};
