import React, {Component} from "react";
import AuthService from "../services/auth.service";

export default class Logout extends Component {

  constructor(props) {
    super(props);
    AuthService.logout();
    this.props.history.push("/");
  }

  render() {
    return (
        <> </>
    );
  }

}