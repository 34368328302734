import React, {Component} from "react";
import * as Images from "../components/images";
import * as Constants from "../utils/constants";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import axios from "axios";
import * as Validator from "../utils/validator";
import {withTranslation} from "react-i18next";
import HeaderMobile from "../components/HeaderMobile";

class Scan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountType: JSON.parse(localStorage.getItem('user')).accountType,
      user: JSON.parse(localStorage.getItem('user')),
      displayMode: '',
      avatarChange: false,
      planNo: '',
      errMessage: ''
    }

    this.inputRef = React.createRef()
    this.handleChangeFile = this.handleChangeFile.bind(this);
  }

  getHeader(type) {
    let headers = {
      'Content-Type': type,
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': '*',
      'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
      'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).accessToken
    }
    return {headers: headers};
  }

  handleChangeFile(e, t) {
    e.preventDefault();

    const reader = new FileReader();
    const file = e.target.files[0];

    reader.onload = () => {
      const dataUrl = reader.result;
      this.setState({ imageUpload: dataUrl });
    };

    reader.readAsDataURL(file);

    if (e.target.files[0]) {
      this.setState({displayMode: 'loading'});
      let formData = new FormData();
      let header = this.getHeader('multipart/form-data');
      formData.append("file", e.target.files[0]);

      axios.post(Constants.ARUCO_URL, formData, header)
      .then((res) => {
        this.setState({
          scanNumber: res.data
        })
        if (res.data > 100 && res.data < 300) {

          axios.get(Constants.PLAN_DETAIL_URL + res.data, this.getHeader('application/json'))
          .then(res => {
            this.setState({
              displayMode: 'flight',
              planData: res.data,
              planNo: res.data.planNo
            })

          }).catch((error) => {
            this.setState({
              error: t('schedule.label.marker') + res.data + t('scan.is_not_set'),
              displayMode: 'error',
              planData: null,
              planNo: null
            })
          });
        } else if (res.data > 0 && res.data < 100) {
          if (this.state.planNo) {
            axios.get(Constants.PLAN_DETAIL_URL + this.state.planNo, this.getHeader('application/json'))
            .then(res => {
              this.setState({
                displayMode: 'marker',
                planData: res.data,
                planNo: res.data.planNo
              })
            }).catch((error) => {
              this.setState({
                error: t('schedule.label.marker') + res.data + t('scan.is_not_set'),
                displayMode: 'error',
                planData: null,
                planNo: null
              })
            });
          } else {
            this.setState({
              planData: null,
              displayMode: 'error',
              error: this.state.accountType === 'robot' ? t('scan.please_enter_the_move_plan_number') : t('scan.please_enter_your_flight_plan_number'),
              planNo: null
            })
          }
        } else {
          this.setState({
            error: t('scan.the_marker_does_not_load'),
            displayMode: 'error',
            planData: null,
            planNo: null
          })
        }
      }).catch((error) => {
        this.setState({
          error: error.response.data.err_msg === 'マーカーをスキャンできませんでした' ? 
            t('scan.scaning_multiple_marker') : t('scan.the_marker_does_not_load'),
          displayMode: 'error',
          planData: null,
          // planNo: null
        })
      });
    }
  }

  handleCaptureImage(t) {
    const planNo = this.state.planNo;
    if(!planNo) {
      this.setState({
        errMessage: t('scan.validate_input_required')
      })
      this.inputRef.current.focus()
    } else if(this.state.errMessage) {
      this.inputRef.current.focus()
    } else {
      document.getElementById('fileUpload').click();
    }
  }

  calculateRange(data, isRobot) {
    if (data != null) {
      if (!isRobot) {
        let result = 0;
        for (var i = data.length - 1; i >= 0; i--) {
          result = result + data[i].marker_distance
        }
        return result;
      } else {
        let result = 0;
        for (var i = data.length - 1; i >= 0; i--) {
          result = result + data[i].move_distance
        }
        return result;
      }

    }
  }

  savePlanNo() {}

  handleChange(t, e) {
    const {value} = e.target
    if(!value) {
      this.setState({
        errMessage: t('scan.validate_input_required'),
        planNo: value
      })
    } else if(!/^-?\d+$/.test(value) || parseInt(value) < 101 || parseInt(value) > 299){
      this.setState({ 
        errMessage: t('scan.validate_input_format'),
        planNo: value
      })
    } else this.setState({
      planNo: value,
      errMessage: ''
    });
  }

  translateDirection(direction, t) {
    if (direction === "right") {
      return t('common.right');
    }
    if (direction === "left") {
      return t('common.left');
    }
    if (direction === "turn") {
      return t('common.turn');
    }
    if (direction === "landed") {
      return t('common.landed');
    }
    return '';
  }

  // A Plan
  renderFlightPlanDetail(t) {
    let plan = this.state.planData;
    if (plan) {
      let isRobot = (plan.mode === 'robot');
      return (
        <>
          <div className="scan-flight-plan" style={{borderRadius: '10px 10px 0 0'}}>
            <table>
              <tr>
                <td>Corporate Name</td>
                <td>{plan.planContent.user.corp}</td>
              </tr>
              <tr>
                <td>{isRobot ? t('planView.travel_plan_number') : t('plan.drone.plan_no')}</td>
                <td>{plan.planNo}</td>
              </tr>
            </table>
          </div>
          {plan.planContent.flight.flight_marker.map((content) => {
            return (
              <div className="scan-flight-plan">
                <table>
                  <tr>
                    <td>{t('scan.marker_ID')}</td>
                    <td>{content.id}</td>
                  </tr>
                  <tr>
                    <td>{t('plan.marker.drone.height')}</td>
                    <td>{content.height} m</td>
                  </tr>
                  <tr>
                    <td>{t('plan.marker.drone.wall_distance')}</td>
                    <td>{content.wall_distance} m</td>
                  </tr>
                  <tr>
                    <td>{t('plan.marker.drone.landing_port')}</td>
                    <td>
                      {(!content.isStation || content.isStation === 'false') ? t('common.no') : (content.isStation === 'else' ? t('common.others') : content.isStation)}
                    </td>
                  </tr>
                  <tr>
                    <td>{t('plan.marker.drone.direction')}</td>
                    <td>{this.translateDirection(content.direction, t)}</td>
                  </tr>
                  <tr>
                    <td>{t('plan.marker.drone.marker_distance')}</td>
                    <td>{content.marker_distance} m</td>
                  </tr>
                </table>
              </div>
            )
          })}
        </>
      )
    }
  }

  // A marker
  renderFlightMarkerDetail(t) {
    let plan = this.state.planData;
    if (plan) {
      let isRobot = (plan.mode === 'robot');
      let markers;
      if (isRobot && plan.planContent) {
        markers = plan.planContent.movement.movement_marker.filter(e => e.id === this.state.scanNumber);
      } else {
        markers = plan.planContent.flight.flight_marker.filter(e => e.id === this.state.scanNumber);
      }
      if (markers) {
        return (
          <>
            <div className="scan-flight-plan" style={{borderRadius: '10px 10px 0 0'}}>
              <table>
                <tr>
                  <td>Corporate Name</td>
                  <td>{plan.planContent.user.corp}</td>
                </tr>
                <tr>
                  <td>{isRobot ? t('planView.travel_plan_number') : t('plan.drone.plan_no')}</td>
                  <td>{plan.planNo}</td>
                </tr>
              </table>
            </div>
            {markers.map((marker, index) => {
              // Only show at the most 2 markers
              if (index < 2) {
                return (
                  <div className="scan-flight-plan">
                    <table>
                      <tr>
                        <td>{t('scan.marker_ID')}</td>
                        <td>{marker.id}</td>
                      </tr>
                      <tr>
                        <td>{t('plan.marker.drone.height')}</td>
                        <td>{marker.height} m</td>
                      </tr>
                      <tr>
                        <td>{t('plan.marker.drone.wall_distance')}</td>
                        <td>{marker.wall_distance} m</td>
                      </tr>
                      <tr>
                        <td>{t('plan.marker.drone.landing_port')}</td>
                        <td>
                          {(!marker.isStation || marker.isStation === 'false') ? t('common.no') : (marker.isStation === 'else' ? t('common.others') : marker.isStation)}
                        </td>
                      </tr>
                      <tr>
                        <td>{t('plan.marker.drone.direction')}</td>
                        <td>{this.translateDirection(marker.direction, t)}</td>
                      </tr>
                      <tr>
                        <td>{t('plan.marker.drone.marker_distance')}</td>
                        <td>{marker.marker_distance} m</td>
                      </tr>
                      {isRobot &&
                        <tr>
                          <td>{t('planView.moving_angle')}</td>
                          <td>{marker.move_angle} deg</td>
                        </tr>
                      }
                    </table>
                  </div>
                )
              }
            })}
          </>
        )
      } else {
        return (
          <p>{t('plan.marker_name')}{this.state.scanNumber}{t('scan.is_not_set')}</p>
        )
      }
    }
  }

  render() {
    const {t} = this.props;
    return (
      <>
        <HeaderMobile />
        <div className="m-content">
          {!(this.state.displayMode === 'flight' || this.state.displayMode === 'marker') ?
            <>
              <h2 className="scan-title">{t("scan.title-scan")}</h2>

              <div className="scan-form-content">
                <div className="inputPlanNo">
                  <Form 
                    onSubmit={this.savePlanNo.bind(this)} 
                    ref={c => { this.form = c }}
                  >
                    <label>{this.state.user.accountType === 'robot' ? t('planView.travel_plan_number') : t('scan.enter_flight_plan_id')}</label>
                    <input 
                      ref={this.inputRef}
                      type="text" 
                      className="scan-dps-input"
                      name="planName"
                      placeholder="101 -> 299"
                      value={this.state.planNo}
                      min={101}
                      max={299}
                      onChange={this.handleChange.bind(this, t)}
                    />
                    {this.state.errMessage && <small className="input-error">{this.state.errMessage}</small>}
                  </Form>
                </div>

                <div className="capture-aruco-code">
                  <input 
                    className="hidden"
                    type="file" 
                    accept="image/*;capture=camera" 
                    id="fileUpload" 
                    onClick={(event) => { event.target.value = null }} 
                    onChange={(e) => this.handleChangeFile(e, t)}
                  />
                  <button className="capture" onClick={this.handleCaptureImage.bind(this, t)}>
                    {t('scan.capture_the_image')}
                  </button>
                </div>

                {this.state.displayMode === 'loading' ?
                  <div className='loading'>
                    <img src={require('../assets/images/loading.gif')} alt="loading"/>
                  </div>
                  : ''
                }
                {this.state.displayMode === 'error' ? <p className="error-message">{this.state.error}</p> : ''}
              </div>
            </>
            :
            <div className="scan-detail-header">
              <div className="plan-no">
                <label>{t("scan.flight_plan_id")}</label>
                <input type="number" value={this.state.planNo} />
              </div>
              <img className="img-scan-result" src={this.state.imageUpload} alt="" />
            </div>
          }

          {this.state.displayMode === 'flight' && this.state.planData && this.renderFlightPlanDetail(t)}
          {this.state.displayMode === 'marker' && this.state.planData && this.renderFlightMarkerDetail(t)}
        </div>
      </>
    );
  }
}

export default withTranslation()(Scan);