import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import * as Images from "../images";
import {Map} from "../map/map";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import * as Validator from "../../utils/validator";
import React, {useEffect, useRef, useState} from "react";
import {MarkerDraggable} from "../map/markerDraggable";
import cloneDeep from 'lodash/cloneDeep';
import {useTranslation} from 'react-i18next';
import {isTablet} from 'react-device-detect';
import {TouchBackend} from 'react-dnd-touch-backend';
import {NotificationManager} from "react-notifications";

// Tab content Marker setting of Plan Tabs
// Usage: Set marker and port position for status and result - store infomation in map_image and map_content columns
const PlanMarkerSettings = (props) => {
  const {t, i18n} = useTranslation();
  let {id} = props.plan;
  let {mapImage, markersChange, handleSaveMap, handleMakerChange, tab} = props
  const accountType = JSON.parse(localStorage.getItem('user')).accountType;
  const [planContent, setPlanContent] = useState(props.plan.planContent);

  const [action, setAction] = useState('upload'); //upload, setting_map

  const refBtnUpload = useRef(null)
  const refBtnSettingMap = useRef(null)

  useEffect(() => {
    if (props) {
      setPlanContent(props.plan.planContent);
    }
    if(props.mapImage) {
      setAction('setting_map')
    }
  }, [props]);

  useEffect(() => {
    switch (action) {
      case 'upload':
        refBtnUpload.current.classList.add('active')
        refBtnUpload.current.classList.remove('pass')
        refBtnSettingMap.current.classList.remove('active', 'pass')
        return
      case 'setting_map':
        refBtnUpload.current.classList.remove('active')
        refBtnUpload.current.classList.add('pass')
        refBtnSettingMap.current.classList.add('active')
        refBtnSettingMap.current.classList.remove('pass')
        return
      default: return
    }
  }, [action])

  useEffect(() => {
    setAction('upload')
  }, [tab])

  // Render marker list
  const renderMakerList = () => {
    let flightMarker = cloneDeep(planContent.flight.flight_marker);
    if (accountType !== 'robot') {
      let takeOffMarker = {
        id: 0,
        isTakeoff: true
      };

      let landingMarker = {
        id: -1,
        isLanding: true
      };
      const uniqMarkerIds = [];

      let uniqIdFlightMarker = [];
      flightMarker.forEach((item, index) => {
        if (!uniqMarkerIds.includes(item.id)) {
          uniqMarkerIds.push(item.id);
          uniqIdFlightMarker.push(item);
        }
      });
      flightMarker = [
        takeOffMarker,
        ...uniqIdFlightMarker,
        landingMarker,
      ];
    }

    if (flightMarker) {
      return flightMarker.map((item, index) => {
        let dropped = false;
        if (markersChange) {
          dropped = markersChange.some((markerInMap) => parseInt(markerInMap.id) === parseInt(item.id));
        }

        if (dropped) {
          return (
            <MarkerDraggable {...item} displayType='dropped' key={index}/>
          );
        } else {
          return (
            <MarkerDraggable {...item} displayType='drag-area' key={index}/>
          );
        }
      });
    }
  };

  const uploadImageMap = () => {
    setAction('upload')
  };

  const showSettingMap = () => {
    if (mapImage) {
      setAction('setting_map') 
    } else {
      NotificationManager.info(t('planMap.info.not_set_image'), '', 2000);
    }
  }

  const handleClickChangeFile = () => {
    document.getElementById('uploadMapImage').value = null;
    document.getElementById('uploadMapImage').click();
  }

  const switchContentTabEditMap = () => {
    switch (action) {
      case 'upload':
        return(
          <>
            <h3 className='tab-title'>{t('plan.tab_3.upload.title')}</h3>
            <p className='tab-desc'>
              <span>{t('plan.tab_3.upload.desc_1')} </span>
              <span>{t('plan.tab_3.upload.desc_2')}</span>
            </p>
            <div className="tab-from" onClick={() => handleClickChangeFile()}>
              <label>{t('plan.tab_3.upload.label')}</label>
              <div className="mask-tab-form">
                <Form onSubmit={handleSaveMap} className="d-inline-block">
                  <Input
                    id="uploadMapImage"
                    type="file"
                    className="hidden"
                    name="planName"
                    placeholder={accountType === 'robot' ? t('planMap.travel_plan_name') : t('planMap.flight_plan_name')}
                    message={accountType === 'robot' ? t('planMap.Please_enter_the_move_plane_name') : t('planMap.Please_enter_the_flight_plane_name')}
                    accept="image/*,application/pdf"
                    validations={[Validator.required]}
                    onChange={props.handleChangeImageMapFile}
                  />
                </Form>
                <span>{t('plan.tab_2.upload.selected')}</span>
              </div>
            </div>

            <div className="tab-actions">
              <button className='next-tab' onClick={showSettingMap}>{t('plan.btn_next_tab')}</button>
            </div>
          </>
        )
      case 'setting_map':
        return(
          <>
            <h3 className='tab-title'>{t('plan.tab_3.setting_map.title')}</h3>
            <p className='tab-desc'>
              <span>{t('plan.tab_3.setting_map.desc_1')} </span>
              <span>{t('plan.tab_3.setting_map.desc_2')}</span>
            </p>
            <div className="marker_list">
              {renderMakerList()}
            </div>
            <div className="tab-actions">
              <button className='pre-tab' onClick={uploadImageMap}>{t('plan.btn_pre_tab')}</button>
            </div>
          </>
        )
      default: return
    }
  }
 
  return (
    <>
      <DndProvider backend={isTablet ? TouchBackend : HTML5Backend}>
        <div className="map-editor">
          <div className="menu-zone">
            <div className="action">
              <button ref={refBtnUpload} className='dps-button_tab' onClick={uploadImageMap}>{t('plan.tab_3.upload.text_btn')}</button>
              <Images.IconArrowTab color='currentColor' />
              <button ref={refBtnSettingMap} className="dps-button_tab" onClick={showSettingMap}>{t('plan.tab_3.setting_map.text_btn')}</button>
            </div>
          </div>
          <div className="map-zone">
            <div className="content-map-zone">
              {switchContentTabEditMap()}
            </div>
            <div className="preview-map-zone">
              <Map 
                url={mapImage}
                onMarkerChange={handleMakerChange}
                id={id}
                markers={markersChange}
                tab={props.tab}
                action={action}
              />
            </div>
          </div>
        </div>
      </DndProvider>
    </>
  );

};

export default PlanMarkerSettings;