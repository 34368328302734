import React, {useState, useEffect, useRef, memo} from 'react';
import { useTranslation } from 'react-i18next';
import SockJsClient from "react-stomp";
import axios from 'axios';
import update from "immutability-helper";

import * as Images from '../images';
import * as Constants from "../../utils/constants";
import { timeConverter } from '../../utils/utils';
import { getHeader } from '../../services/api.service';

const DeviceList = ({ data, reLoadData, onDeviceSelect, deviceIcons, isMove }) => {
  const corpId = JSON.parse(localStorage.getItem("user")).corpId;
  const corpKey = JSON.parse(localStorage.getItem("user")).corpKey;
  const accountType = JSON.parse(localStorage.getItem("user")).accountType;
  const { t } = useTranslation();

  const [deviceStatusList, setDeviceStatusList] = useState(data);

  const ws = useRef(null);

  useEffect(() => {
    const deviceStatusSort = handleSortDevice(data)
    setDeviceStatusList(deviceStatusSort)
  }, [data])

  const handleSortDevice = (data) => {
    const compareDates = (dateString1, dateString2) => {
      const date1 = new Date(dateString1)
      const date2 = new Date(dateString2)
      if(date1 > date2) return dateString1
      if(date1 < date2) return dateString2
    }
    const dataConvert = data.reduce((preVal, item) => {
      let lastUpdate
      if(!item.device) {
        lastUpdate = item.station.updated;
      } else if(!item.station) {
        lastUpdate = item.device.deviceInfo.updated;
      } else {
        lastUpdate = compareDates(item.device.deviceInfo.updated, item.station.updated)
      }
      return [
        ...preVal,
        {
          ...item, lastUpdate
        }
      ]
    }, [])
    const dataSortInit = dataConvert.filter(item => item.lastUpdate === undefined);
    const dataSortEmpty = dataConvert.filter(item => item.lastUpdate === null);
    const dataSortReal = dataConvert.filter(item => item.lastUpdate).sort((item1, item2) => {
      return (Date.parse(item2.lastUpdate) / 1000) - (Date.parse(item1.lastUpdate) / 1000)
    });
    const dataSort = [...dataSortReal, ...dataSortEmpty, ...dataSortInit]
    return dataSort;
  }
  const handleDeviceNotFound = (dataCheck) => {
    const {objectId, objectType} = dataCheck;
    if(+objectType === 1) {
      getDeviceDetail(+objectId);
    }
    if(+objectType === 2) {
      getStationDetail(objectId);
    }
  }
  const getDeviceDetail = (droneId) => {
    axios.get(Constants.DEVICE_STATUS_URL + droneId, getHeader()).then((res) => {
      const index = deviceStatusList.findIndex(
        (item) => droneId === item.device?.deviceInfo.id
      );
      const deviceStatusSort = handleSortDevice(update(deviceStatusList, {
        [index]: { device: { $set: res.data } }
      }))
      setDeviceStatusList(deviceStatusSort)
    });
  }
  const getStationDetail = (stationId) => {
    axios.get(Constants.GET_STATION_STATUS + corpId, {params: {stationId: stationId}, headers: { corpKey } }).then((res) => {
      const index = deviceStatusList.findIndex(
        (item) => stationId === item.station?.stationId
      );
      if(res.data.droneId !== deviceStatusList[index].station?.droneId) {
        reLoadData()
      } else{
        const deviceStatusSort = handleSortDevice(update(deviceStatusList, {
          [index]: { station: { $set: res.data } }
        }))
        setDeviceStatusList(deviceStatusSort)
      }
    })
  }

  const renderDeviceIcon = (c, item) => {
    if (accountType === "robot") {
      let iconUrl;
      deviceIcons.map((item) => {
        if (item.key === "ROBOT_ICON" && item.value) {
          iconUrl = Constants.getResourceUrl() + item.value;
        }
      });

      if (!iconUrl) {
        iconUrl = Images.IconRobot;
      }

      return (
        <img
          alt=""
          className={"device-icon-tag " + (c ? c : "")}
          src={iconUrl}
        />
      );
    } else if(item) {
      const { deviceInfo } = item;
      if (deviceInfo.imagePath) {
        let iconUrl = Constants.getResourceUrl() + deviceInfo.imagePath;

        return (
          <img
            alt=""
            className={"device-icon-tag " + (c ? c : "") + (deviceInfo.status === 'idle' ? 'not-found' : '')}
            src={iconUrl}
          />
        );
      } else {
        return <Images.Icons name="IconPlane" className = {"device-icon-tag " + (c ? c : '') + (deviceInfo.status === 'idle' ? 'not-found' : '')} />;
      }
    } else {
      return <Images.Icons name="IconPlane" className="device-icon-tag empty" />;
    }
  }

  const renderStationIcon = (status) => {
    switch (status) {
      case 'closed':
        return <img src={require('../../assets/images/cs-closed.png')} alt="" className='device-icon-tag' />
      case 'closing':
        return <img src={require('../../assets/images/cs-closeing.png')} alt="" className='device-icon-tag' />
      case 'opening':
        return <img src={require('../../assets/images/cs-opening.png')} alt="" className='device-icon-tag' />
      case 'opened':
        return <img src={require('../../assets/images/cs-opened.png')} alt="" className='device-icon-tag' />

      default: return <img src={require('../../assets/images/cs-closed.png')} alt="" className="device-icon-tag not-found" />;
    }
  }

  const getClassName = (param) => {
    switch (param) {
      //drone
      case "idle":
        return "not-found";
      case "charging":
        return "charging";
      case "non-charging":
        return "non-charging";
      case "ready":
        return "ready";
      case "landed":
        return "landed";
      case "empty":
        return "empty";
      case "now-flying":
        return "now-flying";
      case "charging-empty":
        return "charging-empty";

      //station
      case "opened":
        return "opened";
      case "closed":
        return "closed";
      case "opening":
        return "opening";
      case "closing":
        return "closing";

      default: return;
    }
  }

  const getStatus = (param) => {
    switch (param) {
      //drone
      case 'idle':
        return 'Not Found';
      case 'charging':
        return 'Charging';
      case 'non-charging':
        return 'Non Charging';
      case 'ready':
        return 'Ready';
      case 'takeoff':
        return '';
      case 'marker_arv':
        return '';
      case 'marker_left':
        return '';
      case 'image':
        return '';
      case 'video':
        return '';
      case 'landed':
        return 'Landed';

      //station
      case 'closed':
        return 'Closed';
      case 'opened':
        return 'Opened';
      case 'closing':
        return 'Closing';
      case 'opening':
        return 'Opening';

      default: return 'Not Found';
    }
  }

  if(deviceStatusList != null && deviceStatusList.length > 0) return (
    <>
      <SockJsClient
        url={Constants.WS_URL}
        topics={["/topic/not-found/" + JSON.parse(localStorage.getItem("user")).id]}
        onMessage={(msg) => {
          handleDeviceNotFound(msg);
        }}
        ref={ws}
      />
      <SockJsClient
        url={Constants.WS_URL}
        topics={["/topic/drone/" + JSON.parse(localStorage.getItem("user")).id,]}
        onMessage={(msg) => { getDeviceDetail(msg) }}
        ref={ws}
      />
      <SockJsClient
        url={Constants.WS_URL}
        topics={["/topic/station/" + JSON.parse(localStorage.getItem("user")).id,]}
        onMessage={(msg) => getStationDetail(msg)}
        ref={ws}
      />
      {deviceStatusList.map((item, index) => {
        const {station, device} = item;
        const haveAlert = device?.deviceAlert && device?.deviceAlert.length > 0;
        let isCheckStationNotFound = false;
        if(station && station.isNotFound) {
          isCheckStationNotFound = true
        }
        const isFlying = device && !(
          device.deviceInfo.status === 'idle' ||
          device.deviceInfo.status === 'charging' ||
          device.deviceInfo.status === 'non-charging' ||
          device.deviceInfo.status === 'ready' ||
          device.deviceInfo.status === 'landed'
        )

        return (
          <div
            key={index}
            className="device-item list"
            onClick={isMove ? null : (e) => onDeviceSelect(e, device?.deviceInfo.id, station?.stationId, index, deviceStatusList)}
          >
            <div className="drone device" style={!device ? {alignSelf: 'center'} : undefined}>
              {device ? 
                <>
                  {renderDeviceIcon(haveAlert ? 'border-red blink ' : '', device)}
                  <div className='device-info'>
                    <p className="type">{device.deviceInfo.deviceType}</p>
                    <p className="name">{device.deviceInfo.deviceID}</p>
                    <div className='status-battery'>
                      <p 
                        className={
                          "status " + 
                          getClassName(isFlying ? "now-flying" : device.deviceInfo.status) + 
                          (isFlying ? " blink" : "")}
                      >
                        { isFlying ? "Flying..." : getStatus(device.deviceInfo.status) }
                      </p>
                      <div className={"battery-icon " + (device.deviceInfo.status === 'idle' ? 'not-found' : '')}>
                        <span>{device.deviceInfo.battery || "---"}%</span>
                        <Images.IconBattery />
                      </div>
                    </div>
                    <div className="time-schedule">
                      <Images.Icons name="IconSchedule" /> 
                      <span className="time">{device.deviceInfo.scheduleTime ? timeConverter(device.deviceInfo.scheduleTime , t) : 'Not scheduled'}</span>
                    </div>
                  </div>
                </>
                :
                <p className='no-drone'>No drone</p>
              }
            </div>

            {isFlying ? 
              <div className='flying-info'>
                <p className='plan-flying'>
                  {device.deviceInfo.planName}
                </p>
                <p className='current-time-status'>
                  {timeConverter(device.deviceInfo.utc, t)} {device.deviceInfo.status}
                </p>
                <p className='msg'>{device.deviceInfo.msg}</p>
              </div>
              :
              <>
                <hr className='dash'/>

                <div className="station device" style={!station ? {opacity: 0} : undefined}>
                  {station && isCheckStationNotFound ? 
                    <>
                      <img src={require('../../assets/images/cs-closed.png')} alt="" className='device-icon-tag not-found' />
                      <div className='device-info'>
                        <p className="type">Station</p>
                        <p className="name">{station.stationId}</p>
                        <p className="status not-found">Not Found</p>
                      </div>
                    </>
                    :
                    !station ? 
                    <>
                      <img src={require('../../assets/images/cs-closed.png')} alt="" className='device-icon-tag empty' />
                      <div className='device-info'>
                        <p className="type empty">Station</p>
                        <p className="name"></p>
                        <p className="status"></p>
                      </div>
                    </>
                    :
                    <>
                      {renderStationIcon(station.lid)}
                      <div className='device-info'>
                        <p className="type">Station</p>
                        <p className="name">{station.stationId}</p>
                        <p className={"status " +
                        (getClassName(station.lid)) +
                        (station.lid === "opening" || station.lid === "closing" ? " blink" : "")}>
                          {getStatus(station.lid)}
                        </p>
                      </div>
                    </>
                  }
                </div>
              </>
            }
            <div className="overlay"/>
          </div>
        )})
      }
      
    </>
  )
};

export default memo(DeviceList);