import React from "react";
import * as Constants from "../../utils/constants";
import MarkerDisplay from "../markerDisplay";
import {IconDirection, IconKiriha} from '../images'
import { t } from 'i18next'

export default class MapPreview extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      accountType: JSON.parse(localStorage.getItem("user")).accountType,
      loading: false,
      windowSize: 0,
    };

    this.imagePreview = React.createRef();
    this.imageArea = React.createRef();
    this.imageContainer = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.url !== prevProps.url) {
      this.setState({
        loading: false,
      });
    }

    if(
      (this.state.loading !== prevState.loading && this.state.loading) ||
      prevState.windowSize !== this.state.windowSize || 
      prevProps.sizeMain !== this.props.sizeMain ||
      prevProps.elementMainView !== this.props.elementMainView ||
      prevProps.isFullScreenMode !== this.props.isFullScreenMode
    ) {
      this.props.url && this.imageLoaded()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState(
      { 
        windowSize: {
          width: window.innerWidth,
          height: window.innerHeight,
        } 
      }
    );
  };

  convertDataForPreview(data) {
    let mapContentDisplay = [];
    let flightPlanContent;
    let deviceStatus = data.deviceStatus;
    const currentTime = this.props.currentTime;

    if (this.state.accountType === "robot") {
      flightPlanContent = data.inspection.jsonContent.flight.flight_marker;
    } else {
      let takeoffItem = {
        id: Constants.TAKEOFF_MARKER_ID,
        isTakeoff: true,
      };
      let landingItem = {
        id: Constants.LANDING_MARKER_ID,
        isLanding: true,
      };
      flightPlanContent = [
        takeoffItem,
        ...data.inspection.jsonContent.flight.flight_marker,
        landingItem,
      ];
    }
    let currentTimeVideo = 0;

    let latestStatus = 0;
    let latestLeftId = 0;
    flightPlanContent.map((item, index) => {
      if (this.state.accountType === "robot") {
        var takeoff = deviceStatus.find(function (result) {
          return result.status === "takeoff";
        });
        currentTimeVideo = Number(takeoff.content.utc) + Number(currentTime);
      }

      if (item.isTakeoff) {
        var takeoff = deviceStatus.find(function (result) {
          return result.status === "takeoff";
        });
        item = { ...takeoff, ...item };
        currentTimeVideo = Number(item.content.utc) + Number(currentTime);

        mapContentDisplay.push(item);
      } else if (item.isLanding) {
        let markerInfo;
        deviceStatus
          .filter((filterItem) => filterItem.status === "landed")
          .map((markerStatus) => {
            markerInfo = { ...markerStatus };
          });

        item = { ...markerInfo, ...item };
        mapContentDisplay.push(item);
      } else {
        let markerInfo, markerContent;
        let trackingPassItem = false;
        let makerArrTime = null;
        let nextArvMarker = null;
        let arrMarkerArv =
          deviceStatus.filter(
            (filterItem) =>
              filterItem.status === "marker_arv" &&
              ("" + filterItem.content.id === "" + item.id ||
                filterItem.content.marker_id === "" + item.id)
          ) || [];
        for (let it = 0; it < arrMarkerArv.length; it++) {
          let markerStatus = arrMarkerArv[it];
          if (markerStatus.id <= latestStatus) {
            continue;
          }
          if (markerStatus.content.utc < currentTimeVideo) {
            trackingPassItem = true;
          }
          latestStatus = markerStatus.id;
          markerInfo = { ...markerStatus };
          markerContent = { ...markerContent, ...markerStatus.content };
          makerArrTime = +markerStatus.content.utc;
          if (it < arrMarkerArv.length - 1) {
            nextArvMarker = arrMarkerArv[it + 1];
          }
          break;
        }
        let arrMarkerLeftOrImage =
          deviceStatus.filter(
            (filterItem) =>
              ("" + filterItem.content.id === "" + item.id ||
                filterItem.content.marker_id === "" + item.id) &&
              (filterItem.status === "marker_left" ||
                filterItem.status === "image")
          ) || [];
        for (let ls = 0; ls < arrMarkerLeftOrImage.length; ls++) {
          let markerStatus = arrMarkerLeftOrImage[ls];
          // Only find image that come before next same marker id
          if (markerStatus.id > latestStatus) {
            if (
              markerStatus.status === "image" &&
              (!nextArvMarker || markerStatus.id < nextArvMarker.id)
            ) {
              latestStatus = markerStatus.id;
              markerInfo = { ...markerStatus };
              markerContent = { ...markerContent, ...markerStatus.content };
            }
            if (
              markerStatus.status === "marker_left" &&
              markerStatus.content.utc < currentTimeVideo
            ) {
              latestStatus = markerStatus.id;
              markerInfo = { ...markerStatus };
              markerContent = { ...markerContent, ...markerStatus.content };
              trackingPassItem = true;
              break;
            }
            if (markerStatus.id > latestLeftId) {
              if (markerStatus.status === "image") {
                markerContent = { ...markerContent, ...markerStatus.content };
              } else if (markerStatus.status === "marker_left") {
                latestLeftId = markerStatus.id;
                break;
              }
            }
          }
        }

        item = { ...markerInfo, ...item };
        item["content"] = markerContent;
        item["makerArrTime"] = makerArrTime;
        item["trackingPassItem"] = trackingPassItem;

        if (trackingPassItem) {
          mapContentDisplay.push(item);
        } else {
          item["status"] = "pending";
          mapContentDisplay.push(item);
        }
      }
    });
    return mapContentDisplay;
  }

  imageLoaded() {
    const elem = this.imagePreview.current;
    const imageContainer = this.imageContainer.current;

    if(elem && imageContainer) {
      const zoomY = imageContainer.clientHeight / elem.naturalHeight;
      const zoomX = imageContainer.clientWidth / elem.naturalWidth;
      
      const zoom = Math.min(zoomX, zoomY);

      elem.width = zoom * elem.naturalWidth;
      elem.height = zoom * elem.naturalHeight;
  
      this.setState({
        zoom: zoom,
      });
    }
  }

  renderIndicator() {
    let currentMarker = this.props.currentMarker,
      endMarker = this.props.endMarker,
      start,
      end;
    start = this.props.markers.find((x) => x.id == currentMarker);
    end = this.props.markers.find((x) => x.id == endMarker);

    if (currentMarker === -1 && this.state.accountType !== "robot") {
      start = this.props.markers.find((item) => {
        return item.id === -1;
      });
    }

    if (!end && this.state.accountType !== "robot") {
      end = this.props.markers.find((item) => {
        return item.id === -1;
      });
    }

    let left = 0;
    let top = 0;
    let deg = 0;
    if (start) {
      if (start.status === "marker_left" || start.status === "takeoff") {
        if (start && end) {
          left = (start.left + end.left) / 2;
          top = (start.top + end.top) / 2;

          if (this.props.currentTime <= 0.001) {
            left = start.left;
            top = start.top;
          }
        } else {
          left = start.left;
          top = start.top;
        }
      } else {
        if (
          this.state.accountType === "robot" &&
          end &&
          this.props.currentTime > 0.001
        ) {
          left = (start.left + end.left) / 2;
          top = (start.top + end.top) / 2;
        } else {
          left = start.left;
          top = start.top;
        }
      }
    }

    if(start && end) {
      const tempMarker = {
        top: start.top,
        left: end.left
      }

      const widthStartToEnd = Math.sqrt(Math.pow(end.top - start.top, 2) + Math.pow(end.left - start.left, 2));
      const widthEndToTemp = Math.sqrt(Math.pow(tempMarker.top - end.top, 2) + Math.pow(tempMarker.left - end.left, 2));
      if(start.top >= end.top && start.left <= end.left){
        deg = (Math.asin(widthEndToTemp / widthStartToEnd) * 180) / Math.PI * -1;
      } else if (start.top <= end.top && start.left <= end.left) {
        deg = (Math.asin(widthEndToTemp / widthStartToEnd) * 180) / Math.PI;
      } else if (start.top <= end.top && start.left >= end.left) {
        deg = 180 - ((Math.asin(widthEndToTemp / widthStartToEnd) * 180) / Math.PI);
      } else if (start.top >= end.top && start.left >= end.left) {
        deg = (Math.asin(widthEndToTemp / widthStartToEnd) * 180) / Math.PI + 180;
      }
    }

    return (
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 1,
          transform:
            "translate3d(" +
            left * this.state.zoom +
            "px, " +
            top * this.state.zoom +
            "px, 0)",
          WebkitTransform:
            "translate3d(" +
            left * this.state.zoom +
            "px, " +
            top * this.state.zoom +
            "px, 0)",
        }}
      >
        <span
          href="#"
          className="intro-banner-vdo-play-btn"
          target="_blank"
        > 
          {this.props.currentTime > 0.01 && deg ? <IconDirection style={{transform: 'rotate(' + deg + 'deg)'}}/> : null}
          <span className="ripple pinkBg"></span>
          <span className="ripple pinkBg"></span>
        </span>
      </div>
    );
  }

  imageLoad() {
    this.setState({ loading: true });
  }

  imageError () {
    if (this.imagePreview.current) {
      this.imagePreview.current.style.aspectRatio = '16 / 9'
    }
  }

  render() {
    let imageSource = Constants.getResourceUrl() + this.props.url;
    if(this.props.url) {
      return (
        <div
          className="map-image-preview"
          ref={this.imageContainer}
        >
          <div
            className="image-map-view"
            ref={this.imageArea}
          >
            <img
              alt="mapimage"
              className="image-map-image"
              src={imageSource}
              onError={this.imageError.bind(this)}
              onLoad={this.imageLoad.bind(this)}
              ref={this.imagePreview}
            />
            {this.state.loading && this.props.markers
              ? this.props.markers.map((marker, index) => {
                  return (
                    <div
                      className="marker-item"
                      style={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                        transform:
                          "translate3d(" +
                          marker.left * this.state.zoom +
                          "px, " +
                          marker.top * this.state.zoom +
                          "px, 0)",
                        WebkitTransform:
                          "translate3d(" +
                          marker.left * this.state.zoom +
                          "px, " +
                          marker.top * this.state.zoom +
                          "px, 0)",
                      }}
                      key={index}
                    >
                      <MarkerDisplay
                        key={index}
                        {...marker}
                        nextMarker={this.props.endMarker}
                        markerPreview={this.convertDataForPreview(
                          this.props.activeInspection
                        )}
                        firstFlightLength={this.props.markers.length - 1}
                        currentTime={this.props.currentTime}
                      />
                    </div>
                  );
                })
              : ""}
            {this.state.loading && this.props.markers && this.props.currentTime
              ? this.renderIndicator()
              : ""}
          </div>
          {this.props.isKiriha && 
            <label className="kiriha-label kiriha-blink">
              {t("kiriha.label")}
              <IconKiriha />
            </label>
          } 
        </div>
      );
    } else return (
      <div style={{
        width: '100%',
        aspectRatio: '16 / 9',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <p>Not Setting</p>
      </div>
    )
  }
}
