import React, {useState, useEffect} from 'react';
import Form from "react-validation/build/form";
import { useTranslation } from 'react-i18next';
import DatePicker from "react-datepicker";
import enUS from "date-fns/locale/en-US";
import ja from "date-fns/locale/ja";
import "react-datepicker/dist/react-datepicker.css";
import * as Images from './images';

const SearchTable = ({data, onSearch, className, formSearch}) => {
  const { t, i18n } = useTranslation();
  const [dataSearch, setDataSearch] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [selectedDateFrom, setSelectedDateFrom] = useState(null);
  const [selectedDateTo, setSelectedDateTo] = useState(null);

  useEffect(() => {
    const lang = i18n.language;
    if(lang === 'en') {
      setSelectedLanguage(enUS);
    } else if (lang === 'ja') {
      setSelectedLanguage(ja);
    } 
  }, [])

  useEffect(() => {
    if(data) {
      setDataSearch(formSearch)
    }
  }, [data])

  useEffect(() => {
    if(dataSearch.dateFrom && dataSearch.dateTo && dataSearch.dateFrom > dataSearch.dateTo) {
      setErrorMessage(t('Search_Pagination.checkDate'))
    } else{
      setErrorMessage(null)
    }
  }, [dataSearch])

  const renderSearchFrom = () => {
    if(data) {
      return (
        <div className="form_search">
          {data.map(item => {
              if(item.typeSearch === 'text' || item.typeSearch === 'number') {
                return (
                  <div className="form_search-item" key={item.name}>
                    <label>{item.label}</label>
                    <input 
                      value={dataSearch[item.name]}
                      type={item.typeSearch} 
                      min={item.name === 'planNo' ? 101 : undefined}
                      max={item.name === 'planNo' ? 299 : undefined}
                      onChange={(e) => handleChangeValue(e, item.name)} />
                  </div>
                )
              }
              if(item.typeSearch === 'date') {
                return (
                  <div className="form_search-item" style={{width: 'auto'}} key={item.name}>
                    <label>{item.label}</label>
                    <div className="form_search-date">
                      <DatePicker
                        wrapperClassName='form_search-date-wrapper'
                        showIcon={true}
                        placeholderText={t("Search_Pagination.format-date")}
                        selected={selectedDateFrom}
                        onChange={(date) => handleChangeDateValue(date, item.name.split(' ')[0])}
                        locale={selectedLanguage}
                        dateFormat="yyyy/MM/dd"
                      />
                      -
                      <DatePicker
                        wrapperClassName='form_search-date-wrapper'
                        showIcon={true}
                        placeholderText={t("Search_Pagination.format-date")}
                        selected={selectedDateTo}
                        onChange={(date) => handleChangeDateValue(date, item.name.split(' ')[1])}
                        locale={selectedLanguage}
                        dateFormat="yyyy/MM/dd"
                      />
                    </div>
                    <span className="error-message">
                      {" "}
                      {errorMessage}
                    </span>
                  </div>
                )
              }
              if (item.typeSearch === 'select'){
                return (
                  <div className="form_search-item" key={item.name}>
                    <label>{item.label}</label>
                    <div className="mask-select">
                      <select onChange={(e) => handleChangeValue(e, item.name)} value={dataSearch[item.name]}>
                        <option>All</option>
                        {item.subData.map(it => {
                          return(
                            <option key={it} value={it}>{it}</option>
                          )
                        })}
                      </select>
                      <Images.Icons name="IconSelect" color="currentColor" />
                    </div>
                  </div>
                )
              }
          })}
          <button type='submit' disabled={errorMessage}>{t('Search_Pagination.search')}</button>
        </div>
      )
    }
  }

  const handleChangeValue = (e, name) => {
    let { value, type } = e.target;
    if(type.includes('select') && value === 'All') {
      value = null;
    }

    const newDataSearch = {...dataSearch, [name]: value}
    setDataSearch(newDataSearch)
  }

  const handleChangeDateValue = (date, name) => {
    if(date) {
      function getDateValue() {
        const day = ("0" + date.getDate()).slice(-2);
        const month = ("0" + (date.getMonth() + 1)).slice(-2);
        const year = date.getFullYear();
  
        return year + "-" + month + "-" + day;
      }
  
      const dateValue = getDateValue();
      let value;
  
      switch (name) {
        case "dateFrom":
          value = Date.parse(dateValue) / 1000;
          setSelectedDateFrom(date);
          break;
        case "dateTo":
          setSelectedDateTo(date);
          const end = new Date(dateValue);
          end.setUTCHours(23,59,59,999);
          value = Date.parse(end.toUTCString()) / 1000;
          break;
      
        default: break;
      }
  
      setDataSearch({...dataSearch, [name]: value});
    } else {
      switch (name) {
        case "dateFrom":
          setSelectedDateFrom(null);
          break;
        case "dateTo":
          setSelectedDateTo(null);
          break;

        default: break;
      }
      setDataSearch({ ...dataSearch, [name]: undefined })
    }
  }

  return (
    <Form onSubmit={(e) => onSearch(e, dataSearch)} className={`search-table ${className ? className : ''}`}>
      {renderSearchFrom()}
    </Form>
  );
};

export default SearchTable;