import * as Images from "../../components/images";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useHistory, useRouteMatch } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import authService from "../../services/auth.service";
import { useLocation } from "react-router-dom";
import { withTranslation } from "react-i18next";

function ResetPassword(props) {
  const history = useHistory();
  const { t, i18n } = props;
  let location = useLocation();
  let id = location.pathname.slice(16);

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required(t("reset_password.validate_send_email"))
        .min(3, t("reset_password.error_invalid_email")),
      confirmPassword: Yup.string()
        .required(t("reset_password.validate_send_password"))
        .min(3, t("reset_password.error_invalid_password")),
    }),
    validateOnChange: false,
    onSubmit: async (values) => {
      // window.alert('Form submitted');
      if (values.confirmPassword === values.password) {
        await authService.sendRestPassword(id, values.password);
        history.push("/");
        NotificationManager.success(t("reset_password.message_error_password"));
      } else {
        NotificationManager.error(t("reset_password.message_error_password"));

        // setMessageError(true);
      }
    },
  });

  const handleBackLogin = () => {
    history.push("/");
  };

  return (
    <section className="background w-100 dps-h-100 f-col">
      <form
        className="card f-column f-center"
        style={{ position: "unset" }}
        onSubmit={formik.handleSubmit}
      >
        <img src={Images.Logo} alt="spiral logo" className="logo" />
        {/* <p>{t("login.welcome")} <br/> {t("login.in_notice")}</p> */}

        <div>
          <label htmlFor="password">{t("reset_password.password")}</label>
          <input
            type="password"
            className="dps-input login-font-size-revert"
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <div className="message" style={{ marginBottom: 0, height: 15 }}>
            {formik.errors.password && formik.touched.password && (
              <p
                className="error"
                style={{ color: "red", marginBottom: 0, fontSize: 12 }}
              >
                {formik.errors.password}
              </p>
            )}
          </div>
        </div>

        <div>
          <label htmlFor="password">
            {t("reset_password.confirm_password")}
          </label>
          <input
            type="password"
            className="dps-input login-font-size-revert"
            name="confirmPassword"
            value={formik.values.confirmPassword}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />

          <div className="message" style={{ marginBottom: 0, height: 15 }}>
            {formik.errors.confirmPassword &&
              formik.touched.confirmPassword && (
                <p className="error" style={{ color: "red" }}>
                  {formik.errors.confirmPassword}
                </p>
              )}
          </div>
        </div>

        <button className="forgot-pass" type="button" onClick={handleBackLogin}>
          {t("reset_password.back_login")}
        </button>
        <button
          className="dps-button btn-reset-password"
          type="submit"
          // style={{
          //   color: "#fff",
          //   textTransform: "uppercase",
          //   fontSize: "13px",

          // }}
        >
          {t("reset_password.btn_reset_password")}
        </button>
      </form>
    </section>
  );
}

export default withTranslation()(ResetPassword);
