import axios from "axios";
import * as Constants from "../utils/constants";
import { doDelete } from "./api.service";

class AuthService {
  login(username, password) {
    return axios
      .post(Constants.LOGIN_URL, {
        email: username,
        password: password,
      })
      .then((response) => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    const currentUser = JSON.parse(localStorage.getItem("user"));
    const { refreshToken } = currentUser;
    const url = Constants.REVOKE_TOKEN_URL + refreshToken;
    localStorage.removeItem("user");
    // localStorage.removeItem("lang");
    doDelete(url, () => {});
  }

  sendEmail(email) {
    return axios
      .post(Constants.LOGIN_URL, {
        email,
      })
      .then((response) => {
        if (response.data.accessToken) {
          localStorage.setItem("email", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  register(username, email, password) {
    return axios.post(Constants.SIGNUP_URL + "signup", {
      username,
      email,
      password,
    });
  }
  sendEmailGetNewPassword(email) {
    return axios.put(Constants.SEND_EMAIL_GET_RESET_PASSWORD, {
      email,
    });
  }
  sendRestPassword(id, password) {
    return axios.put(Constants.SEND_RESET_PASSWORD, {
      key: id,
      password,
    });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem("user"));
  }
}

export default new AuthService();
