import React, { Component } from "react";
import { isTablet } from "react-device-detect";
import { withTranslation } from "react-i18next";

import Menu from "../components/menu";
import * as Images from "../components/images";
import ResultListComponent from "../components/result/resultList";
import ResultDetailComponent from "../components/result/resultDetail";
import HeaderMobile from "../components/HeaderMobile"

class InspectionResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceId: null,
      dataActive: null,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    for (const key in this.state) {
      if (this.state[key] !== nextState[key]) {
        return true;
      }
    }
    return false;
  }

  componentDidMount() {
    if (
      this.props.history.action === "PUSH" &&
      this.props.location.state?.data
    ) {
      const {data} = this.props.location.state;
      console.log(this.props.location.state);
      (async () => {
        this.setState({
          isViewDetail: true,
        });
        this.getInspectionDetail(data);
      })();
    }
  }

  handleShowMenu() {
    this.setState({ isShowMenu: !this.state.isShowMenu });
  }

  render() {
    const { isShowMenu } = this.state;
    return (
      <>
        <HeaderMobile />
        <div
          className={
            "dps-menu-hamburger-button " +
            (isTablet
              ? "dps-menu-hamburger-button-tablet " +
                (isShowMenu ? "" : "dps-menu-hamburger-button-not-show")
              : "")
          }
          onClick={() => this.handleShowMenu()}
        >
          <Images.HamburgerButton />
        </div>
        <div
          className={
            "dps-menu " +
            (isTablet
              ? "dps-menu-tablet " + (isShowMenu ? "" : "dps-menu-not-show")
              : "")
          }
        >
          <Menu currentRouter={this.props.location.pathname} />
        </div>
        {isShowMenu && isTablet && <div className="dps-menu-back-ground"></div>}
        <div
          className={"dps-content " + (isTablet ? "dps-content-tablet" : "")}
          style={this.state.isViewDetail ? { padding: 0 } : undefined}
        >
          <div className="dps-content-area" style={{overflow: 'hidden'}}>
            <div className="dps-main-content inspection-result">
              <div className="dps-main-content-area">
                <div className="inspection-status-area">
                  <div className="drone-selector">
                    {this.state.isViewDetail
                      ?
                      <ResultDetailComponent
                        droneId={this.state.deviceId}
                        dataActive={this.state.dataActive}
                        updateIsViewDetail={(status => this.setState({ isViewDetail: status }))}
                      />
                      : 
                      <ResultListComponent 
                        isViewDetail={this.state.isViewDetail}
                        getInspectionDetail={(data) => this.getInspectionDetail(data)}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  getInspectionDetail(device) {
    const { deviceId } = device;
    this.setState({
      isViewDetail: true,
      deviceId,
      dataActive: device
    });
  }
}
export default withTranslation()(InspectionResult);