import React, { Component } from "react";
import Menu from "../components/menu";
import HeaderMobile from "../components/HeaderMobile";
import * as Images from "../components/images";
import axios from "axios";
import * as Constants from "../utils/constants";
import { isTablet, isBrowser } from "react-device-detect";
import SockJsClient from "react-stomp";
import { withTranslation } from "react-i18next";
import { getHeader } from "../services/api.service";
import DeviceList from "../components/device/deviceList";
import { timeConverter } from "../utils/utils";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: JSON.parse(localStorage.getItem("user")),
      accountType: JSON.parse(localStorage.getItem("user")).accountType,
      loadingStatus: "loading",
      url: Constants.WS_URL,
      deviceStatusList: [],
      deviceActive: null,
      isShowMenu: false,
      previewSchedule: [],
      previewResult: [],  
      // drag to scroll preview drones
      isDragging: false,
      startX: null,
      scrollLeft: 0,
      cursorStyle: 'grab',
      // user action preview drone
      isCheckMove: false,
      isMove: false,
    };

    this.container = React.createRef();
    this.scrollRef = React.createRef();
    this.droneW = React.createRef();
    //binding

    this.fetchData = this.fetchData.bind(this);
    this.fetchData();
  }

  fetchData() {
    const promiseIcon = axios.get(
      Constants.MASTER_DATA_URL + "icon",
      getHeader()
    );
    const promiseStatusDevices = axios.get(
      Constants.DEVICE_STATUS_ALL_URL,
      getHeader()
    );
    const promisePreviewSchedule = axios.get(
      Constants.SCHEDULE_URL,
      getHeader()
    );
    const promisePreviewResult = axios.get(
      Constants.LIST_DEVICE_HISTORY_URL_SEARCH, { params: {
        pageSize: 3,
        pageNumber: 1,
        direction: 'desc',
        sort: 'inspectionNo',
      }
      }, getHeader()
    );
    Promise.all([
      promiseIcon,
      promiseStatusDevices,
      promisePreviewSchedule,
      promisePreviewResult,
    ]).then((responseArr) => {
      const schedules = this.convertSchedulesPreview(responseArr[2].data.takeoff_schedule)
      this.setState({
        deviceIcons: responseArr[0].data,
        deviceStatusList: responseArr[1].data,
        previewSchedule: schedules.slice(0, 3),
        previewResult: responseArr[3].data.items,
      });
      if (responseArr[1].data != null && responseArr[1].data.length > 0) {
        this.setState({ loadingStatus: "OK" });
      } else {
        this.setState({ loadingStatus: "NOK" });
      }
    });
  }

  convertSchedulesPreview(schedules) {
    const nowUtc = Math.floor(Date.now() / 1000);
    const tempSchedulesConvert = [];

    schedules.forEach(item => {
      if((+item.utc > nowUtc || item.h !== '*') && item.valid_flag) {
        tempSchedulesConvert.push(item)
      }
    })

    const schedulesConvert = tempSchedulesConvert.map(item => {
      if(item.h === '*') {
        return {
          ...item,
          tempNextUtc: +item.utc
        }
      }

      let tempNextUtc = this.getNextTimestamp(item.min, item.h, item.d, item.mon, this.convertDayOfWeek(item.dow), +item.utc);
      
      return {
        ...item,
        tempNextUtc
      }
    })

    schedulesConvert.sort((obj_1, obj_2) => {
      if(obj_1.tempNextUtc > obj_2.tempNextUtc) return 1;
      if(obj_1.tempNextUtc < obj_2.tempNextUtc) return -1;
      return 0;
    })

    return schedulesConvert;
  }

  convertDayOfWeek(dow) {
    if(dow === '*') return '*'

    return dow.split(',').map(item => {
      switch (item) {
        case 'Sun': return 0;
        case 'Mon': return 1;
        case 'Tue': return 2;
        case 'Wed': return 3;
        case 'Thu': return 4;
        case 'Fri': return 5;
        case 'Sat': return 6;
      
        default: return null
      }
    }).join(',');
  }

  getNextTimestamp(minute, hour, dayOfMonth, month, dayOfWeek, utcSchedule) {
    if(minute !== '*' && hour !== '*') {
      const now = new Date();
      let nextTimeStamp = new Date(now.getTime());

      nextTimeStamp.setMinutes(minute);
      nextTimeStamp.setHours(hour);

      if(dayOfMonth !== '*') {
        nextTimeStamp.setDate(parseInt(dayOfMonth));
        if(nextTimeStamp.getTime() < now.getTime()) {
          nextTimeStamp.setMonth(parseInt(now.getMonth() + 1))
        }
      }

      if(dayOfWeek !== '*') {
        const dows = dayOfWeek.split(',').map(item => +item);
        const nextDow = dows.find(item => item >= nextTimeStamp.getDay());
        if(nextDow) {
          nextTimeStamp.setDate(parseInt(nextTimeStamp.getDate() + nextDow - nextTimeStamp.getDay()));
        } else {
          nextTimeStamp.setDate(parseInt(nextTimeStamp.getDate() + 7 - nextTimeStamp.getDay() + dows[0]));
        }

        if(nextTimeStamp.getTime() < now.getTime()){
          let indexNextDow = dows.findIndex(item => item >= nextTimeStamp.getDay()) + 1;
          if(indexNextDow > dows.length - 1) {
            indexNextDow = 0
          }
          nextTimeStamp.setDate(parseInt(nextTimeStamp.getDate() + 7 - nextTimeStamp.getDay() + dows[indexNextDow]));
        }
      }

      if(nextTimeStamp.getTime() < now.getTime()) {
        nextTimeStamp.setDate(parseInt(now.getDate()) + 1)
      }

      nextTimeStamp.setSeconds(0)
      nextTimeStamp.setMilliseconds(0)

      let nextTimeStampSchedule = nextTimeStamp.getTime() / 1000;

      if(utcSchedule && utcSchedule > now.getTime() / 1000 && nextTimeStampSchedule > utcSchedule) {
        nextTimeStampSchedule = utcSchedule
      }

      return nextTimeStampSchedule;
    } 

    return utcSchedule
  }
  
  reLoadData () {
    axios.get(Constants.DEVICE_STATUS_ALL_URL, getHeader()).then((res) => {
      this.setState({
        deviceStatusList: res.data
      })
    })
  }

  handleShowMenu() {
    this.setState({ isShowMenu: !this.state.isShowMenu });
  }

  render() {
    const { t, i18n } = this.props;
    const { isShowMenu } = this.state;
    return (
      <>
        <HeaderMobile />
        <div
          className={
            "dps-menu-hamburger-button " +
            (isTablet
              ? "dps-menu-hamburger-button-tablet " +
                (isShowMenu ? "" : "dps-menu-hamburger-button-not-show")
              : "")
          }
          onClick={() => this.handleShowMenu()}
        >
          <Images.HamburgerButton />
        </div>
        <div
          className={
            "dps-menu " +
            (isTablet
              ? "dps-menu-tablet " + (isShowMenu ? "" : "dps-menu-not-show")
              : "")
          }
        >
          <Menu currentRouter={this.props.location.pathname} />
        </div>
        {isShowMenu && isTablet && <div className="dps-menu-back-ground"></div>}
        <div
          className={"dps-content drone-home " + (isTablet ? "dps-content-tablet" : "")}
        >
          <SockJsClient
            url={this.state.url}
            topics={[
              "/topic/drone/list/" +
                JSON.parse(localStorage.getItem("user")).id,
            ]}
            onMessage={(msg) => {
              if (msg === JSON.parse(localStorage.getItem("user")).id) {
                this.getDeviceList();
              }
            }}
            ref={(client) => {
              this.clientRef = client;
            }}
          />
          <div className="dps-content-area">
            <div className=" dps-main-content">
              <div className="dps-main-content-area drone-status">
                {this.state.loadingStatus === 'loading' ?
                  <div className="loading">
                    <img
                      alt="loading"
                      src={require("../assets/images/loading.gif")}
                    />
                  </div>
                  :
                  this.renderDashboard(t)
                }
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  renderDashboard(t) {
    return(
      <div className="drone-status-area">
        <div className="drone-introduce" style={{flexGrow: this.state.loadingStatus === 'NOK' && 'unset'}}>
          <b>MFA Tracker</b>
        </div>
        <div className="preview-group">
          <div className="preview-item preview-item-left">
            <div className="preview-header ">
              <h4 className="preview-title">
                {t("home.preview_schedule")}
              </h4>
            </div>
            <div className="preview-content">
              {this.renderpreviewSchedule(this.state.previewSchedule, t)}
            </div>
          </div>
          <div className="preview-item preview-item-right">
            <div className="preview-header">
              <h4 className="preview-title">
                {t("home.preview_result")}
              </h4>
            </div>
            <div className="preview-content">
              {this.renderpreviewResult(this.state.previewResult, t)}
            </div>
          </div>
        </div>
        {(isBrowser || isTablet) && 
          <div className="drone-preview" ref={this.container}>
            <h4>{t("home.drone_title")}</h4>
            {{
              NOK: (
                <h1 className="txt-red no-data" style={{paddingBottom: '20px'}}>
                  {this.state.accountType === "robot"
                    ? t("status.robot.message_1")
                    : t("status.drone.message_1")}
                </h1>
              ),
              OK:(
                <div 
                  className="drone-list" 
                  ref={this.scrollRef} 
                  style={{ cursor: this.state.cursorStyle }}
                  onMouseDown={this.handleMouseDown}
                  onMouseMove={this.handleMouseMove}
                  onMouseUp={this.handleMouseUp}
                >
                  {!(isBrowser || isTablet) ? 
                      <DeviceList 
                        data={this.state.deviceStatusList} 
                        reLoadData={this.reLoadData.bind(this)}
                        onDeviceSelect={this.onDeviceSelect.bind(this)}
                        deviceIcons={this.state.deviceIcons}
                        isMove={this.state.isMove}
                      />
                    :
                    <div className="scroll-x" ref={this.droneW}>
                      <DeviceList 
                        data={this.state.deviceStatusList} 
                        reLoadData={this.reLoadData.bind(this)}
                        onDeviceSelect={this.onDeviceSelect.bind(this)}
                        deviceIcons={this.state.deviceIcons}
                        isMove={this.state.isMove}
                      />
                    </div>
                  }
                </div>
              )
            }[this.state.loadingStatus]}
          </div>
        }
      </div>
    )
  }

  renderpreviewSchedule(data, t) {
    if (data.length === 0) {
      return (
        <table className="dps-table table-preview">
          <tr>
            <td style={{color: 'rgb(62,58,57)'}}>No Scheduled</td>
            <td></td>
          </tr>
        </table>
      );
    } else {
      return (
        <table className="dps-table table-preview">
          <tbody>
            {data.map((item, index) => {
              return (
                <tr key={index + 'schedule'} style={{cursor: 'pointer'}} onClick={this.changeRouteToSchedule.bind(this, item)}>
                  <td>{item.scheduleName || undefined}</td>
                  <td>{timeConverter(item.tempNextUtc, t)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    }
  }

  renderpreviewResult(data, t) {
    if (!data) {
      return (
        <table className="dps-table table-preview">
          <tr>
            <td style={{color: 'rgb(62,58,57)'}}>No result</td>
            <td></td>
          </tr>
        </table>
      );
    } else {
      return (
        <table className="dps-table table-preview">
          <tbody>
            {data.map((item, index) => {
              return (
                <tr key={index + 'result'} style={{cursor: 'pointer'}} onClick={this.changeRouteToResult.bind(this, item, index, true)}>
                  <td>
                    {item.deviceType} {item.deviceNo}
                  </td>
                  <td>
                    {timeConverter(item.inspectionNo, t)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    }
  }

  changeRouteToResult(item, index, isList) {
    this.props.history.push({ pathname: '/inspection-result', state: { data: item, itemActive: isList ? 0 : index }})
  }
  changeRouteToSchedule(item) {
    this.props.history.push({ pathname: '/schedule', state: { data: item }})
  }
  changeRouteToDeviceStation(deviceId, stationId, deviceActive) {
    this.props.history.push({ pathname: '/status', state: { deviceId, stationId, deviceActive }})
  }

  onDeviceSelect(e, droneId, stationId, index, deviceStatusList) {
    e.preventDefault();
    e.stopPropagation();
    this.changeRouteToDeviceStation(droneId, stationId, deviceStatusList[index])
  }

  // Drag to scroll preview drones
  handleMouseDown = (event) => {
    this.setState({ cursorStyle: 'grabbing' });
    event.preventDefault();

    this.setState({
      isDragging: true,
      startX: event.pageX - this.scrollRef.current.offsetLeft,
      isCheckMove: event.pageX - this.scrollRef.current.offsetLeft
    });
  };

  handleMouseMove = (event) => {
    if (!this.state.isDragging) {
      return;
    } 
    const droneWidthView = this.droneW.current.offsetWidth - this.container.current.offsetWidth;

    if(this.state.scrollLeft < 0) {
      this.setState({
        scrollLeft: 0,
      })
    } else if (this.state.scrollLeft > droneWidthView) {
      this.setState({
        scrollLeft: droneWidthView,
      })
    }

    const x = event.pageX - this.scrollRef.current.offsetLeft;
    const dx = x - this.state.startX;

    this.setState((prevState) => ({
      scrollLeft: prevState.scrollLeft - dx,
      startX: x,
    }));

    this.scrollRef.current.scrollLeft = this.state.scrollLeft;
  };

  handleMouseUp = () => {
    this.setState({ 
      isDragging: false ,
      cursorStyle: 'grab',
    });
    this.state.isCheckMove !== this.state.startX ? this.setState({ isMove: true }) : this.setState({ isMove: false })
  };
}
export default withTranslation()(Home);
