import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { DEFAULT_LANGUAGE } from "../utils/constants";

export default function LanguageSwitch(props) {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(localStorage.getItem('lang') || DEFAULT_LANGUAGE);

  useEffect(() => {
    var langLS = localStorage.getItem("lang");
    langLS = langLS != null ? langLS : DEFAULT_LANGUAGE;
    i18n.changeLanguage(langLS, () => setLanguage(langLS));
  }, [i18n]);

  function changeLang(e) {
    const { checked } = e.target;
    let lang = checked ? "ja" : "en";
    if (lang !== i18n.language) {
      i18n.changeLanguage(lang, () => {
        localStorage.setItem("lang", lang);
        setLanguage(lang);
      });
    }
  }

  return (
    <>
      <div className="d-flex align-content-center detail_setting_mode setting_mode_mobile">
        <p
          className="mr-3 mb-0 d-flex align-items-center"
          style={{
            marginRight: "5px",
          }}
        >
          {t("language")}
        </p>
        <label className="switch switch_language mb-0">
          <input
            type="checkbox"
            checked={language === "ja" ? true : false}
            onChange={changeLang}
          />
          <span className="slider round" style={{background: '#6E34AA'}}>
            <span
              className="lang"
              style={language === "en" ? { opacity: 0 } : null}
            >
              JP
            </span>
            <span
              className="lang"
              style={language === "ja" ? { opacity: 0 } : undefined}
            >
              EN
            </span>
          </span>
        </label>
      </div>
    </>
  );
}
