import React, {useEffect, useRef, useState} from "react";
import * as Images from "../images";
import MarkerEditPlan from "../plan/makerEditPlan";

/*
Animation start from first marker, drone location will be calculated by map direction, first marker plan direction
First marker plan direction accepts only right/left
Default distance between drone location and first marker is about DEFAULT_DISTANCE_TO_MARKER
Animation will play scripts from planContent
 */
const MapAnimation = (props) => {
  const {zoom, planContent, direction} = props

  const [animationName, setAnimationName] = useState('');
  const [markers, setMarkers] = useState(props.markers);
  const [lines, setLines] = useState([]);
  const [dronePosition, setDronePosition] = useState([]);

  const realMapDirection = useRef(null);
  const latestWallDistance = useRef(null);
  const latestMoveDirection = useRef(null);

  let style = {
    animationName: animationName,
    animationTimingFunction: 'ease-in-out',
    animationDuration: markers.length - 1 + 's',
    animationDelay: '0s',
    animationIterationCount: 1,
    animationDirection: 'normal',
    animationFillMode: 'forwards',
    position: 'absolute',
    display: 'none'
  };

  useEffect(() => {
    setMarkers(props.markers)
  }, [props]);
  
  useEffect(() => {
    if (markers && markers.length >= 1) {
      const firstStep = planContent[0];
      // Ex: realMapDirection.current is up, lastestMoveDirection is right.
      // It means: In map, up direction is right move.
      // I changed was just distance from wall. wall position is equal to marker position.
      // So if user try to change distance from wall , marker position should not be changed.  Only flight position(route) is changed.
      realMapDirection.current = direction;
      latestMoveDirection.current = firstStep.direction;
      latestWallDistance.current = firstStep.wall_distance;
      const redLines = [];
      const dronePosition = [];
      let linePointPosition = {};
  
      if (markers.length >= 2) {
        for (let index = 0; index < planContent.length; index++) {
          const planAtMarker = planContent[index];
          const {wall_distance, direction} = planAtMarker;
          // const rotateAngle = Number(planAtMarker.action.rotate);
          // ADDNEW
          const rotateAngle = Number(planAtMarker.angle);
          if (['right', 'left', 'landed'].includes(direction)) {
            const markerPosition = markers[index];
            let point = {
              left: markerPosition.left * zoom,
              top: markerPosition.top * zoom
            };
            point = moveDrone(point, latestMoveDirection.current, wall_distance);
            linePointPosition = {
              left: point.left,
              top: point.top
            };
  
            redLines.push({x: linePointPosition.left, y: linePointPosition.top});
            dronePosition.push(point);
            if ('landed' === direction) {
              break;
            }
          } else if (direction === 'turn') {
            realMapDirection.current = rotateDirection(direction, rotateAngle, realMapDirection.current);
          }
        }
  
        setLines(redLines)
        setDronePosition(dronePosition)
      }
  
    }
  }, [markers])

  useEffect(() => {
    moveDroneByPosition(dronePosition)
  }, [dronePosition])

  const moveDroneByPosition = (positions) => {
    let styleSheet = document.styleSheets[0];
    let aniName = `animation${Math.round(Math.random() * 100)}`;
    const moveStep = 100 / (positions.length - 1);
    let keyframeTransforms = '';
    positions.forEach((position, index) => {
      keyframeTransforms += `${moveStep
      * index}% {-webkit-transform:translate(${position.left + 7.5}px, ${position.top + 7.5}px)} `
    })
    let keyframes = `@-webkit-keyframes ${aniName} {${keyframeTransforms}}`;
    styleSheet.insertRule(keyframes, styleSheet.cssRules.length);
    styleSheet.insertRule('.drone-icon-in-map { display: block !important }',
        styleSheet.cssRules.length);
    setAnimationName(aniName)
  };

  const rotateDirection = (rotate, angle, currentDirection) => {
    if (!rotate || rotate !== 'turn') {
      console.log("Rotate value is invalid!");
      return currentDirection;
    }
    const arr = (angle === 90) ? ['up', 'right', 'down', 'left']
        : ['up', 'left', 'down', 'right'];
    // if (angle === 90) {
    //   return 'down';
    // } else if (angle === -90) {
    //   return 'up';
    // }
    const indexOfCurrentDirection = arr.findIndex(
        (item) => item === currentDirection);
    return (indexOfCurrentDirection === 3) ? arr[0]
        : arr[indexOfCurrentDirection + 1];
  };

  const moveDrone = (startingPoint, firstStepDirection, firstSetWallDistance) => {
    const wallDistanceInMap = getDistanceInMap(firstSetWallDistance);
    // eslint-disable-next-line default-case
    switch (realMapDirection.current) {
      case 'up':
        startingPoint.left = startingPoint.left + (firstStepDirection === 'right' ? 1 : -1) * wallDistanceInMap;
        break;
      case 'down':
        startingPoint.left = startingPoint.left + (firstStepDirection === 'right' ? -1 : 1) * wallDistanceInMap;
        break;
      case 'right':
        startingPoint.top = startingPoint.top + (firstStepDirection === 'right' ? 1 : -1) * wallDistanceInMap;
        break;
      case 'left':
        startingPoint.top = startingPoint.top + (firstStepDirection === 'right' ? -1 : 1) * wallDistanceInMap;
        break;
    }
    return startingPoint;
  };

  const getDistanceInMap = (distance) => {
    const {startPoint, endPoint, value} = props.scale;
    const distanceOnMap = Math.sqrt(
      Math.pow(startPoint.top - endPoint.top, 2) + Math.pow(startPoint.left - endPoint.left, 2)
    );
    return distanceOnMap * (distance / value);
  };

  const renderLine = () => {
    return(
      lines.map((item, index, arr) => {
        if(index >= arr.length - 1) return null 
        return(
          <svg key={index} className="w-100 h-100 position-absolute">
            <line 
              x1={item.x + 7.5} y1={item.y + 7.5} 
              x2={arr[index + 1].x + 7.5} y2={arr[index + 1].y + 7.5} 
              style={{
                stroke: 'rgb(255, 0, 0)',
                strokeWidth: '1',
              }}
            />
          </svg>
        )
      })
    )
  }

  if(markers && markers.length > 0) {
    return (
      <div className="position-absolute top-0" style={{width: `${props.style.width}px`, height: `${props.style.height}px`}}>
        <canvas className=" bg-white opacity-50  border border-dark w-100 h-100 position-absolute overflow-hidden"/>
        {
          markers.map((item, index) => {
            return(
              <MarkerEditPlan key={index} styleTransform={true} {...item} zoom={zoom} />
            )
          })
        }
        {renderLine()}
        <div className="drone-icon-in-map" style={style}>
          <span className="intro-banner-vdo-play-btn">
          </span>
        </div>
      </div>
    );
  } else return null
};

export default MapAnimation;