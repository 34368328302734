import React from "react";
import validator from 'validator';
import i18n from "i18next";

export const required = (value, item) => {
  if(item.typeCheck){
    if ((!value || !value.toString().trim().length) && item.isCheckAdd) {
      // We can return string or jsx as the 'error' prop for the validated Component
      if (item?.message) {
        return <small className="input-error"> {item?.message}</small>;
      } else {
        return <small className="input-error">{item.type === "password" ? i18n.t('login.password_message') : i18n.t('login.email_message')}</small>;
      }
    }
  } else{
    if (!value || !value.toString().trim().length) {
      // We can return string or jsx as the 'error' prop for the validated Component
      if (item?.message) {
        return <small className="input-error"> {item?.message}</small>;
      } else {
        return <small className="input-error">{item.type === "password" ? i18n.t('login.password_message') : i18n.t('login.email_message')}</small>;
      }
    }
  }
};

export const email = (value, item) => {
  if(item.typeCheck){
    if (!validator.isEmail(value) && item.isCheckAdd) {
      return <small className="input-error">{value} {i18n.t('login.validation_email')}</small>
    }
  } else{
    if (!validator.isEmail(value)) {
      return <small className="input-error">{value} {i18n.t('login.validation_email')}</small>
    }
  }
};

export const lt = (value, props) => {
  // get the maxLength from component's props
  if (!value.toString().trim().length > props.maxLength) {
    // Return jsx
    return <small className="input-error">The value
      exceeded {props.maxLength} symbols</small>
  }
};

export const number = (value, item) => {
  if(item.typeCheck){
    if (!/^-?\d+$/.test(value) && item.isCheckAdd) {
      return <small className="input-error">{i18n.t('common.validate_num')}</small>
    }
  } else {
    if (!/^-?\d+$/.test(value)) {
      return <small className="input-error">{i18n.t('common.validate_num')}</small>
    }
  }
}

export const range = (value, props) => {
  const lang = localStorage.getItem('lang');
  if(props.typeCheck) {
    if ((parseInt(value) < props.min || parseInt(value) > props.max) && props.isCheckAdd) {
      return (
        <small className="input-error">
          {lang === "en" ?
            i18n.t('common.validate_ranger') + " " + props.min + '-' + props.max
            :
            props.min + '-' + props.max + i18n.t('common.validate_ranger')
          }
        </small>
      )
    }
  } else {
    if (parseInt(value) < props.min || parseInt(value) > props.max) {
      return (
        <small className="input-error">
          {lang === "en" ?
            i18n.t('common.validate_ranger') + " " + props.min + '-' + props.max
            :
            props.min + '-' + props.max + i18n.t('common.validate_ranger')
          }
        </small>
      )
    }
  }
}