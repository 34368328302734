import { memo, useEffect, useLayoutEffect, useRef, useState } from "react";
import { t } from "i18next";
import axios from "axios";
import { isMobileOnly } from "react-device-detect";
import { Modal } from "react-bootstrap";

import SearchTable from "../searchTable";
import { getHeader } from "../../services/api.service";
import * as Constants from "../../utils/constants"
import * as Images from "../images";
import PaginationComponent from "../pagination";
import { timeConverter } from "../../utils/utils";

const initFormSearch =  {
  direction: 'desc',
  sort: 'inspectionNo',
  deviceNo: null,
  planName: null,
  planNo: null,
  dateFrom: null, 
  dateTo: null,
};

const tableHeader = [
  "result.table.drone",
  "result.table.flight_plan",
  "result.table.ID",
  "result.table.number_of_flights",
  "result.table.last_flight_date_time",
];

const ResultListComponent = (props) => {
  const { 
    isViewDetail,
    getInspectionDetail
  } = props;

  const [deviceList, setDeviceList] = useState(null);
  const [resultTotal, setResultTotal] = useState(null);
  const [loadingStatus, setLoadingStatus] = useState("LOADING"); // LOADING, OK, NOK
  const [uniqDeviceNo, setUniqDeviceNo] = useState(null);
  const [uniqPlanNo, setUniqPlanNo] = useState(null);
  const [formSearch, setFormSearch] = useState(initFormSearch);
  const [pageSize, setPageSize] = useState(20);
  const [pageNumber, setPageNumber] = useState(1);

  const [isShowSearchMobile, setIsShowSearchMobile] = useState(false);
  const [isSort, setIsSort] = useState(false);
  const [sortName, setSortName] = useState(false);
  const [heightBrowser, setHeightBrowser] = useState(0);

  const refHeaderTable = useRef(null);
  const refBodyTable = useRef(null);

  useEffect(() => {
    getUniqData();
    getdeviceList();
  }, [])

  useEffect(() => {
    getdeviceList();
  }, [pageSize, pageNumber, formSearch])

  useEffect(() => {
    resizeTable();
  }, [useWindowSize()])

  function useWindowSize() {
    useLayoutEffect(() => {
      function updateSize() {
        setHeightBrowser(window.innerHeight);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);

    return heightBrowser;
  }

  const getdeviceList = () => {
    const params = {
      ...formSearch,
      pageSize: pageSize,
      pageNumber: pageNumber,
    }

    axios.get(Constants.LIST_DEVICE_HISTORY_URL_SEARCH, {params}, getHeader()).then((res) => {
      if (res.data.items != null && res.data.items.length > 0) {
        setDeviceList(res.data.items);
        setResultTotal(res.data.totalItems);
        setLoadingStatus("OK");
      } else {
        setLoadingStatus("NOK");
      }
    });
  }

  const getUniqData = () => {
    axios.get(Constants.LIST_DEVICE_HISTORY_URL, getHeader()).then(res => {
      if(res.data) {
        const dataConvert = res.data.map(item => {
          return {
            deviceNo: item.deviceNo,
            history: item.history.map(it => it.planNo)
          }
        })
        const uniqDeviceNoConvert = dataConvert.map(item => item.deviceNo);
        const uniqPlanNoConvert = [...new Set(dataConvert.flatMap(item => item.history))].sort();

        setUniqDeviceNo(uniqDeviceNoConvert);
        setUniqPlanNo(uniqPlanNoConvert);
      }
    })
  }

  const resizeTable = () => {
    const scrollHeight = refBodyTable.current?.scrollHeight;
    const clientHeight = refBodyTable.current?.clientHeight;
    const headerTable = refHeaderTable.current;
    if(headerTable) {
      if(scrollHeight > clientHeight) {
        headerTable.style.paddingRight = '16px';
      } else {
        headerTable.style.paddingRight = 0;
      }
    }
  }

  const handleChangePageSize = (e) => {
    setPageSize(e.target.value);
    setPageNumber(1);
  }

  const handleChangePagination = (curentPage) => {
    setPageNumber(curentPage);
  }

  const handleSearch = (event, dataSearch) => {
    event.preventDefault();
    
    setFormSearch({ ...formSearch, pageNumber: 1, ...dataSearch });
    setPageNumber(1);
    setIsShowSearchMobile(false);
  }

  const sort = (item) => {
    let sortNameNew, directionNew, isSortNew = false;
    if(item.includes("flight_plan")) {
      sortNameNew = "planName"
    } else if(item.includes("last_flight_date_time")) {
      sortNameNew = "inspectionNo"
    }

    if(sortName === item) {
      isSortNew = !isSort
    } else {
      isSortNew = true
    }

    directionNew = isSortNew ? 'asc' : 'desc'

    setFormSearch({...formSearch, sort: sortNameNew, direction: directionNew});
    setIsSort(isSortNew);
    setSortName(item);
  }

  const renderSearchTable = () => {
    if (uniqDeviceNo && uniqPlanNo) {
      return (
        <SearchTable
          data={[
            {
              name: 'deviceNo',
              label: t("Search_Pagination.device_no"),
              typeSearch: 'select',
              subData: uniqDeviceNo
            },
            {
              name: 'planName',
              label: t("Search_Pagination.flight_plan"),
              typeSearch: 'text',
            },
            {
              name: 'planNo',
              label: t("Search_Pagination.plan_no"),
              typeSearch: 'select',
              subData: uniqPlanNo
            },
            {
              name: 'dateFrom dateTo',
              label: t("Search_Pagination.last_flight_date"),
              typeSearch: 'date',
            },
          ]}
          formSearch={formSearch}
          onSearch={handleSearch}
          className={isViewDetail ? 'hidden' : undefined}
        />
      )
    }
  }

  if (loadingStatus === "LOADING") {
    return (
      <div className="loading">
        <img src={require("../../assets/images/loading.gif")} />
      </div>
    )
  }

  return (
    <>
      {isMobileOnly ? 
        <div className="header-result-mb">
          <button onClick={() => setIsShowSearchMobile(true)}>{t('Search_Pagination.search')}</button>

          <Modal
            show={isShowSearchMobile}
            centered
            className="form_search-mobile"
            size="lg"
            onHide={() => setIsShowSearchMobile(false)}
          >
            <Modal.Body>
              {renderSearchTable(t)}
            </Modal.Body>
          </Modal>
        </div>
        :
        renderSearchTable()
      }
      {loadingStatus === "NOK" ?
        <h1 className="txt-red">{t("result.no_data_result")}</h1>
        :
        <>
          {isMobileOnly ?
            <div className="result-list-mb">
              {deviceList.map(device => (
                <div 
                  className="item" 
                  key={device.inspectionNo} 
                  onClick={() => getInspectionDetail(device)}
                >
                  <p className="drone-type">
                    <Images.Icons name="IconPlane" color="#000" />
                    {device.deviceType} {device.deviceNo}
                  </p>

                  <p className="plan-name">
                    <Images.Icons name="IconScan" color="#000" />
                    {device.planName}
                  </p>

                  <span className="time">{timeConverter(device.inspectionNo, t)}</span>
                </div>
              ))}
            </div>
            :
            <div className="wrap_table">
              <div className="dps-list-f">
                <div className="header" ref={refHeaderTable}>
                  {tableHeader.map((item, index) => {
                    if (item.includes("flight_plan") || item.includes("last_flight_date_time")) {
                      return (
                        <div
                          className="list_sort clmn"
                          onClick={() => sort(item)}
                          key={index}
                          style={{
                            cursor: "pointer",
                            userSelect: "none",
                            color: "#473DAF",
                          }}
                        >
                          <div className="l-sort">
                            {t(item)}
                            <span
                              style={{
                                transform: `${isSort && sortName === item ? "rotate(180deg)" : ""}`,
                              }}
                            >
                              <Images.IconSort color="currentColor" />
                            </span>
                          </div>
                        </div>
                      );
                    }
                    return <div className="clmn" key={index}>{t(item)}</div>;
                  })}
                </div>
                <div className="scroll-bar body" ref={refBodyTable}>
                  {deviceList.map((device, index) => {
                    return (
                      <div className="list-item"
                        key={index}
                        onClick={() => getInspectionDetail(device)}
                      >
                        <div className="clmn">
                          <Images.Icons name="IconPlane" color="#000" />
                          {device.deviceType} {device.deviceNo}
                        </div>
                        <div className="clmn">
                          <Images.Icons name="IconScan" color="#000" />
                          {device.planName}
                        </div>
                        <div className="clmn">{device.planNo}</div>
                        <div className="clmn">
                          <span>{device.quantity}</span>
                        </div>
                        <div className="clmn">{timeConverter(device.inspectionNo, t)}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          }
          
          <div className="footer-page">
            {!isMobileOnly && 
              <p className="total-list">{resultTotal} {t('result.total')}</p>
            }
            <PaginationComponent
              total={resultTotal} 
              pageSize={pageSize} 
              pageNumber={pageNumber}
              handleChangePageSize={handleChangePageSize}
              handleChangePagination={handleChangePagination} 
            />
          </div>
        </>
      }
    </>
  )
}

export default memo(ResultListComponent);