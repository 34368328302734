import React from "react";
import * as Images from "./images";
import * as Constants from "../utils/constants";
import PreviewGallery from "./result/previewGallery";
import { getHeader } from "../services/api.service";
import axios from "axios";

export default class MarkerDisplay extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      accountType: JSON.parse(localStorage.getItem('user')).accountType,
    }
  }

  displayMarker(item, nextMarker) {
    const markerPreview = this.props.markerPreview
    const firstFlightLength = this.props.firstFlightLength
    let markerPass = 1, markerFuture = []
    markerPreview.forEach(item => {
      markerPass += item.trackingPassItem ? 1 : 0
    })
    
    markerPreview.slice(markerPass).forEach(item => item.id !== -1 ? markerFuture.push(item.id) : null)

    if (item.isTakeoff) {
      return (
        <div className="device_tracking-marker takeoff" key={item.id}>
          <Images.IconFlight color="currentColor"/>
          <span><Images.IconArrow color='currentColor'/></span>
        </div>
      )
    } else if(item.isLanding && item.id === nextMarker) {
      return(
        <div className="device_tracking-marker landing" key={item.id}>
          <Images.IconFlight color="currentColor"/>
          <span><Images.IconArrow color='currentColor'/></span>
          <div className="flight-next">
            <Images.IconMarker color="currentColor" textColor='#fcd13e' marker='L'/>
          </div>
        </div>
      )
    } else if (item.isLanding) {
      return (
        <div className="device_tracking-marker landing" key={item.id}>
          <Images.IconFlight color="currentColor"/>
          <span><Images.IconArrow color='currentColor'/></span>
        </div>
      )
    } else if(markerPass < markerPreview.length - 1 && markerFuture.includes(item.id) && markerPass > firstFlightLength - 1) {
      return (
        <div className="device_tracking-marker pending" key={item.id}>
          <Images.IconFlight color="#606060"/>
          <div
            onClick={this.handleOpen.bind(this, item)}
            className={item.content?.url ? 'capture' : 'hidden'}>
            <Images.IconCapture color='currentColor' />
          </div>
          <div className={`flight-next ${nextMarker === item.id ? '' : 'hidden'}`}>
            <Images.IconMarker color="#606060" textColor='#fcd13e'/>
            <span>{item.id}</span>
          </div>
        </div>
      )
    } else if(markerPass < markerPreview.length - 1 && !markerFuture.includes(item.id) && markerPass > firstFlightLength - 1) {
      return (
        <div className="device_tracking-marker pending" key={item.id}>
          <Images.IconFlight color="#000"/>
          <div
            onClick={this.handleOpen.bind(this, item)}
            className={item.content?.url ? 'capture' : 'hidden'}>
            <Images.IconCapture color='currentColor' />
          </div>
          <div className="flight-next hidden">
            <Images.IconMarker color="#000" textColor='#fcd13e'/>
            <span>{item.id}</span>
          </div>
        </div>
      )
    } else if (item.trackingPassItem === true) {
      return (
        <div className="device_tracking-marker pass" key={item.id}>
          <Images.IconFlight color="currentColor"/>
          <div
            onClick={this.handleOpen.bind(this, item)} 
            className={item.content.url ? 'capture' : 'hidden'}>
            <Images.IconCapture color='currentColor' />
          </div>
          <div className="flight-next hidden">
            <Images.IconMarker color="currentColor" textColor='#3e3a39'/>
            <span>{item.id}</span>
          </div>
        </div>
      )
    } else if(item.id === nextMarker) {
      return (
        <div className="device_tracking-marker pending" key={item.id}>
          <Images.IconFlight color="currentColor"/>
          <div className="flight-next">
            <Images.IconMarker color="currentColor" textColor='#fcd13e'/>
            <span>{item.id}</span>
          </div>
        </div>
      )
    } else {
      return (
        <div className="device_tracking-marker pending" key={item.id}>
          <Images.IconFlight color="currentColor"/>
          <div
            onClick={this.handleOpen.bind(this, item)}
            className={item.content?.url ? 'capture' : 'hidden'}>
            <Images.IconCapture color='currentColor' />
          </div>
          <div className="flight-next hidden">
            <Images.IconMarker color="currentColor" textColor='#fcd13e'/>
            <span>{item.id}</span>
          </div>
        </div>
      )
    }

  }

  handleOpen(item) {
    const { marker_id, utc } = item.content
    this.setState({
      mediaList: item.content.url,
      mediaTime: utc,
      isEdit: true,
      mediaPreview: [],
      currentMarkerPreview: marker_id
    })

    axios.all(item.content.url.trim().split(' ').map(item => axios.get(Constants.MEDIA_URL + item, getHeader())))
      .then(axios.spread((...response) => {
        this.setState({
          mediaPreview: response.map(res => res.data)
        })
      }));
  }

  handleClose() {
    this.setState({
      isEdit: false,
      mediaList: null,
      mediaTime: null,
      mediaPreview: [],
    });
  }

  render() {
    return (
      <>
        {this.displayMarker(this.props.data ? this.props.data : this.props, this.props.nextMarker)}
        <PreviewGallery
          mediaList={this.state.mediaList}
          mediaTime={this.state.mediaTime}
          mediaPreview={this.state.mediaPreview}
          isOpen={this.state.isEdit}
          handleClose={this.handleClose.bind(this)}
          currentMarker={this.state.currentMarkerPreview}
        />
      </>
    );
  }

}


