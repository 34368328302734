import React from "react";
import * as Constants from "../../utils/constants";
import MarkerDisplay from "../markerDisplay";
import * as Images from "../images";

const imageStyle = {
  objectFit: 'contain',
  maxWidth: '100%',
  maxHeight: '100%',
  borderRadius: '10px',
  margin: '0 auto',
  display: 'block',

};

const containerAreaStyle = {
  display: 'block',
  width: '100%',
  height: '100%',
  flexShrink: 0,
  flexGrow: 1,
};

const containerStyle = {
  position: 'relative',
  display: 'block',
  width: '100%',
  height: '100%',
  margin: '0 auto',
};
export default class MapPreviewStatusFC extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      accountType: JSON.parse(localStorage.getItem('user')).accountType,
      loading: false,
    };

    this.imagePreview = React.createRef();
    this.imageArea = React.createRef();
    this.imageContainer = React.createRef();

    imageStyle.width = '';
    imageStyle.height = '';
    this.imageLoaded.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.displayMode !== 'result') {
      this.setState({
        markers: this.props.markers
      });
    }

    if (this.props.url !== prevProps.url) {
      imageStyle.width = '';
      imageStyle.height = '';
      containerStyle.width = '';
      containerStyle.height = '';
      this.setState({
        loading: false,
        zoom: 0
      })
    }

    if (this.state.loading !== prevState.loading && this.state.loading) {
      this.imageLoaded();
    }
  }

  imageLoaded() {
    let elem = this.imagePreview.current;
    let containerRef = this.imageArea.current;
    let imageContainerRef = this.imageContainer.current;
    const zoomX = imageContainerRef.clientWidth / elem.clientWidth;
    const zoomY = imageContainerRef.clientHeight / elem.clientHeight;
    let zoom = Math.min(zoomX, zoomY);

    containerRef.style.width = elem.clientWidth * zoom + 'px';
    containerRef.style.height = elem.clientHeight * zoom + 'px';
    containerRef.style.display = "block"

    imageStyle.height = elem.clientHeight * zoom;
    imageStyle.width = elem.clientWidth * zoom;

    zoom = imageStyle.height / elem.naturalHeight;

    this.setState({
      zoom: zoom,
    });
  }

  renderIndicator() {
    let currentMarker = this.props.currentMarker;
    let start = this.props.markers.find(x => x.id == currentMarker)
    if (currentMarker === -1 && this.state.accountType !== 'robot') {
      start = this.props.markers.find((item) => {
        return item.id === -1;
      });
    }
    let end = this.props.markers.find(x => x.id == this.props.endMarker)

    if (!end && this.state.accountType !== 'robot') {
      end = this.props.markers.find((item) => {
        return item.id === -1;
      });
    }
    let left = 0;
    let top = 0;
    let deg = 0;

    if (start) {
      if (start.status === "marker_left" || start.status === "takeoff") {
        if (start && end) {
          left = (start.left + end.left) / 2;
          top = (start.top + end.top) / 2;

          if (this.props.currentTime <= 0.001) {
            left = start.left;
            top = start.top;
          }
        } else {
          left = start.left;
          top = start.top;
        }
      } else {
        if (this.state.accountType === 'robot' && end && this.props.currentTime
            > 0.001) {
          left = (start.left + end.left) / 2;
          top = (start.top + end.top) / 2;
        } else {
          left = start.left;
          top = start.top;
        }

      }
    }
    if(start && end) {
      const tempMarker = {
        top: start.top,
        left: end.left
      }

      const widthStartToEnd = Math.sqrt(Math.pow(end.top - start.top, 2) + Math.pow(end.left - start.left, 2));
      const widthEndToTemp = Math.sqrt(Math.pow(tempMarker.top - end.top, 2) + Math.pow(tempMarker.left - end.left, 2));

      if(start.top >= end.top && start.left <= end.left){
        deg = (Math.asin(widthEndToTemp / widthStartToEnd) * 180) / Math.PI * -1;
      } else if (start.top <= end.top && start.left <= end.left) {
        deg = (Math.asin(widthEndToTemp / widthStartToEnd) * 180) / Math.PI;
      } else if (start.top <= end.top && start.left >= end.left) {
        deg = 180 - ((Math.asin(widthEndToTemp / widthStartToEnd) * 180) / Math.PI);
      } else if (start.top >= end.top && start.left >= end.left) {
        deg = (Math.asin(widthEndToTemp / widthStartToEnd) * 180) / Math.PI + 180;
      }
    }

    return (
      <div
        style={{
          position: 'absolute',
          width: '24px',
          height: '24px',
          transform: 'translate3d(' + left * this.state.zoom + 'px, ' + top
              * this.state.zoom + 'px, 0)',
          WebkitTransform: 'translate3d(' + left * this.state.zoom + 'px, '
              + top * this.state.zoom + 'px, 0)',
        }}
        className={start ? 'marker-pink-pulse-mobile' : 'hidden'}
      >

        <span href="#" className="intro-banner-vdo-play-btn" target="_blank">
          {deg ? <Images.IconDirection style={{transform: 'rotate(' + deg + 'deg)'}}/> : null}
          <span className="ripple pinkBg"></span>
          <span className="ripple pinkBg"></span>
        </span>
      </div>
    )
  }

  imageLoad() {
    this.setState({loading: true})
  }

  render() {
    let imageSource = Constants.getResourceUrl() + this.props.url;
    const {t} = this.props;
    return (
        <div className="map-image-preview" style={{...containerAreaStyle}}
             ref={this.imageContainer}>
          <div style={{...containerStyle}} className="image-map-view"
               ref={this.imageArea}>
            <img alt="mapimage" className="image-map-image" src={imageSource}
                 style={{...imageStyle}} onLoad={this.imageLoad.bind(this)}
                 ref={this.imagePreview}/>
            {
              this.state.loading && this.props.markers ?
                  this.props.markers.map((marker, index) => {
                    return (
                        <div className="marker-item"
                             style={{
                               position: 'absolute',
                               top: '0',
                               left: '0',
                               transform: 'translate3d(' + marker.left
                                   * this.state.zoom + 'px, ' + marker.top
                                   * this.state.zoom + 'px, 0)',
                               WebkitTransform: 'translate3d(' + marker.left
                                   * this.state.zoom + 'px, ' + marker.top
                                   * this.state.zoom + 'px, 0)',
                             }}
                             key={index}>
                          <MarkerDisplay key={index} {...marker} nextMarker={this.props.endMarker} markerPreview={this.props.markerPreview} firstFlightLength={this.props.markers.length - 1}/>
                        </div>
                    );
                  }) : ''
            }

            {
              this.state.loading && this.props.markers ? this.renderIndicator() : ''
            }
          </div>
        </div>
    );
  }

}
