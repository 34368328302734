import React from "react";
import * as Constants from "../../utils/constants";
import MarkerDisplay from "../markerDisplay";
import * as Images from "../images";
import { isMobile } from "react-device-detect";

const imageStyle = {};

export default class MapPreviewStatus extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      accountType: JSON.parse(localStorage.getItem("user")).accountType,
      loading: false,
    };

    this.imagePreview = React.createRef();
    this.imageArea = React.createRef();
    this.imageContainer = React.createRef();

    imageStyle.width = "";
    imageStyle.height = "";
    this.imageLoaded.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.displayMode !== "result") {
      this.setState({
        markers: this.props.markers,
      });
    }

    if (this.props.url !== prevProps.url) {
      imageStyle.width = "";
      imageStyle.height = "";
      this.setState({
        loading: false,
        zoom: 0,
      });
    }

    if(
      (this.state.loading !== prevState.loading && this.state.loading) ||
      prevProps.sizeBrowser !== this.props.sizeBrowser || 
      prevProps.sizeMain !== this.props.sizeMain ||
      prevProps.elementMainView !== this.props.elementMainView ||
      prevProps.isFullScreenMode !== this.props.isFullScreenMode
    ) {
      this.props.url && this.imageLoaded();
    }
  }

  imageLoaded() {
    let elem = this.imagePreview.current;
    let imageContainerRef = this.imageContainer.current;

    if (elem && imageContainerRef) {
      const zoomX = imageContainerRef.clientWidth / elem.clientWidth;
      const zoomY = imageContainerRef.clientHeight / elem.clientHeight;
      let zoom = Math.min(zoomX, zoomY);
  
      imageStyle.width = elem.clientWidth * zoom;
      imageStyle.height = elem.clientHeight * zoom;
  
      zoom = imageStyle.height / elem.naturalHeight;
  
      this.setState({
        zoom: zoom,
      });
    }
  }

  renderIndicator() {
    let currentMarker = this.props.currentMarker;
    let start = this.props.markers.find((x) => x.id == currentMarker);
    if (currentMarker === -1 && this.state.accountType !== "robot") {
      start = this.props.markers.find((item) => {
        return item.id === -1;
      });
    }
    let end = this.props.markers.find((x) => x.id == this.props.endMarker);

    if (!end && this.state.accountType !== "robot") {
      end = this.props.markers.find((item) => {
        return item.id === -1;
      });
    }
    let left = 0;
    let top = 0;
    let deg = 0;
    if (start) {
      if (start.status === "marker_left" || start.status === "takeoff") {
        if (start && end) {
          left = (start.left + end.left) / 2;
          top = (start.top + end.top) / 2;

          if (this.props.currentTime <= 0.001) {
            left = start.left;
            top = start.top;
          }
        } else {
          left = start.left;
          top = start.top;
        }
      } else {
        if (
          this.state.accountType === "robot" &&
          end &&
          this.props.currentTime > 0.001
        ) {
          left = (start.left + end.left) / 2;
          top = (start.top + end.top) / 2;
        } else {
          left = start.left;
          top = start.top;
        }
      }
    }

    if (start && end) {
      const tempMarker = {
        top: start.top,
        left: end.left,
      };

      const widthStartToEnd = Math.sqrt(
        Math.pow(end.top - start.top, 2) + Math.pow(end.left - start.left, 2)
      );
      const widthEndToTemp = Math.sqrt(
        Math.pow(tempMarker.top - end.top, 2) +
          Math.pow(tempMarker.left - end.left, 2)
      );

      if (start.top >= end.top && start.left <= end.left) {
        deg =
          ((Math.asin(widthEndToTemp / widthStartToEnd) * 180) / Math.PI) * -1;
      } else if (start.top <= end.top && start.left <= end.left) {
        deg = (Math.asin(widthEndToTemp / widthStartToEnd) * 180) / Math.PI;
      } else if (start.top <= end.top && start.left >= end.left) {
        deg =
          180 - (Math.asin(widthEndToTemp / widthStartToEnd) * 180) / Math.PI;
      } else if (start.top >= end.top && start.left >= end.left) {
        deg =
          (Math.asin(widthEndToTemp / widthStartToEnd) * 180) / Math.PI + 180;
      }
    }

    return (
      <div
        style={{
          transform:
            "translate3d(" +
            left * this.state.zoom +
            "px, " +
            top * this.state.zoom +
            "px, 0)",
          WebkitTransform:
            "translate3d(" +
            left * this.state.zoom +
            "px, " +
            top * this.state.zoom +
            "px, 0)",
        }}
        className={start ? "marker-pink-pulse-mobile" : "hidden"}
      >
        <span href="#" className="intro-banner-vdo-play-btn" target="_blank">
          {deg ? (
            <Images.IconDirection
              style={{ transform: "rotate(" + deg + "deg)" }}
            />
          ) : null}
          <span className="ripple pinkBg"></span>
          <span className="ripple pinkBg"></span>
        </span>
      </div>
    );
  }

  imageLoad() {
    this.setState({ loading: true });
  }

  renderMarker(data, t) {
    return data.map((marker, index) => {
      return (
        <div
          className="marker-item"
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            transform:
              "translate3d(" +
              marker.left * this.state.zoom +
              "px, " +
              marker.top * this.state.zoom +
              "px, 0)",
            WebkitTransform:
              "translate3d(" +
              marker.left * this.state.zoom +
              "px, " +
              marker.top * this.state.zoom +
              "px, 0)",
          }}
          key={index}
        >
          <MarkerDisplay
            key={index}
            data={marker}
            nextMarker={this.props.endMarker}
            markerPreview={this.props.markerPreview}
            firstFlightLength={data.length - 1}
          />
        </div>
      );
    });
  }

  render() {
    let imageSource = Constants.getResourceUrl() + this.props.url;
    const { t } = this.props;
    return (
      <div
        className="preview-plan-map-img"
        ref={this.imageContainer}
      >
        <div
          className="status-map-view"
          ref={this.imageArea}
        >
          <div className="status-map-view-wrapper">
            <img
              alt="mapimage"
              src={imageSource}
              style={{ ...imageStyle }}
              onLoad={this.imageLoad.bind(this)}
              ref={this.imagePreview}
            />
            {this.state.loading && this.props.markers
              ? this.renderMarker(this.props.markers, t)
              : ""}

            {this.state.loading && this.props.markers
              ? this.renderIndicator()
              : ""}
          </div>
        </div>
      </div>
    );
  }
}
