import { memo } from "react";
import { IconSelect } from "../images";

const SelectComponent = (props) => {
  const { name, data, onChange, defaultChecked, value, className } = props;

  return (
    <div className="dps-select">
      <select
        className={className}
        name={name}
        defaultChecked={defaultChecked}
        value={value}
        onChange={onChange}
      >
        {data.map(item => (
          <option value={item.value} key={item.value + Math.random()}>{item.title}</option>
        ))}
      </select>
      <IconSelect color="currentColor" />
    </div>
  )
}

export default memo(SelectComponent);