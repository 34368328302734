import React, { Component } from "react";
import MenuAdmin from "../../components/menu-admin";
import * as Images from "../../components/images";
import { Modal } from "react-bootstrap";
import * as Constants from "../../utils/constants";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import axios from "axios";
import * as Validator from "../../utils/validator";
import cloneDeep from "lodash/cloneDeep";
import { NotificationManager } from "react-notifications";
import { confirmAlert } from "react-confirm-alert";
import LanguageSwitch from "../../components/languageSwitch";
import { withTranslation } from "react-i18next";
import { CHANGE_IMAGE_DECTECTED_MODE_URL, CHANGE_RECOGNITION_STATUS_URL } from "../../utils/constants";
import PaginationComponent from "../../components/pagination";
import SearchTable from "../../components/searchTable";

const init_addUser = {
  corpId: "",
  corpName: "",
  password: "",
  type: "drone",
  email: "",
  corpKey: "",
  secondCorpKey: "",
  recognitionStatus: "",
};

const init_editUser = {
  corpId: "",
  corpName: "",
  password: "",
  type: "",
  email: "",
  corpKey: "",
  secondCorpKey: "",
  recognitionStatus: "",
};

const tableHeader = [
  { title: "user.table.header_1" },
  { title: "user.table.header_2" },
  { title: "user.table.header_3" },
  { title: "user.table.header_4" },
  { title: "user.table.header_5" },
  { title: "user.table.header_6" },
];

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingStatus: "loading",
      users: [],
      addUser: cloneDeep(init_addUser),
      editUser: cloneDeep(init_editUser),
      isEdit: false,
      isCheckAdd: true,
      showCopied1: false,
      showCopied2: false,
      recognitionStatus: Boolean(JSON.parse(localStorage.getItem("user"))?.imageRecognition === "true"),
      // Pagination
      userTotal: 0,
      pageSize: 20,
      pageNumber: 1,
      formSearch: {
        corpName: null,
        email: null
      },
    };

    this.handleClose = this.handleClose.bind(this);
    this.addUserSubmit.bind(this);
    this.handleChangeImageDetectedMode.bind(this);
    this.editUserSubmit.bind(this);
    this.toHashString.bind(this);
    this.getUserList();
    document.documentElement.style.overflow = "hidden";
  }

  componentDidUpdate(prevProps, prevStates) {
    if(prevStates.pageSize !== this.state.pageSize || 
       prevStates.pageNumber !== this.state.pageNumber || 
       prevStates.formSearch !== this.state.formSearch) {
      this.getUserList()
    }
  }

  handleChangeImageDetectedMode(e) {
    e.preventDefault();
    const isChecked = e.target.checked;
    this.setState({ recognitionStatus: isChecked });
    let request = Constants.CHANGE_RECOGNITION_STATUS_URL + this.state.editUser.id;
    axios
      .post(
        request,
        { recognition_status: isChecked },
        {
          headers: this.getHeader().headers,
          body: { recognition_status: isChecked },
        }
      )
      .then((response) => {
        const newRecogitionStatus = response?.data?.imageRecognition;
        let user = cloneDeep(this.state.editUser);
        this.setState({ editUser: user });
        this.getUserList();
      })
      .catch(function (error) {
        console.log("ERRROR");
      });
  }

  handleChangeKirihaModeMode(field, e) {
    let user = this.state.editUser;
    user[field] = e.target.checked;
    this.setState({ user });
  }

  getHeader() {
    let headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "*",
      "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
    };
    return { headers: headers };
  }

  getUserList() {
    const params = {
      ...this.state.formSearch,
      pageSize: this.state.pageSize,
      pageNumber: this.state.pageNumber,
    }
    axios.get(Constants.USER_SEARCH_URL, {params}, this.getHeader()).then((res) => {
      this.setState({ 
        users: res.data.items ,
        userTotal: res.data.totalItems,
      });
      if(res.data.items) {
        this.setState({loadingStatus: "OK"})
      } else{
        this.setState({loadingStatus: "NOK"})
      }
    });
  }

  handleClose() {
    this.setState({ isEdit: false });
  }

  handleShow(item) {
    const editItem = cloneDeep(item);
    this.setState({ isEdit: true, editUser: editItem, recognitionStatus: editItem.imageRecognition }); // first render modal
    const currentCorpKey = this.state.editUser.corpKey;
    let request = Constants.USERS_URL + item.id;

    if (currentCorpKey && currentCorpKey.includes("*") === false) {
      axios
        .get(request)
        .then((response) => {
          let user = cloneDeep(this.state.editUser);
          const hashedCorpKey = response?.data?.corpKey;
          user.corpKey = hashedCorpKey;
          this.setState({ editUser: user });
        })
        .catch(function (error) {
          console.log("ERRROR");
        });
    }
  }

  handleChange(field, e) {
    let user = this.state.addUser;
    user[field] = e.target.value;
    this.setState({ user, isCheckAdd: true });
  }

  handleEditChange(field, e) {
    let user = this.state.editUser;
    user[field] = e.target.value;
    this.setState({ user });
  }

  handleDelete(item, t) {
    confirmAlert({
      title: t("user.message.confirm_delete_text"),
      message: "",
      buttons: [
        {
          label: t("common.ok"),
          onClick: () => {
            let request = Constants.USER_DELETE_URL + item.id;
            axios
              .delete(request, { headers: this.getHeader().headers, params: { uuid: item.uuid } })
              .then((response) => {
                this.getUserList();
                NotificationManager.success(t("dialog_delete.confirm_success2"), "", 2000);
              })
              .catch(function (error) {
                NotificationManager.error(t("dialog_delete.confirm_error2"), "common.error", 2000);
              });
          },
        },
        {
          label: t("common.back"),
        },
      ],
    });
  }

  handleChangeCorpKey(e) {
    e.preventDefault();
    let request = Constants.CHANGE_FIRST_CORP_KEY_URL + this.state.editUser.id;
    axios
      .post(request, this.state.editUser, {
        headers: this.getHeader().headers,
        params: { uuid: this.state.editUser.uuid },
      })
      .then((response) => {
        const newCorpKey = response?.data?.message;
        let user = cloneDeep(this.state.editUser);
        user.corpKey = newCorpKey;
        this.setState({ editUser: user });
      })
      .catch(function (error) {
        console.log("ERRROR");
      });
  }

  handleChangeSecondCorpKey(e) {
    e.preventDefault();
    let request = Constants.CHANGE_SECOND_CORP_KEY_URL + this.state.editUser.id;
    axios
      .post(request, this.state.editUser, {
        headers: this.getHeader().headers,
        params: { uuid: this.state.editUser.uuid },
      })
      .then((response) => {
        const newSecondCorpKey = response?.data?.message;
        let user = cloneDeep(this.state.editUser);
        user.secondCorpKey = newSecondCorpKey;
        this.setState({ editUser: user });
        console.log(newSecondCorpKey);
      })
      .catch(function (error) {
        console.log("ERRROR");
      });
  }

  copyCorpKey(e) {
    e.preventDefault();
    this.copyToClipboard(this.state.editUser.corpKey)
      .then(() => {
        this.setState({ showCopied1: true });
        setTimeout(() => {
          this.setState({ showCopied1: false });
        }, 1000);
      })
      .catch(() => {
        console.log("Copy fail");
      });
  }

  copySecondCorpKey(e) {
    e.preventDefault();
    this.copyToClipboard(this.state.editUser.secondCorpKey)
      .then(() => {
        this.setState({ showCopied2: true });
        setTimeout(() => {
          this.setState({ showCopied2: false });
        }, 1000);
      })
      .catch(() => {
        console.log("Copy fail");
      });
  }

  copyToClipboard(textToCopy) {
    // navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
      // navigator clipboard api method'
      return navigator.clipboard.writeText(textToCopy);
    } else {
      // text area method
      let textArea = document.createElement("textarea");
      textArea.value = textToCopy;
      // make the textarea out of viewport
      textArea.style.position = "fixed";
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      return new Promise((res, rej) => {
        // here the magic happens
        document.execCommand("copy") ? res() : rej();
        textArea.remove();
      });
    }
  }

  editUserSubmit(e, t) {
    e.preventDefault();
    //validate input data
    this.formEdit.validateAll();
    if (this.checkBtnEdit.context._errors.length === 0) {
      let request = Constants.USER_EDIT_URL + this.state.editUser.id;
      axios
        .put(request, this.state.editUser, {
          headers: this.getHeader().headers,
          params: { uuid: this.state.editUser.uuid },
        })
        .then((response) => {
          this.getUserList();
          this.handleClose();
          this.setState({ editUser: cloneDeep(init_addUser) });
          NotificationManager.success(t("avatar.notifi_success"), "", 2000);
        })
        .catch(function (error) {
          NotificationManager.error(t("avatar.notifi_error3"), t("common.error"), 2000);
        });
    }
  }

  addUserSubmit(e, t) {
    e.preventDefault();
    //validate input data
    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      axios
        .post(Constants.USER_ADD_URL, this.state.addUser, this.getHeader())
        .then((response) => {
          this.setState({ addUser: cloneDeep(init_addUser), isCheckAdd: false });
          this.getUserList();
          NotificationManager.success(t("user.message.add_user"), "", 2000);
        })
        .catch(function (error) {
          NotificationManager.error(t("user.message.validate"), "", 2000);
        });
    }
  }

  toHashString = (str, unhashedCharNum, hashChar = "*") => {
    const strLength = str.length;
    return new Array(strLength - unhashedCharNum).join(hashChar) + str.substring(strLength - 3);
  };

  renderItemHeaderTable(data, t) {
    return data.map((item, index) => {
      return <div className="clmn" key={index}>{t(item.title)}</div>;
    });
  }

  renderItemTable(data, t) {
    return data.map((item, index) => {
      return (
        <div className="list-item" key={index}>
          <div className="clmn">
            {item.corpId}
          </div>
          <div className="clmn">
            {item.corpName}
          </div>
          <div className="clmn">
            {item.email}
          </div>
          <div className="clmn">
            ********
          </div>
          <div className="clmn" style={ item.kirihaMode ? { fontWeight: 500, color: "#0D7EF0" } : undefined }>
            {item.kirihaMode ? "ON" : "OFF"}
          </div>
          <div className="actions clmn">
            <button className="btn-small edit position-relative" onClick={this.handleShow.bind(this, item)}>
              <div className="fix-icon-center-ios">
                <Images.Icons name="IconEdit" />
              </div>
            </button>
            <button
              className={item.roleName === "ROLE_ADMIN" ? "hidden" : "btn-small delete position-relative"}
              onClick={this.handleDelete.bind(this, item, t)}
            >
              <div className="fix-icon-center-ios">
                <Images.Icons name="IconDelete" />
              </div>
            </button>
          </div>
        </div>
      );
    });
  }

  renderModal(t) {
    return (
      <Modal className="edit-form" show={this.state.isEdit} onHide={this.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("user.message.edit_info")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            className="edit-user f-column f-center"
            onSubmit={(e) => this.editUserSubmit(e, t)}
            ref={(c) => {
              this.formEdit = c;
            }}
          >
            <div className="item">
              <label>ID</label>
              <Input
                type="text"
                className="dps-input"
                name="id"
                placeholder="ID"
                value={this.state.editUser.corpId}
                min={100}
                max={999}
                message={t("common.message_input")}
                validations={[Validator.required, Validator.number, Validator.range]}
                onChange={this.handleEditChange.bind(this, "corpId")}
              />
            </div>
            <div className="item">
              <label>{t("user.table.header_2")}</label>
              <Input
                type="text"
                className="dps-input"
                name="corpName"
                placeholder={t("user.table.header_2")}
                message={t("common.message_input")}
                value={this.state.editUser.corpName || ""}
                validations={[Validator.required]} // CHECK THIS BEFORE COMMIT BRO
                onChange={this.handleEditChange.bind(this, "corpName")}
              />
            </div>
            <div className="item">
              <label>{t("user.table.header_3")}</label>
              <Input
                type="text"
                className="dps-input"
                name="email"
                placeholder={t("user.table.header_3")}
                message={t("common.message_input")}
                value={this.state.editUser.email}
                validations={[Validator.required, Validator.email]}
                onChange={this.handleEditChange.bind(this, "email")}
              />
            </div>
            <div className="item">
              <label>{t("user.table.header_4")}</label>
              <Input
                type="password"
                className="dps-input"
                name="password"
                placeholder={t("user.table.header_4")}
                message={t("common.message_input")}
                value={this.state.editUser.password || ""}
                onChange={this.handleEditChange.bind(this, "password")}
              />
            </div>
            <div className="item mb-2">
              <label>{t("user.table.header_5")}</label>

              <div className="d-flex" style={{ width: '280px', justifyContent: 'flex-end' }}>
                <label className="switch mb-0" style={{ width: '38px' }}>
                  <input type="checkbox" checked={this.state.editUser.kirihaMode} onChange={this.handleChangeKirihaModeMode.bind(this, "kirihaMode")} />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
            <div className="item mb-1">
              <label>{t("user.first_corp_key")}</label>
              {/* {this.state.editUser.corpKey ? (
                <>
                  <strong className="flex-grow-1" style={{ fontSize: "13px", paddingRight: "10px" }}>
                    {this.state.editUser.corpKey ? this.toHashString(String(this.state.editUser.corpKey), 3) : ""}
                  </strong>
                  <button className="button dps-button-secondary" onClick={this.handleChangeCorpKey.bind(this)}>
                    {t("user.3rd_change_api")}
                  </button>
                  <button style={{ position: "relative" }} className="btn-small border-0 bg-white" onClick={this.copyCorpKey.bind(this)}>
                    {this.state.showCopied1 && <span style={{ position: "absolute", top:"-20px", left: "0", color: "blue"  }}>Copied</span>}
                    <Images.Icons name="IconCopy" color="#7f7f7f" />
                  </button>
                </>
              ) : (
                ""
              )} */}
              <>
                <strong className="flex-grow-1" style={{ fontSize: "13px", paddingRight: "10px" }}>
                  {this.state.editUser.corpKey ? this.toHashString(String(this.state.editUser.corpKey), 3) : ""}
                </strong>
                <button className="button dps-button-secondary" onClick={this.handleChangeCorpKey.bind(this)}>
                  {t("user.3rd_change_api")}
                </button>
                <button style={{ position: "relative" }} className="btn-small border-0 bg-white" onClick={this.copyCorpKey.bind(this)}>
                  {this.state.showCopied1 && <span style={{ position: "absolute", top: "-20px", left: "0", color: "blue" }}>Copied</span>}
                  <Images.Icons name="IconCopy" color="#7f7f7f" />
                </button>
              </>
            </div>
            <div className="item mb-1">
              <label>{t("user.second_corp_key")}</label>
              {/* {this.state.editUser.secondCorpKey ? (
                <>
                  <strong className="flex-grow-1" style={{ fontSize: "13px", paddingRight: "10px" }}>
                    {this.state.editUser.secondCorpKey ? this.toHashString(String(this.state.editUser.secondCorpKey), 3) : ""}
                  </strong>
                  <button className="button dps-button-secondary" onClick={this.handleChangeSecondCorpKey.bind(this)}>
                    {t("user.3rd_change_api")}
                  </button>
                  <button style={{ position: "relative" }} className="btn-small border-0 bg-white" onClick={this.copySecondCorpKey.bind(this)}>
                    {this.state.showCopied2 && <span style={{ position: "absolute", top: "-20px", left: "0", color: "blue" }}>Copied</span>}
                    <Images.Icons name="IconCopy" color="#7f7f7f" />
                  </button>
                </>
              ) : (
                ""
              )} */}
              <>
                <strong className="flex-grow-1" style={{ fontSize: "13px", paddingRight: "10px" }}>
                  {this.state.editUser.secondCorpKey ? this.toHashString(String(this.state.editUser.secondCorpKey), 3) : ""}
                </strong>
                <button className="button dps-button-secondary" onClick={this.handleChangeSecondCorpKey.bind(this)}>
                  {t("user.3rd_change_api")}
                </button>
                <button style={{ position: "relative" }} className="btn-small border-0 bg-white" onClick={this.copySecondCorpKey.bind(this)}>
                  {this.state.showCopied2 && <span style={{ position: "absolute", top: "-20px", left: "0", color: "blue" }}>Copied</span>}
                  <Images.Icons name="IconCopy" color="#7f7f7f" />
                </button>
              </>

            </div>
            <CheckButton
              style={{ display: "none" }}
              ref={(c) => {
                this.checkBtnEdit = c;
              }}
            />
            <button type="submit" className="btn-save position-relative">
              <div className="fix-icon-center-ios">
                <Images.Icons name="IconSave" />
              </div>
            </button>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }

  handleChangePageSize = (e) => {
    this.setState({
      pageSize: e.target.value,
      pageNumber: 1
    })
  }

  handleChangePagination = (curentPage) => {
    this.setState({
      pageNumber: curentPage
    })
  }

  handleSearch = (event, dataSearch) => {
    event.preventDefault();
    
    this.setState({
      formSearch: {...this.state.formSearch, pageNumber: 1, ...dataSearch},
      pageNumber: 1
    })
  }

  render() {
    const { t, i18n } = this.props;
    return (
      <>
        {this.renderModal(t)}
        <div className="dps-menu ">
          <MenuAdmin currentRouter={this.props.location.pathname} />
        </div>
        <div className="dps-content admin-page">
          <div className="dps-content-area">
            <div className="dps-title main-title">
              <h1>{t("user.title")}</h1>
              <LanguageSwitch />
            </div>

            <SearchTable 
              data={[
                {
                  name: 'corpName',
                  label: t('user.table.header_2'),
                  typeSearch: 'text',
                },
                {
                  name: 'email',
                  label: t('user.table.header_3'),
                  typeSearch: 'text',
                },
              ]}
              formSearch={this.state.formSearch}
              onSearch={this.handleSearch}
            />

            <div className="dps-main-content">
              <div className="wrap_table">
                {!this.state.users && <p className="txt-empty">{t("user.no_result")}</p>}
                <div className="dps-main-content-area flex-column" style={!this.state.users ? {height: 'max-content'} : undefined}>
                  {this.state.users && 
                    <div className="dps-list-f table-user">
                      <div className="header">
                        {this.renderItemHeaderTable(tableHeader, t)}
                      </div>
                      <div className="body">
                        {this.renderItemTable(this.state.users, t)}
                      </div>
                    </div>
                  }

                  <Form
                    className="add-item add-user position-relative f-row f-center"
                    autoComplete="new-password"
                    onSubmit={(e) => this.addUserSubmit(e, t)}
                    ref={(c) => {
                      this.form = c;
                    }}
                  >
                    <Input
                      type="text"
                      className="dps-input"
                      name="id"
                      placeholder={t("user.table.header_1")}
                      value={this.state.addUser.corpId}
                      min={100}
                      max={999}
                      typeCheck
                      isCheckAdd={this.state.isCheckAdd}
                      autoComplete="new-password"
                      message={t("common.message_input")}
                      validations={[Validator.required, Validator.number, Validator.range]}
                      onChange={this.handleChange.bind(this, "corpId")}
                    />
                    <Input
                      type="text"
                      className="dps-input"
                      name="corpName"
                      placeholder={t("user.table.header_2")}
                      value={this.state.addUser.corpName}
                      typeCheck
                      isCheckAdd={this.state.isCheckAdd}
                      validations={[Validator.required]}
                      message={t("common.message_input")}
                      onChange={this.handleChange.bind(this, "corpName")}
                    />
                    <Input
                      type="text"
                      className="dps-input"
                      name="email"
                      placeholder={t("user.table.header_3")}
                      value={this.state.addUser.email}
                      typeCheck
                      isCheckAdd={this.state.isCheckAdd}
                      message={t("common.message_input")}
                      validations={[Validator.required, Validator.email]}
                      onChange={this.handleChange.bind(this, "email")}
                    />

                    <Input
                      type="password"
                      className="dps-input"
                      name="password"
                      placeholder={t("user.table.header_4")}
                      message={t("common.message_input")}
                      value={this.state.addUser.password}
                      typeCheck
                      isCheckAdd={this.state.isCheckAdd}
                      validations={[Validator.required]}
                      onChange={this.handleChange.bind(this, "password")}
                    />
                    <CheckButton
                      style={{ display: "none" }}
                      ref={(c) => {
                        this.checkBtn = c;
                      }}
                    />
                    <button type="submit" className="btn-save position-relative">
                      <div className="fix-icon-center-ios">
                        <Images.Icons name="IconSave" />
                      </div>
                    </button>
                  </Form>
                </div>
              </div>
              <div className="footer-page">
                <p className="total-list">{this.state.userTotal} {t('user.total')}</p>
                <PaginationComponent 
                  total={this.state.userTotal} 
                  pageSize={this.state.pageSize} 
                  pageNumber={this.state.pageNumber}
                  handleChangePageSize={this.handleChangePageSize}
                  handleChangePagination={this.handleChangePagination} 
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation("dps")(User);
