import React, { Component } from "react";
import Menu from "../components/menu";
import * as Images from "../components/images";
import axios from "axios";
import * as Constants from "../utils/constants";
import { isMobile, isTablet } from "react-device-detect";
import SockJsClient from "react-stomp";
import { withTranslation } from "react-i18next";
import { getHeader } from "../services/api.service";
import HeaderMobile from "../components/HeaderMobile";
import DeveiceDetail from "../components/device/deveiceDetail";
import DeviceList from "../components/device/deviceList";

class Status extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: JSON.parse(localStorage.getItem("user")),
      accountType: JSON.parse(localStorage.getItem("user")).accountType,
      deviceIcons: null,
      loadingStatus: "loading",
      url: Constants.WS_URL,
      deviceStatusList: [],
      deviceActive: null,
      isShowMenu: false,
      viewDetail: null,
      isCheckStationNotFound: false,
    };
    //binding
    this.onDeviceSelect = this.onDeviceSelect.bind(this);
    this.handleShowMenu.bind(this);

    this.fetchData = this.fetchData.bind(this);
    this.fetchData();
    //this.getS3Object();
  }

  componentDidMount() {
    if(this.props.history.action === "PUSH") {
      const {deviceActive, deviceId, stationId} = this.props.history.location.state
      this.setState({
        viewDetail: true,
        deviceActive,
        deviceId,
        stationId
      })
    }
  }

  fetchData() {
    const promiseIcon = axios.get(
      Constants.MASTER_DATA_URL + "icon",
      getHeader()
    );
    const promiseStatusDevices = axios.get(
      Constants.DEVICE_STATUS_ALL_URL,
      getHeader()
    );
    Promise.all([
      promiseIcon,
      promiseStatusDevices,
    ]).then((responseArr) => {
      this.setState({
        deviceIcons: responseArr[0].data,
        deviceStatusList: responseArr[1].data,
      });
      if (responseArr[1].data != null && responseArr[1].data.length > 0) {
        this.setState({ loadingStatus: "OK" });
      } else {
        this.setState({ loadingStatus: "NOK" });
      }
    });
  }

  reLoadData () {
    axios.get(Constants.DEVICE_STATUS_ALL_URL, getHeader()).then((res) => {
      this.setState({
        deviceStatusList: res.data
      })
    })
  }

  onDeviceSelect(e, droneId, stationId, index, deviceStatusList) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      deviceId: droneId,
      stationId: stationId,
      viewDetail: true,
      deviceActive: deviceStatusList[index]
    });
  }

  handleShowMenu() {
    this.setState({ isShowMenu: !this.state.isShowMenu });
  }

  render() {
    const { t, i18n } = this.props;
    const { isShowMenu } = this.state;

    return (
      <>
        <HeaderMobile />
        <div
          className={
            "dps-menu-hamburger-button " +
            (isTablet
              ? "dps-menu-hamburger-button-tablet " +
                (isShowMenu ? "" : "dps-menu-hamburger-button-not-show")
              : "")
          }
          onClick={() => this.handleShowMenu()}
        >
          <Images.HamburgerButton />
        </div>
        <div
          className={
            "dps-menu " +
            (isTablet
              ? "dps-menu-tablet " + (isShowMenu ? "" : "dps-menu-not-show")
              : "")
          }
        >
          <Menu currentRouter={this.props.location.pathname} />
        </div>
        {isShowMenu && isTablet && <div className="dps-menu-back-ground"></div>}
        <div
          className={"dps-content " + (isTablet ? "dps-content-tablet" : "")}
          style={this.state.viewDetail ? { padding: 0 } : undefined}
        >
          <SockJsClient
            url={this.state.url}
            topics={["/topic/drone/list/" + JSON.parse(localStorage.getItem("user")).id]}
            onMessage={(msg) => {
              if (msg === JSON.parse(localStorage.getItem("user")).id) {
                this.fetchData();
              }
            }}
            ref={(client) => {this.clientRef = client}}
          />
          <div className="dps-content-area">
            <div className=" dps-main-content " style={!this.state.viewDetail ? { flex: 'unset' } : { padding: "0" }}>
              <div className="dps-main-content-area drone-status" style={isTablet && !this.state.viewDetail ? { height: 'auto' } : null}>
                {
                  {
                    loading: (
                      <div className="loading">
                        <img
                          alt="loading"
                          src={require("../assets/images/loading.gif")}
                        />
                      </div>
                    ),
                    NOK: (
                      <h1 className="txt-red no-data">
                        {this.state.accountType === "robot"
                          ? t("status.robot.message_1")
                          : t("status.drone.message_1")}
                      </h1>
                    ),
                    OK: (
                      <div className="drone-status-area">
                        <div
                          className={"drone-selector" + (!this.state.viewDetail ? " drone-list" : "")}
                        >
                          {this.state.viewDetail
                            ? 
                            <DeveiceDetail 
                              deviceList={this.state.deviceStatusList} 
                              deviceActive={this.state.deviceActive}
                              droneId={this.state.deviceId} 
                              stationId={this.state.stationId} 
                              isCheckStationNotFound={this.state.isCheckStationNotFound}
                              deviceIcons={this.state.deviceIcons}
                            />
                            : 
                            <DeviceList
                              data={this.state.deviceStatusList}
                              reLoadData={this.reLoadData.bind(this)}
                              onDeviceSelect={this.onDeviceSelect}
                              deviceIcons={this.state.deviceIcons}
                            />
                          }
                        </div>
                      </div>
                    ),
                  }[this.state.loadingStatus]
                }
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  getS3Object() {
    // console.log("get s3 resource")
    // var jwtToken = "eyJraWQiOiIwOEJCNUNNaldFclFweFZvUkdPU3p4N1kyTEMyRWx4cUFZeE01dGJ4aXZ3PSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIwMzJmYTNmMS03YjNjLTRlM2EtYmVjNC01ODNiZGI1Yzk4MzkiLCJhdWQiOiIxbWlsYnA4czVsbzYyYTQ3azZ2ZGNyaWhrYSIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiZXZlbnRfaWQiOiIxNGE3NGY1OC1jN2QwLTRkNTYtOGE0ZC0wYTM5YTUwNzI3NGUiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTYxMTIxOTYxMCwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmFwLXNvdXRoZWFzdC0xLmFtYXpvbmF3cy5jb21cL2FwLXNvdXRoZWFzdC0xXzNPdVhlb0tXeSIsImNvZ25pdG86dXNlcm5hbWUiOiIwMzJmYTNmMS03YjNjLTRlM2EtYmVjNC01ODNiZGI1Yzk4MzkiLCJleHAiOjE2MTEyMjMyMTAsImlhdCI6MTYxMTIxOTYxMCwiZW1haWwiOiJpLnRydW9uZ3ZhQGdtYWlsLmNvbSJ9.dc0kfvj6XuczfBX4o1SowJiREpPmh2yEWGys5D0AoSxM_gZnEJ15yTHTYL2TIQSo5CglgxHjm1g1dMU-Dgdzmhe6O5zTASAe2qZy1tdWUaI3lrO2m6qC9fDC57uJJ_cZNsz27zjolrPnUPxW-7cCaw74wA7WLekB8wAuOBj2diTSOg0K3sC3dCnuHbDgVWhl-K63wNHW-pfpKz-4-B3L3MTyw2UrEbvKi83B2HEgG7eOYOI8kWtkFiyqAVweLiaVBkpcHjwj9MIaI3C9Q_vCUR-1ZF87bwfEER4CwhIDWZrVMnvs8YHE0KugM84AdxMsnzWDMBryx-_uOcprIb3inQ"
    // AWS.config.region = 'ap-southeast-1';
    // var credParams = {
    //     IdentityPoolId: 'ap-southeast-1:29d0ed9e-824f-4c1c-8e86-701055f6f1c7',
    //     Logins: {
    //         'cognito-idp.ap-southeast-1.amazonaws.com/ap-southeast-1_3OuXeoKWy': jwtToken
    //     }
    // };
    // AWS.config.credentials = new AWS.CognitoIdentityCredentials(credParams);
    // var s3 = new AWS.S3();
    // var params = {
    //     Bucket: "spiral-poc",
    //     Key: "Media/FHD-video_102_1610628467_b113eb8e54ad4a96b7040382fa9cbb10.mp4",
    //     Expires: 60
    // };
    // s3.getSignedUrl('getObject', params, (err, data) => {
    //     this.setState({ videoSource: data });
    // });
    // var params = {
    //     Bucket: "spiral-poc",
    //     Key: "Media/FHD-video_102_1610628467_b113eb8e54ad4a96b7040382fa9cbb10.mp4",
    // };
    // var req = s3.getObject(params
    //     , (err, data) =>{
    //     if (err) {
    //         console.log(err)
    //     }
    //     else {
    //         var base = new Buffer.from(data.Body.buffer).toString("base64");
    //         console.log(base)
    //         this.setState({ videoSource: base });
    //     }
    // }
    // )
    // if(req){
    //     var stream =  req.createReadStream().pipe();
    //     var base = new Buffer.from(stream.Body.buffer).toString("base64");
    //     this.setState({ videoSource: base });
    // }
  }
}
export default withTranslation()(Status);
