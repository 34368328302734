import React, {useEffect, useState, useRef} from 'react'
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import * as Images from "../images";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import * as Validator from "../../utils/validator";
import {MapCanvas} from "../map/mapCanvas";
import {NotificationManager} from "react-notifications";
import update from 'immutability-helper';
import {useTranslation} from "react-i18next";
import {TouchBackend} from 'react-dnd-touch-backend';
import {isTablet} from 'react-device-detect';
import cloneDeep from 'lodash/cloneDeep';

// Tab content Route Confirmation of Plan Tabs
// Usage: Can edit plan and verify planContent in map view

const initialMarkerState = [{
  "id": null,
  "top": 0,
  "left": 0,
  "hover_t": 0,
  "isStation": "false",
  "direction": "",
  "angle": 0,
  "marker_distance": 0,
  "wall_distance": 0,
  "height": 0,
  "action": {
    "type": "image",
    "rotate": 0,
    "hold_t": 0
  }
}];

const PlanMap = (props) => {
  const {t, i18n} = useTranslation();
  let {scale, handleScaleChange, handleDirectionChange, id, tab, handleChangeTab} = props

  const accountType = JSON.parse(localStorage.getItem('user')).accountType;
  const [action, setAction] = useState('upload'); // upload, setScale, setFirstMarker, showRoute
  const [mapEditorImage, setMapEditorImage] = useState(props.mapEditorImage);

  const [flightMarkers, setFlightMarkers] = useState(props.flightMarkers);
  const [firstMarker, setFirstMarker] = useState(props.firstMarker);
  const [direction, setDirection] = useState(props.direction);
  const [zoom, setZoom] = useState(props.zoom);
  const [markerInMap, setMarkerInMap] = useState(props.markerInMap);
  // Tab Scale
  const [value, setValue] = useState(0);

  // Tab Set First Marker
  const [showModal, setShowModal] = useState(false);
  const [markersModal, setMarkersModal] = useState(initialMarkerState);

  const refBtnUpload = useRef(null)
  const refBtnSetScale = useRef(null)
  const refBtnFirstMarker = useRef(null)
  const refBtnShowRoute = useRef(null)

  useEffect(() => {
    let {id, mapEditorImage, scale, flightMarkers, firstMarker, direction, markerInMap, zoom} = props;
    setMapEditorImage(mapEditorImage);
    setValue(scale?.value)
    setFlightMarkers(flightMarkers)
    setFirstMarker(firstMarker)
    setDirection(direction)
    setMarkerInMap(markerInMap);
    setZoom(zoom)
  }, [props])

  useEffect(() => {
    switch (action) {
      case 'upload':
        refBtnUpload.current.classList.add('active')
        refBtnUpload.current.classList.remove('pass')
        refBtnSetScale.current.classList.remove('active', 'pass')
        refBtnFirstMarker.current.classList.remove('active', 'pass')
        refBtnShowRoute.current.classList.remove('active', 'pass')
        return
      case 'setScale':
        refBtnUpload.current.classList.remove('active')
        refBtnUpload.current.classList.add('pass')
        refBtnSetScale.current.classList.add('active')
        refBtnSetScale.current.classList.remove('pass')
        refBtnFirstMarker.current.classList.remove('active', 'pass')
        refBtnShowRoute.current.classList.remove('active', 'pass')
        return
      case 'setFirstMarker':
        refBtnUpload.current.classList.remove('active')
        refBtnUpload.current.classList.add('pass')
        refBtnSetScale.current.classList.remove('active')
        refBtnSetScale.current.classList.add('pass')
        refBtnFirstMarker.current.classList.add('active')
        refBtnFirstMarker.current.classList.remove('pass')
        refBtnShowRoute.current.classList.remove('active', 'pass')
        return
      case 'showRoute':
        refBtnUpload.current.classList.remove('active')
        refBtnUpload.current.classList.add('pass')
        refBtnSetScale.current.classList.remove('active')
        refBtnSetScale.current.classList.add('pass')
        refBtnFirstMarker.current.classList.remove('active')
        refBtnFirstMarker.current.classList.add('pass')
        refBtnShowRoute.current.classList.add('active')
        return
    
      default: return
    }
  }, [action])

  useEffect(() => {
    setAction('upload')
  }, [tab])

  const showScale = () => {
    if (mapEditorImage) {
      setAction('setScale');
    } else {
      NotificationManager.info(t('planMap.info.not_set_image'), '', 2000);
    }

  };

  const showFirstMarker = () => {
    if (flightMarkers.length === 0) {
      NotificationManager.info(t('planMap.info.set_first_marker_empty_plan'), '', 2000);
      return false;
    }
    if (!mapEditorImage) {
      NotificationManager.info(t('planMap.info.not_set_image'), '', 2000);
      return false;
    }
    if (!scale || !scale.value || !scale.startPoint || !scale.endPoint) {
      NotificationManager.info(t('planMap.info.not_set_scale'), '', 2000);
      return false;
    }
    setAction('setFirstMarker');
    setFirstMarker({...firstMarker, id: flightMarkers[0].id})
  };

  const showRoute = () => {
    if (flightMarkers.length === 0) {
      NotificationManager.info(t('planMap.info.set_first_marker_empty_plan'),
          '', 2000);
      return false;
    }
    if (!mapEditorImage) {
      NotificationManager.info(t('planMap.info.not_set_image'), '', 2000);
      return false;
    }
    if (!scale) {
      NotificationManager.info(t('planMap.info.not_set_scale'), '', 2000);
      return false;
    }
    if (!direction) {
      NotificationManager.info(t('planMap.info.not_set_direction'), '', 2000);
      return false;
    }
    setAction('showRoute');
  };

  const uploadImageMapEditor = () => {
    setAction('upload');
  };

  // Tab Upload
  const handleClickChangeFile = () => {
    document.getElementById('imageMapEditorUpload').click()
  }

  // Tab Set Scale
  const handleChange = (event) => {
    const {value} = event.target;
    setValue(value);
  };

  // Tab Set First Marker
  const openModal = (markerId) => {
    var markers = flightMarkers.filter(
        (item) => parseInt(item.id) === parseInt(markerId));
    if (markers && markers.length > 0) {
      setMarkersModal(markers);
      setShowModal(true)
    }
  };

  const handleClose = () => {
    setShowModal(false)
  };

  const handleEditChange = (event, index) => {
    const {name, value} = event.target;
    setMarkersModal(update(markersModal, {[index]: {$merge: {[name]: value}}}));
  };

  const handleSave = () => {
    let indexArr = [];
    const markerId = markersModal[0].id;
    let flight = cloneDeep(flightMarkers);
    for (let i = 0; i < flight.length; i++) {
      if (flight[i].id === markerId) {
        indexArr.push(i);
      }
    }
    markersModal.map((marker, i) => {
      let updateIndex = indexArr[i];
      flight = update(flight, {[updateIndex]: {$set: marker}})
    });
    props.handleUpdatePlanContent(flight);
    setShowModal(false);
  };

  const switchContentTabEditAdvance = () => {
    switch (action) {
      case 'upload':
        return(
          <>
            <h3 className='tab-title'>{t('plan.tab_2.upload.title')}</h3>
            <p className='tab-desc'>
              <span>{t('plan.tab_2.upload.desc_1')} </span>
              <span>{t('plan.tab_2.upload.desc_2')}</span>
            </p>
            <div className="tab-from" onClick={() => handleClickChangeFile()}>
              <label>{t('plan.tab_2.upload.label')}</label>
              <div className="mask-tab-form">
                <Form onSubmit={props.handleSaveMap} className="d-inline-block hidden">
                  <Input
                    id="imageMapEditorUpload"
                    type="file"
                    name="planName"
                    placeholder={accountType === 'robot' ? t('planMap.travel_plan_name') : t('planMap.flight_plan_name')}
                    message={accountType === 'robot' ? t('planMap.Please_enter_the_move_plane_name') : t('planMap.Please_enter_the_flight_plane_name')}
                    accept="image/*,application/pdf"
                    validations={[Validator.required]}
                    onChange={props.handleChangeImageFile}
                  />
                </Form>
                <span>{t('plan.tab_2.upload.selected')}</span>
              </div>
            </div>

            <div className="tab-actions">
              <button className='next-tab' onClick={showScale}>{t('plan.btn_next_tab')}</button>
            </div>
          </>
        )
      case 'setScale':
        return(
          <>
            <h3 className='tab-title'>{t('plan.tab_2.set_scale.title')}</h3>
            <p className='tab-desc'>
              <span>{t('plan.tab_2.set_scale.desc_1')} </span>
              <span>{t('plan.tab_2.set_scale.desc_2')}</span>
            </p>
            <div className="tab-from">
              <label>{t('plan.tab_2.set_scale.label')}</label>
              <div className='mask-input'>
                <input 
                  type="number" 
                  defaultValue={0} 
                  value={value}
                  min={0} 
                  onChange={handleChange}
                  onBlur={() => handleScaleChange({value})}
                />
                <div className='custom-value'>
                  <span>{value || 0}</span>
                  <span> m</span>
                </div>
              </div>
            </div>

            <div className="tab-actions">
              <button className='pre-tab' onClick={uploadImageMapEditor}>{t('plan.btn_pre_tab')}</button>
              <button className='next-tab' onClick={showFirstMarker}>{t('plan.btn_next_tab')}</button>
            </div>
          </>
        )
      case 'setFirstMarker':
        return(
          <>
            <h3 className='tab-title'>{t('plan.tab_2.set_first_marker.title')}</h3>
            <p className='tab-desc'>
              <span>{t('plan.tab_2.set_first_marker.desc_1')} </span>
              <span>{t('plan.tab_2.set_first_marker.desc_2')}</span>
            </p>
            <div className="tab-actions">
              <button className='pre-tab' onClick={showScale}>{t('plan.btn_pre_tab')}</button>
              <button className='next-tab' onClick={showRoute}>{t('plan.btn_next_tab')}</button>
            </div>
          </>
        )
      case 'showRoute':
        return(
          <>
            <h3 className='tab-title'>{t('plan.tab_2.set_show_route.title')}</h3>
            <p className='tab-desc'>
              <span>{t('plan.tab_2.set_show_route.desc_1')}</span>
              <br /> <br />
              <span>{t('plan.tab_2.set_show_route.desc_2')}</span>
              <span>
                <button 
                  style={{
                    display: 'inline-block', 
                    fontSize: 'inherit', 
                    border: 0, 
                    background: 'transparent', 
                    padding: 0, 
                    textDecoration: 'underline', 
                    marginLeft: '2px'
                  }}
                  onClick={() => handleChangeTab('view')}
                >{t('plan.tab_2.set_show_route.btn_go_to_tab_update')}</button>
              </span>
            </p>
          </>
        )
      default: return
    }
  }

  return (
      <>
        <DndProvider backend={isTablet ? TouchBackend : HTML5Backend}>
          <div className="map-editor">
            <div className="menu-zone">
              <div className="action">
                <button ref={refBtnUpload} className='dps-button_tab' onClick={uploadImageMapEditor}>{t('plan.tab_2.upload.text_btn')}</button>
                <Images.IconArrowTab color='currentColor' />
                <button ref={refBtnSetScale} className="dps-button_tab" onClick={showScale}>{t('plan.tab_2.set_scale.text_btn')}</button>
                <Images.IconArrowTab color='currentColor' />
                <button ref={refBtnFirstMarker} className="dps-button_tab" onClick={showFirstMarker}>{t('plan.tab_2.set_first_marker.text_btn')}</button>
                <Images.IconArrowTab color='currentColor' />
                <button ref={refBtnShowRoute} className="dps-button_tab" onClick={showRoute}>{t('plan.tab_2.set_show_route.text_btn')}</button>
              </div>
            </div>
            <div className="map-zone">
              <div className="content-map-zone">
                {switchContentTabEditAdvance()}
              </div>
              <div className="preview-map-zone">
                <MapCanvas
                  url={props.mapEditorImage}
                  tab={tab}
                  action={action}
                  onScaleChange={handleScaleChange}
                  onDirectionChange={handleDirectionChange}
                  onFirstMarkerChange={props.handleFirstMarkerChange}
                  handleClearSetingFirstMarker={props.handleClearSetingFirstMarker}
                  onZoomChange={props.handleZoomChange}
                  showModal={showModal}
                  markersModal={markersModal}
                  handleClose={handleClose}
                  openModal={openModal}
                  handleEditChange={handleEditChange}
                  handleSave={handleSave}

                  id={id}
                  flightMarkers={flightMarkers}
                  direction={direction}
                  firstMarker={firstMarker}
                  isChangeFirstMarker={props.isChangeFirstMarker}
                  markerInMap={markerInMap}
                  scale={scale}
                  zoom={zoom}
                />
              </div>
            </div>
          </div>
        </DndProvider>
      </>
  );
};

export default PlanMap;