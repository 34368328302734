import { memo } from "react";

const IconCheck = ({isCheck}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.2353 1.55882H1.76471C1.37486 1.55882 1.05882 1.87486 1.05882 2.26471V13.7353C1.05882 14.1251 1.37486 14.4412 1.76471 14.4412H13.2353C13.6251 14.4412 13.9412 14.1251 13.9412 13.7353V2.26471C13.9412 1.87486 13.6251 1.55882 13.2353 1.55882ZM1.76471 0.5C0.790086 0.5 0 1.29009 0 2.26471V13.7353C0 14.7099 0.790086 15.5 1.76471 15.5H13.2353C14.2099 15.5 15 14.7099 15 13.7353V2.26471C15 1.29009 14.2099 0.5 13.2353 0.5H1.76471Z" fill="#5F55C4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.77759 12.5312L1.97498 8.03757L3.10387 7.08204L5.81013 10.2794L11.9191 3.46875L13.0207 4.45624L5.77759 12.5312Z" fill={isCheck ? "#5F55C4" : "#E9E8F7"}/>
    </svg>
  )
}

const CheckBoxComponent = (props) => {
  const { title, name, onChange, defaultChecked, value, className, style } = props;

  return (
    <div className={`dps-checkbox-custom ${className ? className : ""}`} style={style}>
      <label className="form-checkbox-custom" onClick={(e) => e.stopPropagation()}>
        <input type="checkbox" name={name} onChange={onChange} checked={value} defaultChecked={defaultChecked} />
        <IconCheck isCheck={value} />
      </label>
      {title && <span>{title}</span>}
    </div>
  )
}

export default memo(CheckBoxComponent);