import { useState } from "react";
import { isMobile } from "react-device-detect";
import { t } from "i18next";

import * as Images from "./images";
import MobileMenu from "./mobileMenu";
import LanguageSwitch from "./languageSwitch";

function HeaderMobile(props) {
  const [isShowMenu, setIsShowMenu] = useState(false);
  const [isShowFormSetting, setIsShowFormSetting] = useState(false);

  const onShowMenu = () => {
    setIsShowMenu(!isShowMenu);
    setIsShowFormSetting(false);
  }
  
  const onShowFormSetting = () => {
    setIsShowFormSetting(true);
    setIsShowMenu(false);
  }

  if (isMobile) {
    return (
      <div className="dps-header-mobile">
        <img
          className="logo-mobile"
          src={Images.LogoTextWhite}
          alt="spiral logo"
        />
        <div
          className="header-icon-mobile"
          onClick={onShowMenu}
        >
          {isShowMenu ?
            <Images.IconCloseMenu />
            :
            <Images.NavMobile />
          }
        </div>

        <div className={"form-fixed form-menu-mb" + (isShowMenu ? " active" : "")}>
          <MobileMenu 
            currentRouter={window.location.pathname}
            onShowFormSetting={onShowFormSetting}
          />
        </div>

        {isShowFormSetting &&
          <div className="form-fixed form-setting-mb">
            <h3>{t("mobileMenu.textSetting")}</h3>
            <LanguageSwitch />
          </div>
        }
      </div>
    )
  }
}
export default HeaderMobile;
