import React, { useEffect, useRef, useState } from "react";
import FlightMarker from "./flightMarker";
import { doGet, doPost, getHeader } from "../../services/api.service";
import * as Constants from "../../utils/constants";
import * as Images from "../images";
import update from "immutability-helper";
import { useTranslation } from "react-i18next";
import cloneDeep from "lodash/cloneDeep";
import { NotificationManager } from "react-notifications";
import AuthService from "../../services/auth.service";
import moment from "moment";
import momentTimezone from 'moment-timezone';
import { FE_VER } from "../../utils/constants";
import { Nav, Tab } from "react-bootstrap";
import PlanMarkerSettings from "./planMarkerSettings";
import PlanMap from "./planMap";
import KirihaEditTableComponent from './kirihaEditTable';
import axios from "axios";
import pdfjs from "pdfjs-dist/legacy/build/pdf.js";
import { Reorder, AnimatePresence } from "framer-motion";
import { generateUUIDv4, timeConverter } from "../../utils/utils";

const initialMarkerState = {
  id: 1,
  hover_t: 0,
  isStation: "false",
  direction: "right",
  angle: 0,
  marker_distance: 0,
  wall_distance: 1,
  marker_distance_mechanism: "sensor",
  marker_size: 20,
  height: 0,
  mTemp1: "",
  mTemp2: "",
  action: {
    type: "none",
    rotate: 0,
    hold_t: 0,
  },
  rtn: false,
  markerId: generateUUIDv4()
};

let initialState = {
  user: {
    corpId: "",
    corpName: "",
    division: "",
    place: "",
  },
  date: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
  app: {
    ver: ``,
  },
  drone: {
    type: "",
    frm_ver: "",
  },
  flight: {
    flight_plan_no: "",
    flight_plan_name: "",
    speed: 0,
    marker_recog_t: null,
    port: {
      takeoff: "",
      landon: "",
    },
    fTemp: "",
    emergency_marker: 999,
    flight_marker: [initialMarkerState],
  },
  kirihaMode: false,
  captureDistance: 20,
};

const faceKirahaMode = "face";
const markerKirihaMode = "marker";

const PlanContent = (props) => {
  const { action, changeCsvSuccess } = props;
  const accountType = JSON.parse(localStorage.getItem("user")).accountType;
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const KIRIHA_MODE = JSON.parse(localStorage.getItem("user")).kirihaMode;

  const { t, i18n } = useTranslation();
  const [plan, setPlan] = useState(initialState);
  const [droneTypes, setDroneTypes] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorElement, setErrorElement] = useState("");
  const [isChange, setIsChange] = useState(false);
  const [isMapChange, setIsMapChange] = useState(false);
  const [isChangeFirstMarker, setIsChangeFirstMarker] = useState(false);
  const [chargingStation, setChargingStation] = useState([]);

  const [mapEditorImage, setMapEditorImage] = useState(null);
  const [mapEditorContent, setMapEditorContent] = useState(null);
  const [imageFileChange, setImageFileChange] = useState(null);
  const [planId, setPlanId] = useState(null);
  const [flightMarkers, setFlightMarkers] = useState([]);
  const [direction, setDirection] = useState(null);
  const [scale, setScale] = useState(null);
  const [zoom, setZoom] = useState(0);
  const [firstMarker, setFirstMarker] = useState(null);
  const [markerInMap, setMarkerInMap] = useState([]);

  const [mapImage, setMapImage] = useState(
    props.plan?.mapImage ? props.plan?.mapImage : null
  );
  const [imageMapFileChange, setImageMapFileChange] = useState(null);
  const [markersChange, setMarkersChange] = useState(
    props.plan?.mapContent ? props.plan?.mapContent : []
  );

  // Sortable
  const [markerData, setMarkerData] = useState([]);
  const [isChangeMarker, setIsChangeMarker] = useState(false);

  // Kiriha
  const [faceCsvUploadedInfo, setFaceCsvUploadedInfo] = useState(null);
  const [isOpenEditFaceKiriha, setIsOpenEditFaceKiriha] = useState(false);
  const [faceKirihaData, setFaceKirihaData] = useState([]);

  const [markerCsvUploadedInfo, setMarkerCsvUploadedInfo] = useState(null);
  const [isOpenEditMarkerKiriha, setIsOpenEditMarkerKiriha] = useState(false);
  const [markerKirihaData, setMarkerKirihaData] = useState([]);

  const [isKirihaMode, setIsKirihaMode] = useState(false);

  const btnRef = useRef(null);

  const developerMode = Boolean(
    localStorage.getItem("developerMode") === "true"
  );

  useEffect(() => {
    setIsChange(false);
  }, [action])

  useEffect(() => {
    if (props.plan) {
      let { id, mapEditorContent, planContent, mapEditorImage, planNo } = props.plan;

      const returnedPlan = planContent;
      const flightMarker = returnedPlan.flight.flight_marker;
      returnedPlan.flight.flight_marker = flightMarker.map((marker) => {
        if (marker.marker_distance_mechnism) {
          delete marker.marker_distance_mechnism;
        }
        if (!marker?.marker_distance_mechanism) {
          marker.marker_distance_mechanism = "sensor";
        }
        if (!marker?.marker_size) {
          marker.marker_size = 20;
        }
        return marker;
      });
      // setPlan(props.plan);
      setPlan(returnedPlan);

      setMapEditorImage(mapEditorImage);
      setMapEditorContent(mapEditorContent);
      setPlanId(id);
      setFlightMarkers(
        planContent && planContent.flight && planContent.flight.flight_marker
          ? planContent.flight.flight_marker
          : []
      );
      setDirection(mapEditorContent ? mapEditorContent.direction : null);
      setScale(mapEditorContent ? mapEditorContent.scale : null);
      setFirstMarker(mapEditorContent ? mapEditorContent.firstMarker : null);
      setMarkerInMap(mapEditorContent ? mapEditorContent.markers : []);
      setMarkerData(planContent.flight.flight_marker);

      getKirihaData(planNo);
    } else {
      setMarkerData([initialMarkerState]);
      setPlan(initialState);
    }
    setErrorMessage("");
    getDroneTypes();
    if (btnRef.current) {
      btnRef.current.setAttribute("disabled", "disabled");
    }
    getChargingStationList();
  }, []);

  useEffect(() => {
    if (props.plan) {
      let {
        id,
        mapEditorImage,
        mapEditorContent,
        planContent,
        mapImage,
        mapContent,
        faceCsvFile,
        markerCsvFile,
        kirihaMode
      } = props.plan;
      setPlanId(id);
      setMapEditorImage(mapEditorImage);
      setScale(mapEditorContent ? mapEditorContent.scale : null);
      setFirstMarker(mapEditorContent ? mapEditorContent.firstMarker : null);
      setDirection(mapEditorContent ? mapEditorContent.direction : null);
      setFlightMarkers(
        planContent && planContent.flight && planContent.flight.flight_marker
          ? planContent.flight.flight_marker
          : []
      );
      setMarkerInMap(mapEditorContent ? mapEditorContent.markers : []);

      setMapImage(mapImage);
      setMarkersChange(mapContent ? mapContent : []);
      setMarkerData(props.plan.planContent.flight.flight_marker);

      setFaceCsvUploadedInfo(faceCsvFile);
      setMarkerCsvUploadedInfo(markerCsvFile);
      setIsKirihaMode(kirihaMode);
    }
  }, [props]);

  // When direction changes, we calculate marker location in map (markerInMap)
  useEffect(() => {
    if (props.tabView === "map-advance" && zoom !== 0) {
      updateMapData();
    }
  }, [
    props.tabView,
    planId,
    zoom,
    scale,
    firstMarker,
    direction,
    flightMarkers,
  ]);

  useEffect(() => {
    if (isChange || isMapChange || isChangeMarker) {
      btnRef.current.removeAttribute("disabled");
    } else {
      btnRef.current.setAttribute("disabled", "disabled");
    }
  }, [isChange, isMapChange, isChangeMarker]);

  useEffect(() => {
    if (
      !markerInMap.length &&
      !direction &&
      ((firstMarker?.top === 0 && firstMarker?.left === 0) || !(firstMarker && Object.keys(firstMarker).length))
    ) {
      setIsChangeFirstMarker(false);
    } else {
      setIsChangeFirstMarker(true);
    }
  }, [markerInMap, direction, firstMarker]);

  useEffect(() => {
    if (isChangeMarker) {
      setPlan(
        update(plan, { flight: { flight_marker: { $set: markerData } } })
      );
    }
  }, [markerData]);

  useEffect(() => {
    if(!isOpenEditFaceKiriha && !isOpenEditMarkerKiriha) {
      const notiWrap = document.querySelector(".notification-container > div");
      if(notiWrap) {
        notiWrap.classList.remove("noty-plan")
      }
    }
  }, [isOpenEditFaceKiriha, isOpenEditMarkerKiriha])

  const getDroneTypes = () => {
    doGet(
      Constants.DRONE_TYPE_URL,
      null,
      (res) => {
        setDroneTypes(res.data);
        if (res.data && res.data.length > 0) {
          initialState.drone.type = res.data[0].name;
        }
      },
      (error) => console.log(error)
    );
  };

  const getChargingStationList = () => {
    doGet(
      Constants.CHARGING_STATION_URL,
      null,
      (res) => {
        setChargingStation(res.data);
      },
      (error) => console.log(error)
    );
  };

  const getKirihaData = (planNo) => {
    const promiseGetFaceCsvFile = axios.get(Constants.CSV_CUSTOMER, {
      params : {
        corpId: currentUser.corpId,
        flightPlanNo: planNo,
        mode: faceKirahaMode
      },
      headers : { corpKey: currentUser.corpKey }
    });

    const promiseGetMarkerCsvFile = axios.get(Constants.CSV_CUSTOMER, {
      params : {
        corpId: currentUser.corpId,
        flightPlanNo: planNo,
        mode: markerKirihaMode
      },
      headers : { corpKey: currentUser.corpKey }
    });

    Promise.allSettled([promiseGetFaceCsvFile, promiseGetMarkerCsvFile]).then(ressponseArr => {
      if (ressponseArr[0].status === "fulfilled" && ressponseArr[0].value) {
        const dataMap = ressponseArr[0].value.data.split('\n');
        setFaceKirihaData(dataMap);
      } else {
        setFaceKirihaData([]);
      }

      if (ressponseArr[1].status === "fulfilled" && ressponseArr[1].value) {
        const dataMap = ressponseArr[1].value.data.split('\n');
        setMarkerKirihaData(dataMap);
      } else {
        setMarkerKirihaData([]);
      }
    })
  }

  const updateMapData = () => {
    if (direction && scale) {
      let updatedMarkerInMap = [];
      let realMapDirection = direction;
      let latestMoveDirection;

      let latestMarker = firstMarker;
      updatedMarkerInMap.push(firstMarker);
      for (let i = 0; i < flightMarkers.length; i++) {
        const info = flightMarkers[i];
        let marker = {
          id: flightMarkers[i + 1] ? flightMarkers[i + 1].id : null,
        };
        if (i === 0) {
          latestMoveDirection = info.direction;
        }

        if (["left", "right"].includes(info.direction)) {
          // Move
          const distanceInMap = getDistanceInMap(info.marker_distance);
          realMapDirection = getRealMapDirection(
            realMapDirection,
            info.direction,
            latestMoveDirection
          );
          // Move by marker_distance
          marker.top =
            latestMarker.top +
            (realMapDirection === "up"
              ? -1
              : realMapDirection === "down"
              ? 1
              : 0) *
              distanceInMap;
          marker.left =
            latestMarker.left +
            (realMapDirection === "left"
              ? -1
              : realMapDirection === "right"
              ? 1
              : 0) *
              distanceInMap;
          latestMoveDirection = info.direction;
        }
        // else if (info.direction === "turn" && (Number(info.action.rotate) === 90 || Number(info.action.rotate) === -90))
        // ADDNEW
        else if (
          info.direction === "turn" &&
          (Number(info.angle) === 90 || Number(info.angle) === -90)
        ) {
          // Rotate:
          const nextWallDistance = flightMarkers[i + 1]
            ? flightMarkers[i + 1].wall_distance
            : 0;
          const markerMoveResult = moveMarker(
            latestMarker,
            realMapDirection,
            latestMoveDirection,
            nextWallDistance,
            info
          );
          marker.top = markerMoveResult.top;
          marker.left = markerMoveResult.left;
          // const arr = ("turn" === info.direction && info.action.rotate == 90) ? ["up", "right", "down", "left"] : ["up", "left", "down", "right"];
          // const arr = (Number(info.action.rotate) === 90) ? ["up", "right", "down", "left"] : ["up", "left", "down", "right"];
          // ADDNEW
          const arr =
            Number(info.angle) === 90
              ? ["up", "right", "down", "left"]
              : ["up", "left", "down", "right"];
          const indexOfCurrentDirection = arr.findIndex(
            (item) => item === realMapDirection
          );
          realMapDirection =
            indexOfCurrentDirection === 3
              ? arr[0]
              : arr[indexOfCurrentDirection + 1];
        } else if ("landed" === info.direction) {
          // Stop
          break;
        }

        latestMarker = marker;
        updatedMarkerInMap.push(marker);
      }
      setMarkerInMap(updatedMarkerInMap);
    }
  };

  const getDistanceInMap = (distance) => {
    let { startPoint, endPoint, value } = scale;
    distance = parseFloat(distance);
    value = parseFloat(value);
    if (!(startPoint && endPoint)) {
      startPoint = {
        top: 0,
        left: 0,
      };
      endPoint = {
        top: 0,
        left: 0,
      };
    }
    const distanceOnMap = Math.sqrt(
      Math.pow(startPoint.top - endPoint.top, 2) +
        Math.pow(startPoint.left - endPoint.left, 2)
    );
    return (distanceOnMap / zoom) * (distance / value);
  };

  const getRealMapDirection = (
    realMapDirection,
    planMarkerDirection,
    latestMoveDirection
  ) => {
    if (planMarkerDirection === latestMoveDirection) {
      return realMapDirection;
    } else {
      const arr = ["up", "right", "down", "left"];
      const arrOpposite = ["down", "left", "up", "right"];
      const index = arr.findIndex((item) => item === realMapDirection);
      return arrOpposite[index];
    }
  };

  const moveMarker = (
    latestMarker,
    realMapDirection,
    latestMoveDirection,
    nextWallDistance,
    info
  ) => {
    nextWallDistance = parseFloat(nextWallDistance);
    const currentMoveDirection = info.direction;
    // const currentRotate = info.action.rotate;
    // ADDNEW
    const currentRotate = info.angle;
    const currentWallDistance = parseFloat(info.wall_distance);

    const currentWallDistanceInMap = getDistanceInMap(currentWallDistance);
    const nextWallDistanceInMap = getDistanceInMap(nextWallDistance);
    //const defaultDistance = DEFAULT_DISTANCE_TO_MARKER / zoom;
    if (realMapDirection === "up") {
      if (latestMoveDirection === "right") {
        return {
          top:
            latestMarker.top +
            (currentMoveDirection === "turn" && Number(currentRotate) === 90
              ? -1
              : 1) *
              nextWallDistanceInMap,
          left: latestMarker.left + currentWallDistanceInMap,
        };
      } else {
        return {
          top:
            latestMarker.top +
            (currentMoveDirection === "turn" && Number(currentRotate) === 90
              ? 1
              : -1) *
              nextWallDistanceInMap,
          left: latestMarker.left - currentWallDistanceInMap,
        };
      }
    } else if (realMapDirection === "right") {
      if (latestMoveDirection === "right") {
        return {
          top: latestMarker.top + currentWallDistanceInMap,
          left:
            latestMarker.left +
            (currentMoveDirection === "turn" && Number(currentRotate) === 90
              ? 1
              : -1) *
              nextWallDistanceInMap,
        };
      } else {
        return {
          top: latestMarker.top - currentWallDistanceInMap,
          left:
            latestMarker.left +
            (currentMoveDirection === "turn" && Number(currentRotate) === 90
              ? -1
              : 1) *
              nextWallDistanceInMap,
        };
      }
    } else if (realMapDirection === "down") {
      if (latestMoveDirection === "right") {
        return {
          top:
            latestMarker.top +
            (currentMoveDirection === "turn" && Number(currentRotate) === 90
              ? 1
              : -1) *
              nextWallDistanceInMap,
          left: latestMarker.left - currentWallDistanceInMap,
        };
      } else {
        return {
          top:
            latestMarker.top +
            (currentMoveDirection === "turn" && Number(currentRotate) === 90
              ? -1
              : 1) *
              nextWallDistanceInMap,
          left: latestMarker.left + currentWallDistanceInMap,
        };
      }
    } else if (realMapDirection === "left") {
      if (latestMoveDirection === "right") {
        return {
          top: latestMarker.top - currentWallDistanceInMap,
          left:
            latestMarker.left +
            (currentMoveDirection === "turn" && Number(currentRotate) === 90
              ? -1
              : 1) *
              nextWallDistanceInMap,
        };
      } else {
        return {
          top: latestMarker.top + currentWallDistanceInMap,
          left:
            latestMarker.left +
            (currentMoveDirection === "turn" && Number(currentRotate) === 90
              ? 1
              : -1) *
              nextWallDistanceInMap,
        };
      }
    }
  };

  const handleInputChange = (event) => {
    setIsChange(true);
    // reset error states
    setErrorElement("");
    setErrorMessage("");

    if (btnRef.current) {
      btnRef.current.removeAttribute("disabled");
    }
    let { name, value } = event.target;
    if (name === "id" || name === "flight_plan_no") {
      value = Math.floor(value);
    }

    switch (name) {
      case "id":
        setPlan(update(plan, { flight: { id: { $set: value } } }));
        return;
      case "ver":
        setPlan(update(plan, { app: { ver: { $set: value } } }));
        return;
      case "created_time":
        setPlan(update(plan, { date: { $set: value } }));
        return;
      case "flight_plan_no":
        setPlan(
          update(plan, {
            flight: { flight_plan_no: { $set: value ? value : "" } },
          })
        );
        return;
      case "flight_plan_name":
        setPlan(
          update(plan, { flight: { flight_plan_name: { $set: value } } })
        );
        return;
      case "emergency_marker":
        setPlan(
          update(plan, { flight: { emergency_marker: { $set: value } } })
        );
        return;
      case "fTemp":
        setPlan(update(plan, { flight: { fTemp: { $set: value } } }));
        return;
      case "drone_type":
        setPlan(update(plan, { drone: { type: { $set: value } } }));
        return;
      case "speed":
        setPlan(update(plan, { flight: { speed: { $set: value } } }));
        return;
      case "frm_ver":
        setPlan(update(plan, { drone: { frm_ver: { $set: value } } }));
        return;
      case "place":
        setPlan(update(plan, { user: { place: { $set: value } } }));
        return;
      case "division":
        setPlan(update(plan, { user: { division: { $set: value } } }));
        return;
      case "capture_distance":
        setPlan(update(plan, { captureDistance: { $set: value } }));
        return;
      default:
        return;
    }
  };

  const addMoreMarker = (markerContentTobeCopied, index) => {
    let newMarker = cloneDeep(markerContentTobeCopied);
    newMarker.id = markerContentTobeCopied.id + 1;
    newMarker.rtn = false;
    newMarker.markerId = generateUUIDv4();

    const flightMarker = [...plan.flight.flight_marker];
    flightMarker.splice(index, 0, newMarker);

    setMarkerData(flightMarker);
    setIsChangeMarker(true);
  };

  const updateMarker = (marker, position) => {
    setErrorMessage("");
    setErrorElement("");
    setIsChangeMarker(true);
    let newMarker = update(markerData, { [position]: { $set: marker } });
    setMarkerData(newMarker);
  };

  const deleteMarker = (index) => {
    let markers = [...plan.flight.flight_marker];
    markers.splice(index, 1);
    setMarkerData(markers);
    setIsChangeMarker(true);
  };

  const validateForm = () => {
    if (!plan.flight.flight_plan_name) {
      setErrorMessage(t("plan.error.empty_plan_name"));
      setErrorElement("plan_name");

      return false;
    }
    if (!plan.drone.type) {
      setErrorMessage(t("plan.error.invalid_drone_type"));
      setErrorElement("drone_type");
      return false;
    }

    const speed = Number.parseFloat(plan.flight.speed);
    if (isNaN(speed) || speed <= 0) {
      setErrorMessage(t("plan.error.invalid_speed"));
      setErrorElement("speed");
      return false;
    }

    const planNo = Number.parseInt(plan.flight.flight_plan_no);
    if (isNaN(planNo) || planNo < 101 || planNo > 299) {
      setErrorMessage(t("plan.error.invalid_plan_no"));
      setErrorElement("flight_plan_no");
      return false;
    }

    // if (!plan.flight.emergency_marker && developerMode) {
    //   setErrorMessage(t("plan.error.empty_emergency_marker"));
    //   setErrorElement("emergency_marker");
    //   return false;
    // }

    // if (!plan.user.place) {
    //   setErrorMessage(t("plan.error.empty_place"));
    //   setErrorElement("place");
    //   return false;
    // }

    // if (!plan.user.division) {
    //   setErrorMessage(t("plan.error.empty_division"));
    //   setErrorElement("division");
    //   return false;
    // }

    if (
      plan.flight.flight_marker.length > 0 &&
      plan.flight.flight_marker[0].direction &&
      !["landed", "left", "right"].includes(
        plan.flight.flight_marker[0].direction
      )
    ) {
      setErrorMessage(t("plan.error.invalid_first_marker"));
      setErrorElement("0.direction");
      return false;
    }

    return plan.flight.flight_marker.every((marker, index) => {
      let markerId = parseFloat(marker.id);
      if (
        isNaN(markerId) ||
        !Number.isInteger(markerId) ||
        markerId < 1 ||
        markerId > 100
      ) {
        setErrorMessage(t("plan.error.invalid_marker_id"));
        setErrorElement(index + ".id");
        return false;
      }
      let markerHeight = parseFloat(marker.height);
      if (isNaN(markerHeight) || markerHeight < 0) {
        setErrorMessage(t("plan.error.invalid_marker_height"));
        setErrorElement(index + ".height");
        return false;
      }
      let markerHoverT = parseFloat(marker.hover_t);
      if (
        developerMode &&
        (isNaN(markerHoverT) || markerHoverT < 0 || markerHoverT > 999)
      ) {
        setErrorMessage(t("plan.error.invalid_hover_t"));
        setErrorElement(index + ".hover_t");
        return false;
      }
      let markerWallDistance = parseFloat(marker.wall_distance);
      if (isNaN(markerWallDistance) || markerWallDistance <= 0) {
        setErrorMessage(t("plan.error.invalid_wall_distance"));
        setErrorElement(index + ".wall_distance");
        return false;
      }
      if (!marker.direction) {
        setErrorMessage(t("plan.error.invalid_direction"));
        setErrorElement(index + ".direction");
        return false;
      }
      if (marker.angle === null || marker.angle === "") {
        setErrorMessage("Angle is empty");
        setErrorElement(index + ".angle");
        return false;
      }
      let markerDistance = parseFloat(marker.marker_distance);
      if (isNaN(markerDistance) || markerDistance < 0) {
        setErrorMessage(t("plan.error.invalid_marker_distance"));
        setErrorElement(index + ".marker_distance");
        return false;
      }
      return true;
    });
  };

  const clickSave = () => {
    if (!validateForm()) {
      return;
    }
    const currentUser = AuthService.getCurrentUser();
    plan.user.corpId = currentUser.corpId;
    plan.user.corpName = currentUser.corpName;
    const frontendVer = FE_VER;
    if (plan.app.ver === "") {
      plan.app.ver = frontendVer;
    }

    const planRequest = cloneDeep(plan);
    planRequest.flight.flight_marker.map((item) => {
      delete item.markerId;
      return item;
    });

    plan.kirihaMode = isKirihaMode;
    planRequest.kirihaMode = isKirihaMode;

    validFormChange();

    if (action === "edit") {
      doPost(Constants.PLAN_UPDATE_FLIGHT_URL, planRequest, () => {
        NotificationManager.success(t("put.success"), "", 2000);
        props.updateCallBack(plan);
      });
    } else {
      doPost(Constants.PLAN_CREATE_FLIGHT_URL, plan, () => {
        NotificationManager.success(t("post.success"), "", 2000);
        props.createCallBack(plan);
      });
    }
  };

  const handleSaveMapAdvance = () => {
    var formData = new FormData();
    const endpoint = Constants.PLAN_UPDATE_MAP_EDITOR_URL;
    formData.append("id", planId);
    formData.append(
      "mapEditorContent",
      JSON.stringify({
        ...mapEditorContent,
        markers: markerInMap,
        scale: scale,
        direction: direction,
        zoom: zoom,
        firstMarker: firstMarker,
      })
    );
    formData.append(
      "planContent",
      JSON.stringify(
        update(props.plan.planContent, {
          flight: { flight_marker: { $set: flightMarkers } },
        })
      )
    );

    if (imageFileChange) {
      formData.append("file", imageFileChange);
    }

    if (isMapChange) {
      setIsMapChange(false);
      axios
        .post(endpoint, formData, getHeader("multipart/form-data"))
        .then((response) => {
          if (accountType === "robot") {
            NotificationManager.success(t("planMap.update_plan"), "", 2000);
          } else {
            NotificationManager.success(
              t("planMap.updated_flight_plan"),
              "",
              2000
            );
          }
          props.handleMarkerAdvanceSaveSuccess(response.data, action);
        })
        .catch(function (error) {
          if (accountType === "robot") {
            NotificationManager.error(
              t("planMap.could_not_add_move_plan"),
              "",
              2000
            );
          } else {
            NotificationManager.error(t("planMap.the_flight_plan"), "", 2000);
          }
          setIsMapChange(true);
        });
    }

    if (isChange) {
      const currentUser = AuthService.getCurrentUser();
      plan.user.corpId = currentUser.corpId;
      plan.user.corpName = currentUser.corpName;
      const frontendVer = FE_VER;
      if (plan.app.ver === "") {
        plan.app.ver = frontendVer;
      }
      doPost(Constants.PLAN_UPDATE_FLIGHT_URL, plan, () => {
        NotificationManager.success(t("put.success"), "", 2000);
        props.updateCallBack(plan);
        setIsChange(false);
      });
    }
  };

  const handleZoomChange = (zoomValue) => {
    setZoom(zoomValue);
  };

  const handleChangeImageFile = (e) => {
    if (e.target.files[0]) {
      setIsMapChange(true);
      if (e.target.files[0].type !== "application/pdf") {
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        setImageFileChange(e.target.files[0]);
        reader.onloadend = function (e) {
          let url = reader.result;
          setMapEditorImage(url);
          // reset direction, scale, zoom
          setFirstMarker({});
          setScale(null);
          setDirection("");
          setZoom(0);
        };
      } else {
        let file = e.target.files[0];
        let fileReader = new FileReader();
        fileReader.onload = function () {
          let pdfData = new Uint8Array(this.result);
          // Using DocumentInitParameters object to load binary data.
          let loadingTask = pdfjs.getDocument({ data: pdfData });
          loadingTask.promise.then(
            function (pdf) {
              console.log("PDF loaded");

              // Fetch the first page
              let pageNumber = 1;
              pdf.getPage(pageNumber).then(function (page) {
                console.log("Page loaded");

                let scale = 1.0;
                let viewport = page.getViewport({ scale: scale });

                // Prepare canvas using PDF page dimensions
                let canvas = document.createElement("canvas");
                let context = canvas.getContext("2d");
                canvas.height = viewport.height;
                canvas.width = viewport.width;

                // Render PDF page into canvas context
                let renderContext = {
                  canvasContext: context,
                  viewport: viewport,
                };
                let renderTask = page.render(renderContext);
                renderTask.promise.then(function () {
                  let url = canvas.toDataURL();
                  // Split the base64 string in data and contentType
                  let block = url.split(";");
                  // Get the content type
                  let contentType = block[0].split(":")[1]; // In this case "image/gif"
                  // get the real base64 content of the file
                  let realData = block[1].split(",")[1]; // In this case "iVBORw0KGg...."

                  // Convert to blob
                  let blob = b64toBlob(realData, contentType);
                  setImageFileChange(blob);
                  setMapEditorImage(url);
                  // reset direction, scale, zoom
                  setFirstMarker({});
                  setScale(null);
                  setDirection("");
                  setZoom(0);
                });
              });
            },
            function (reason) {
              // PDF loading error
              console.error(reason);
            }
          );
        };
        fileReader.readAsArrayBuffer(file);
      }
    }
  };

  function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    let byteCharacters = atob(b64Data);
    let byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);

      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      let byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    let blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  const handleScaleChange = (s) => {
    let newScale = { ...scale };
    // check value is change
    setIsMapChange(true);

    if (s.value) {
      newScale = { ...newScale, value: s.value };
    } else {
      newScale = {
        ...newScale,
        startPoint: s.startPoint,
        endPoint: s.endPoint,
      };
    }
    setScale(newScale);
  };

  const handleDirectionChange = (d) => {
    setIsChangeFirstMarker(true);
    setIsMapChange(true);
    setDirection(d);
  };

  const handleFirstMarkerChange = (firstMarkerValue) => {
    setIsChangeFirstMarker(true);
    setIsMapChange(true);
    setFirstMarker(firstMarkerValue);
  };

  const handleUpdatePlanContent = (content) => {
    setIsChangeFirstMarker(true);
    setFlightMarkers(content);
  };

  const handleClearSetingFirstMarker = () => {
    setIsChangeFirstMarker(false);
    setIsMapChange(true);
    setMarkerInMap([]);
    setDirection(null);
    setFirstMarker({
      id: 1,
      top: 0,
      left: 0,
    });
  };

  // Tab Map
  const handleSaveMap = (e) => {
    var formData = new FormData();
    formData.append("type", "update");
    formData.append("id", props.plan.id);
    let endpoint = imageMapFileChange
      ? Constants.PLAN_ADD_URL
      : Constants.PLAN_UPDATE_URL;
    if (imageMapFileChange) {
      formData.append("file", imageMapFileChange);
      formData.append("mapContent", JSON.stringify(markersChange));
    } else {
      formData.append("mapContent", JSON.stringify(markersChange));
    }

    if (isMapChange) {
      setIsMapChange(false);
      axios
        .post(endpoint, formData, getHeader("multipart/form-data"))
        .then((response) => {
          if (accountType === "robot") {
            NotificationManager.success(t("planMap.update_plan"), "", 2000);
          } else {
            NotificationManager.success(
              t("planMap.updated_flight_plan"),
              "",
              2000
            );
          }
          props.handleMarkerSettingSaveSuccess(response.data);
        })
        .catch(function (error) {
          if (accountType === "robot") {
            NotificationManager.error(
              t("planMap.could_not_add_move_plan"),
              "",
              2000
            );
          } else {
            NotificationManager.error(t("planMap.the_flight_plan"), "", 2000);
          }
          setIsMapChange(true);
        });
    }

    if (isChange) {
      const currentUser = AuthService.getCurrentUser();
      plan.user.corpId = currentUser.corpId;
      plan.user.corpName = currentUser.corpName;
      const frontendVer = FE_VER;
      if (plan.app.ver === "") {
        plan.app.ver = frontendVer;
      }
      doPost(Constants.PLAN_UPDATE_FLIGHT_URL, plan, () => {
        NotificationManager.success(t("put.success"), "", 2000);
        props.updateCallBack(plan);
        setIsChange(false);
      });
    }
  };

  const handleChangeImageMapFile = (e) => {
    if (e.target.files[0]) {
      setIsMapChange(true);
      if (e.target.files[0].type !== "application/pdf") {
        let reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);

        setImageMapFileChange(e.target.files[0]);

        reader.onloadend = function (e) {
          let url = reader.result;
          setMapImage(url);
          setMarkersChange([]);
        };
      } else {
        let file = e.target.files[0];
        let fileReader = new FileReader();
        fileReader.onload = function () {
          let pdfData = new Uint8Array(this.result);
          // Using DocumentInitParameters object to load binary data.
          let loadingTask = pdfjs.getDocument({ data: pdfData });
          loadingTask.promise.then(
            function (pdf) {
              console.log("PDF loaded");

              // Fetch the first page
              let pageNumber = 1;
              pdf.getPage(pageNumber).then(function (page) {
                console.log("Page loaded");

                let scale = 1.0;
                let viewport = page.getViewport({ scale: scale });

                // Prepare canvas using PDF page dimensions
                let canvas = document.createElement("canvas");
                let context = canvas.getContext("2d");
                canvas.height = viewport.height;
                canvas.width = viewport.width;

                // Render PDF page into canvas context
                let renderContext = {
                  canvasContext: context,
                  viewport: viewport,
                };
                let renderTask = page.render(renderContext);
                renderTask.promise.then(function () {
                  let url = canvas.toDataURL();
                  // Split the base64 string in data and contentType
                  let block = url.split(";");
                  // Get the content type
                  let contentType = block[0].split(":")[1]; // In this case "image/gif"
                  // get the real base64 content of the file
                  let realData = block[1].split(",")[1]; // In this case "iVBORw0KGg...."

                  // Convert to blob
                  let blob = b64toBlob(realData, contentType);
                  setImageMapFileChange(blob);
                  setMapImage(url);
                  setMarkersChange([]);
                });
              });
            },
            function (reason) {
              // PDF loading error
              console.error(reason);
            }
          );
        };
        fileReader.readAsArrayBuffer(file);
      }
    }
  };

  const handleMakerChange = (markers) => {
    setIsMapChange(true);
    setMarkersChange(markers);
  };

  const calculateDistanceTotal = () => {
    return plan.flight.flight_marker.reduce((sum, curr) => {
      const partialDistance = parseFloat(curr.marker_distance);
      return partialDistance ? sum + partialDistance : sum;
    }, 0);
  };

  const handleSortableMarker = (data) => {
    setIsChangeMarker(true);
    setMarkerData(data);
  };

  const renderFirstTab = () => {
    const dataFaceCsvDownload = faceKirihaData.map(item => item.split(','));
    const dataMarkerCsvDownload = markerKirihaData.map(item => item.split(','));

    const numberOfMarker = [...new Set(plan.flight.flight_marker.map((item) => item.id))].length;
    const totalDistance = calculateDistanceTotal().toFixed(1);

    return (
      <>
        {KIRIHA_MODE &&
          <>
            <div className="form-csv">
              <div className="form-item form-vertical form-kiriha_mode">
                <span>Kiriha Mode</span>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={Boolean(isKirihaMode)}
                    onChange={handleChangeKirihaMode}
                  />
                  <span className="slider round"></span>
                </label>
              </div>

              <div className={"form-item form-vertical form-capture_distance" + (isKirihaMode ? "" : " disable-kr")}>
                <span>Capture <br /> Distance(m)</span>
                <label>
                  <input
                    type="number"
                    name="capture_distance"
                    onChange={handleInputChange}
                    min="0"
                    value={plan.captureDistance}
                  />
                </label>
              </div>

              <div className={"form-item form-vertical form-face_csv" + (isKirihaMode ? "" : " disable-kr")}>
                <button onClick={() => setIsOpenEditFaceKiriha(true)}>
                  <Images.IconKirihaIcon color="currentColor" />
                  Face data
                </button>
                {faceCsvUploadedInfo && 
                  <span>updated at <br /> {timeConverter(momentTimezone(faceCsvUploadedInfo.updatedAt).tz("Asia/Tokyo").unix(), t)}</span>
                }
              </div>

              <div className={"form-item form-vertical form-marker_csv" + (isKirihaMode ? "" : " disable-kr")}>
                <button onClick={() => setIsOpenEditMarkerKiriha(true)}>
                  <Images.IconScan color="currentColor" />
                  Marker Data
                </button>
                {markerCsvUploadedInfo &&
                  <span>updated at <br /> {timeConverter(momentTimezone(markerCsvUploadedInfo.updatedAt).tz("Asia/Tokyo").unix(), t)}</span>
                }
              </div>
            </div>
            {isOpenEditFaceKiriha &&
              <KirihaEditTableComponent 
                isOpenEditKiriha={isOpenEditFaceKiriha} 
                setIsOpenEditKiriha={setIsOpenEditFaceKiriha} 
                kirihaData={faceKirihaData}
                handleUpdate={(newCsv, mode) => updateCsvFile(newCsv, mode)}
                csvUploadedInfo={faceCsvUploadedInfo}
                mode={faceKirahaMode}
                dataCsv={dataFaceCsvDownload}
              />
            }
            {isOpenEditMarkerKiriha &&
              <KirihaEditTableComponent 
                isOpenEditKiriha={isOpenEditMarkerKiriha} 
                setIsOpenEditKiriha={setIsOpenEditMarkerKiriha} 
                kirihaData={markerKirihaData}
                handleUpdate={(newCsv, mode) => updateCsvFile(newCsv, mode)}
                csvUploadedInfo={markerCsvUploadedInfo}
                mode={markerKirihaMode}
                dataCsv={dataMarkerCsvDownload}
              />
            }
          </>
        }

        <div className="form-marker">
          <div className="marker_total">
            <span>
              {t("plan.drone.number_of_markers")}：{numberOfMarker}  |  {t("plan.drone.total_distance")}：{totalDistance}m
            </span>
          </div>
          <Reorder.Group
            values={markerData}
            onReorder={handleSortableMarker}
            className="marker-more"
          >
            <AnimatePresence>
              {markerData.map((marker, index) => (
                <FlightMarker
                  marker={marker}
                  position={index}
                  chargingStation={chargingStation}
                  key={marker.markerId}
                  updateMarker={updateMarker}
                  addMoreMarker={addMoreMarker}
                  deleteMarker={() => deleteMarker(index)}
                  errorElement={errorElement}
                  errorMessage={errorMessage}
                  onChangeKirihaMarker={onChangeKirihaMarker}
                  isKirihaMode={isKirihaMode}
                />
              ))}
            </AnimatePresence>
          </Reorder.Group>
        </div>
      </>
    );
  };

  const handleChangeKirihaMode = (e) => {
    const { checked } = e.target;
    setIsKirihaMode(checked);
    setIsChange(true);
  }

  const updateCsvFile = async (file, mode) => {
    if (props.plan) {
      const blob = new Blob([file.join('\n')], { type: 'text/csv;charset=utf-8;' })
      let formData = new FormData();
      formData.append('corpId', currentUser.corpId);
      formData.append('flightPlanNo', props.plan.planNo);
      formData.append('mode', mode);
      formData.append('file', blob);
      
      let method;

      switch (mode) {
        case faceKirahaMode:
          method = faceCsvUploadedInfo ? 'put' : 'post';
          break;
      
        case markerKirihaMode:
          method = markerCsvUploadedInfo ? 'put' : 'post';
          break;
      
        default: break;
      }
      const url = Constants.CSV;

      try {
        const res = await axios[method](url, formData, {
          headers: { corpKey: currentUser.corpKey }
        });
        switch (mode) {
          case faceKirahaMode:
            setFaceKirihaData(file);
            break;
          case markerKirihaMode:
            setMarkerKirihaData(file);
            break;
          default: break;
        }

        changeCsvSuccess(res.data, mode);
        NotificationManager.success("", "", 2000);
        const notiWrap = document.querySelector(".notification-container > div");
        if(notiWrap) {
          notiWrap.classList.add("noty-plan")
        }
        return true;
      } catch (error) {
        NotificationManager.error(t("common.error"), "", 2000);
        return false;
      }
    } else {
      NotificationManager.error(t("plan.error.invalid_csv"), "", 2000);
    }
  }

  const validFormChange = () => {
    setIsChangeMarker(false);
    setIsChange(false);
    setIsMapChange(false);
  }

  const onChangeKirihaMarker = (position) => {
    setErrorMessage("");
    setErrorElement("");
    setIsChangeMarker(true);
    const markerDataClone = [...markerData];
    const newMarker = markerDataClone.map((item, index) => {
      if (index === position) return { ...item, rtn: true };
      return { ...item, rtn: false };
    })
    setMarkerData(newMarker);
  }

  return (
    <div className="form-plan">
      <div className="form-head">
        <div className="form-head-row">
          <div className={"form-item form-plan_no" + (action === "edit" ? " form-disable" : "")}>
            <span>{t("map.flight_plan_number")}</span>
            <label>
              <input
                type="number"
                name="flight_plan_no"
                onChange={handleInputChange}
                min={101}
                max={299}
                step={1}
                value={plan.flight.flight_plan_no}
              />
              <span className="error-message d-inline ml-4">{" "}{errorElement === "flight_plan_no" ? errorMessage : ""}</span>
            </label>
          </div>

          <div className="form-item form-plan_name">
            <span>{t("plan.drone.plan_name")}</span>
            <label>
              <input
                type="text"
                placeholder={t("plan.plan_name")}
                name="flight_plan_name"
                onChange={handleInputChange}
                value={plan.flight.flight_plan_name}
                maxLength={255}
              />
              <span className="error-message d-inline ml-4">{" "}{errorElement === "plan_name" ? errorMessage : ""}</span>
            </label>
          </div>
          
          {developerMode && 
            <>
              <div className="form-item form-fTemp">
                <span>{t("plan.drone.fTemp")}</span>
                <label>
                  <input
                    disabled={!developerMode}
                    type="text"
                    name="fTemp"
                    onChange={handleInputChange}
                    value={plan.flight.fTemp}
                  />
                  <span className="error-message d-inline ml-4">{" "}{errorElement === "emergency_marker" ? errorMessage : ""}</span>
                </label>
              </div>

              <div className="form-item form-emergency_marker">
                <span>{t("plan.drone.emergency_marker")}</span>
                <label>
                  <input
                    disabled={!developerMode}
                    type="number"
                    name="emergency_marker"
                    onChange={handleInputChange}
                    min="0"
                    value={plan.flight.emergency_marker}
                  />
                  <span className="error-message d-inline ml-4">{" "}{errorElement === "emergency_marker" ? errorMessage : ""}</span>
                </label>
              </div>

              <div className="form-item form-date form-disable">
                <span>{t("plan.app.date")}</span>
                <label>
                <input
                    disabled
                    name="created_time"
                    type="text"
                    onChange={handleInputChange}
                    value={plan.date || moment(new Date())}
                  />
                  <span className="error-message d-inline ml-4">{" "}{errorElement === "date" ? errorMessage : ""}</span>
                </label>
              </div>
            </>
          }
        </div>

        <div className="form-head-row">
          <div className="form-item form-drone_type">
            <span>{t("plan.drone.drone_type")}</span>
            <label>
              <select
                name="drone_type"
                value={plan.drone.type}
                onChange={handleInputChange}
              >
                <option value="" disabled selected>
                  {t("common.please_select")}
                </option>
                {droneTypes.map((droneType, index) => {
                  return (
                    <option key={index} value={droneType.name}>
                      {droneType.name}
                    </option>
                  );
                })}
              </select>
              <Images.IconArrowDown />
              <span className="error-message d-inline ml-4">{" "}{errorElement === "drone_type" ? errorMessage : ""}</span>
            </label>
          </div>

          <div className="form-item form-speed">
            <span>{t("plan.drone.speed")}</span>
            <label>
              <input
                type="number"
                name="speed"
                onChange={handleInputChange}
                min="0"
                value={plan.flight.speed}
              />
              <span className="error-message d-inline ml-4">{" "}{errorElement === "speed" ? errorMessage : ""}</span>
            </label>
          </div>

          <div className="form-item form-place">
            <span>{t("plan.drone.place")}</span>
            <label>
              <input
                type="string"
                name="place"
                onChange={handleInputChange}
                value={plan.user.place}
              />
              <span className="error-message d-inline ml-4">{" "}{errorElement === "place" ? errorMessage : ""}</span>
            </label>
          </div>

          <div className="form-item form-division">
            <span>{t("plan.drone.division")}</span>
            <label>
              <input
                type="string"
                name="division"
                onChange={handleInputChange}
                min="0"
                value={plan.user.division}
              />
              <span className="error-message d-inline ml-4">{" "}{errorElement === "division" ? errorMessage : ""}</span>
            </label>
          </div>
        </div>

        <button
          ref={btnRef}
          className="btn-save-plan"
          onClick={() => {
            props.tabView === "map-advance"
              ? handleSaveMapAdvance()
              : props.tabView === "map"
              ? handleSaveMap()
              : clickSave();
          }}
        >
          {t("common.save")}
        </button>
      </div>
      <Tab.Container
        defaultActiveKey="view"
        activeKey={props.tabView}
        onSelect={props.handleChangeTab.bind(this)}
      >
        <div className="position-relative">
          <Nav variant="pills" className="flex-row">
            <Nav.Item>
              <Nav.Link eventKey="view">1 {t("plan.drone.tab_1")}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="map-advance" disabled={"add" === action}>
                2 {t("plan.drone.tab_2")}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="map" disabled={"add" === action}>
                3 {t("plan.drone.tab_3")}
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>

        <Tab.Content
          bsPrefix="tab-content"
          style={props.tabView === "view" ? { padding: "16px" } : null}
        >
          <Tab.Pane eventKey="view">{renderFirstTab()}</Tab.Pane>
          <Tab.Pane eventKey="map-advance">
            {props.plan != null ? (
              <PlanMap
                id={props.plan.id}
                handleChangeTab={props.handleChangeTab.bind(this)}
                imageFile={imageFileChange}
                mapEditorImage={mapEditorImage}
                flightMarkers={flightMarkers}
                direction={direction}
                scale={scale}
                zoom={zoom}
                firstMarker={firstMarker}
                isChangeFirstMarker={isChangeFirstMarker}
                markerInMap={markerInMap}
                plan={props.plan}
                handleScaleChange={handleScaleChange}
                handleSaveMap={handleSaveMapAdvance}
                handleZoomChange={handleZoomChange}
                handleChangeImageFile={handleChangeImageFile}
                handleDirectionChange={handleDirectionChange}
                handleFirstMarkerChange={handleFirstMarkerChange}
                handleUpdatePlanContent={handleUpdatePlanContent}
                handleClearSetingFirstMarker={handleClearSetingFirstMarker}
                action={action}
                tab={props.tabView}
              />
            ) : (
              ""
            )}
          </Tab.Pane>
          <Tab.Pane eventKey="map">
            {props.plan != null ? (
              <PlanMarkerSettings
                plan={props.plan}
                mapImage={mapImage}
                imageMapFileChange={imageMapFileChange}
                markersChange={markersChange}
                action={action}
                tab={props.tabView}
                handleChangeImageMapFile={handleChangeImageMapFile}
                handleSaveMap={handleSaveMap}
                handleMakerChange={handleMakerChange}
              />
            ) : (
              ""
            )}
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};

export default PlanContent;