import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import * as Images from "../images";
import { doPost, doPut } from "../../services/api.service";
import * as Constants from "../../utils/constants";
import Form from "react-validation/build/form";
import moment from "moment";
import cloneDeep from "lodash/cloneDeep";
import { NotificationManager } from "react-notifications";
import DatePicker from "react-datepicker";
import enUS from "date-fns/locale/en-US";
import ja from "date-fns/locale/ja";
import "react-datepicker/dist/react-datepicker.css";
import SelectCustom from "./SelectCustom";

const initialScheduleItem = {
  id: null,
  scheduleName: "",
  valid_flag: false,
  drone_id: "",
  minute: "",
  hour: "",
  day: "",
  month: "",
  dayOfWeek: "",
  marker: false,
  plan_no: "",
  plan_name: "",
  utc: false,
};
// Date.parse(moment(new Date()).format("YYYY-MM-DD HH:mm:ss")) / 1000
const selectRepeat = ["none", "every_week", "monthly", "every_day"];
const everyWeekView = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const asterisk = ["*"];
const dayOfWeekArr = ["*", "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const dayOfMonthArr = Array.from(Array(32).keys()).slice(1);
const monthArr = asterisk.concat(Array.from(Array(13).keys()).slice(1));

export default function ScheduleActions(props) {
  const {action, droneIds, planNoList, editScheduleDataItem, handleSubmitSuccess, handleDelete} = props
  const { t, i18n } = useTranslation();

  const [scheduleItemData, setScheduleItemData] = useState(initialScheduleItem)
  const [viewRepeat, setViewRepeat] = useState(""); // '', every_week, monthly, every_day
  const [dayOfWeekSelected, setDayOfWeekSelected] = useState([]);
  const [isDisableBtnSub, setIsDisableBtnSub] = useState(false);
  const [isCheckNotChooseDOW, setIsCheckNotChooseDOW] = useState(false);
  const [isCheckNotHouseMinute, setIsCheckNotHouseMinute] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    const lang = i18n.language;
    if(lang === 'en') {
      setSelectedLanguage(enUS);
    } else if (lang === 'ja') {
      setSelectedLanguage(ja);
    } 
  }, [])

  useEffect(() => {
    if(action === 'edit') {
      if(editScheduleDataItem.utc && editScheduleDataItem.utc !== 'false') {
        setSelectedDate(new Date(+editScheduleDataItem.utc * 1000))
      }
      setScheduleItemData(editScheduleDataItem)
      if (editScheduleDataItem.dayOfWeek !== "*") {
        setViewRepeat("every_week");
        setDayOfWeekSelected(editScheduleDataItem.dayOfWeek.split(","));
      } else if (editScheduleDataItem.day !== "*") {
        setViewRepeat("monthly");
      } else if (editScheduleDataItem.hour !== '*' && editScheduleDataItem.minute !== '*') {
        setViewRepeat("every_day");
      } else {
        setViewRepeat('')
      }
    }
  }, [action])

  function validateData(data) {
    if (!data) {
      return false;
    }
  }

  const transformNullToAsterisk = (schedule) => {
    for (var prop in schedule) {
      if (
        prop !== "plan_no" &&
        prop !== "scheduleName" &&
        Object.prototype.hasOwnProperty.call(schedule, prop) &&
        schedule[prop] === ""
      ) {
        schedule[prop] = "*";
      }
    }
  };

  const renderRepeatView = (param) => {
    switch (param) {
      case "every_week":
        return (
          <>
            <div className="every_week">
              {everyWeekView.map((item, index) => {
                return (
                  <div className="every_week-item" key={index}>
                    <label>
                      <input
                        id="dayOfWeek"
                        type="checkbox"
                        name="dayOfWeek"
                        checked={dayOfWeekSelected.includes(item)}
                        value={item}
                        onChange={handleChange}
                      />
                      <Images.IconCheck />
                    </label>
                    <span>{t(`common.${item}`)}</span>
                  </div>
                );
              })}
            </div>
            <span style={{ font: "11px 400 'Inter'", color: "#E51739" }} className={isCheckNotChooseDOW ? "" : "hidden"}>{t("schedule.pls_choose")}</span>
          </>
        );
      case "monthly":
        return (
          <>
            <div className="monthly">
              <label>{t("schedule.label.date")}</label>
              <div className="mask-select">
                <select
                  className="dps-select"
                  name="day"
                  value={scheduleItemData.day}
                  onChange={handleChange}
                >
                  {dayOfMonthArr.map((value, index) => {
                    return (
                      <option key={index} value={value}>
                        {value} 日
                      </option>
                    );
                  })}
                </select>
                <Images.IconSelect color="currentColor" />
              </div>
            </div>
          </>
        );
      case "every_day":
        return null;
      default:
        return null;
    }
  };

  const handleChangeRepeatView = (event) => {
    if (event.target.value === "monthly") {
      setScheduleItemData({ ...scheduleItemData, day: 1, minute: "*", hour: "*" });
    } else {
      setScheduleItemData({
        ...scheduleItemData,
        minute: "*",
        hour: "*",
        day: "*",
        month: "*",
        dayOfWeek: "*",
      });
    }
    setDayOfWeekSelected([]);
    setViewRepeat(event.target.value);
    setIsCheckNotChooseDOW(false);
    setIsCheckNotHouseMinute(false);
  };

  // end handle edit

  const handleSubmit = (event) => {
    event.preventDefault();

    let itemCandidate = cloneDeep(scheduleItemData);
    itemCandidate = {
      ...itemCandidate,
      dayOfWeek:
        dayOfWeekSelected.length > 0 ? dayOfWeekSelected.join(",") : "*",
    };
    transformNullToAsterisk(itemCandidate);
    if(action === 'add') {
      validateData(itemCandidate);
      if(viewRepeat === 'every_week' && itemCandidate.dayOfWeek === '*') {
        setIsCheckNotChooseDOW(true)
      } else if(viewRepeat && viewRepeat !== 'none' && itemCandidate.hour === '*' && itemCandidate.minute === '*') {
        setIsCheckNotHouseMinute(true);
      } else {
        doPost(Constants.SCHEDULE_C_URL, itemCandidate, () => {
          setScheduleItemData(initialScheduleItem);
          handleSubmitSuccess()
          setIsDisableBtnSub(false);
          NotificationManager.success(
            t("post.success"),
            "",
            2000
          );
        }, () => {
          setIsDisableBtnSub(false);
          NotificationManager.error(
            t("post.err"),
            "",
            2000
          );
        });
      }
    }
    
    if(action === 'edit') {
      let url = Constants.SCHEDULE_UD_URL + itemCandidate.id;
      if(viewRepeat === 'every_week' && itemCandidate.dayOfWeek === '*') {
        setIsCheckNotChooseDOW(true);
      } else if(viewRepeat && viewRepeat !== 'none' && itemCandidate.hour === '*' && itemCandidate.minute === '*') {
        setIsCheckNotHouseMinute(true);
      } else {
        doPut(url, itemCandidate, () => {
          // update schedule success
          setScheduleItemData(initialScheduleItem)
          handleSubmitSuccess()
          setIsDisableBtnSub(false);
          NotificationManager.success(
            t("put.success"),
            "",
            2000
          );
        }, () => {
          setIsDisableBtnSub(false);
          NotificationManager.error(
            t("put.err"),
            "",
            2000
          );
        });
      }
    }
  }

  const handleChange = (event) => {
    let { name, value, type, checked } = event.target;
    if (name === "dayOfWeek") {
      setIsCheckNotChooseDOW(false)
      checked
        ? setDayOfWeekSelected(dayOfWeekSelected.concat(value))
        : setDayOfWeekSelected(
            dayOfWeekSelected.filter((item) => item !== value)
          );
    }
    if (name === "day") {
      setDayOfWeekSelected([]);
      setScheduleItemData({ ...scheduleItemData, [name]: value });
    }
    if (name === "utc") {
      value = Date.parse(value) / 1000;
    }
    if (type === "checkbox") {
      value = checked;
    }
    setScheduleItemData({ ...scheduleItemData, [name]: value });
  }

  const handleChangeUtc = (date) => {
    setSelectedDate(date);
    setScheduleItemData({ ...scheduleItemData, utc: Date.parse(date) / 1000 });
  }

  const handleChangePlan = (plan_no, plan_name) => {
    setScheduleItemData({ ...scheduleItemData, plan_no, plan_name });
  }

  const handleChangeHourMinute = (e) => {
    const { value } = e.target;
    let hour = value.split(":")[0];
    let minute = value.split(":")[1];
    setScheduleItemData({ ...scheduleItemData, hour, minute });
    setIsCheckNotHouseMinute(false);
  };

  const renderFormScheduleAction = () => {
    let planNameSelected;
    planNoList.forEach((plan) =>
      plan.planNo === scheduleItemData.plan_no
        ? (planNameSelected = plan.planNo + " / " + plan.name)
        : null
    );
    
    if(action) {
      return(
        <Form className="form_add f-column f-center" onSubmit={handleSubmit}>
          <div className="form_add-head">
            <input
              type="text"
              name="scheduleName"
              value={scheduleItemData.scheduleName}
              placeholder={t("schedule.label.title")}
              onChange={handleChange}
            />
            <button type="submit" className="btn-save" disabled={isDisableBtnSub}>
              {t("schedule.save")}
            </button>
          </div>
  
          <div className="form_add-main">
            <div className="valid_flag form_checkbox">
              <label>
                <input
                  id="valid_flag"
                  type="checkbox"
                  className="dps-input w-auto"
                  name="valid_flag"
                  placeholder="ID"
                  checked={scheduleItemData.valid_flag}
                  value={scheduleItemData.valid_flag}
                  message={t("scan.please_enter")}
                  onChange={handleChange}
                />
                <Images.IconCheck />
              </label>
              <span>{t("schedule.label.valid_flag")}</span>
            </div>
  
            <div className="form_select">
              <label>{t("schedule.label.drone_id")}</label>
              <div className="mask-select">
                <Images.IconPlane />
                <select
                  disabled = {action === 'edit'}
                  className="dps-select "
                  name="drone_id"
                  value={scheduleItemData.drone_id}
                  required
                  onChange={handleChange}
                >
                  {action === 'edit' ? 
                    <option value="" selected>
                      {scheduleItemData.drone_id}
                    </option>
                    : 
                    <>
                      <option value="" disabled>
                        {t("schedule.label.select")}
                      </option>
                      {droneIds.map((value, index) => {
                        return (
                          <option key={index} value={value.deviceNo}>
                          {value.deviceNo}
                        </option>
                      );
                      })}
                    </>
                  }
                </select>
                {action === 'add' && <Images.IconSelect color="currentColor" />}
              </div>
            </div>
  
            <div className="form_select">
              <label>{t("schedule.label.plan")}</label>
              <div className="form_select_plan">
                <SelectCustom
                  planNameSelected={planNameSelected}
                  data={planNoList}
                  onChange={handleChangePlan}
                />
                <span>{t("common.or")}</span>
                <div
                  className="form_checkbox"
                  style={{ position: "relative", top: "0", right: "0" }}
                >
                  <label>
                    <input
                      id="marker"
                      type="checkbox"
                      className="dps-input w-auto"
                      name="marker"
                      placeholder="ID"
                      checked={scheduleItemData.marker}
                      value={scheduleItemData.marker}
                      message={t("scan.please_enter")}
                      onChange={handleChange}
                    />
                    <Images.IconCheck />
                  </label>
                  <span>{t("schedule.label.marker")}</span>
                </div>
              </div>
            </div>
  
            <div className="form_select">
              <label>{t("schedule.label.flight_date_ime")}</label>
              <div className="mask-select" style={{overflow: 'unset'}}>
                <Images.Icons name="IconSchedule" />
                <DatePicker
                  wrapperClassName='form_search-date-wrapper'
                  showIcon
                  showTimeInput
                  shouldCloseOnSelect
                  timeInputLabel={t("common.time")}
                  placeholderText={t("Search_Pagination.format-date") + " --:--"}
                  selected={selectedDate}
                  onChange={(date) => handleChangeUtc(date)}
                  locale={selectedLanguage}
                  dateFormat="yyyy/MM/dd HH:mm"
                  todayButton={t("common.today")}
                />
              </div>
            </div>
  
            <div className="form_repeat">
              <div className="form_select">
                <label>{t("schedule.label.repeat")}</label>
                <div className="mask-select">
                  <select
                    className="dps-select"
                    name="repeat"
                    onChange={handleChangeRepeatView}
                  >
                    {selectRepeat.map((value, index) => {
                      if (viewRepeat === value) {
                        return (
                          <option key={index} value={value} selected>
                            {t(`schedule.select.${value}`)}
                          </option>
                        );
                      } else
                        return (
                          <option key={index} value={value}>
                            {t(`schedule.select.${value}`)}
                          </option>
                        );
                    })}
                  </select>
                  <Images.IconSelect color="currentColor" />
                </div>
              </div>
              {(viewRepeat !== "none" && viewRepeat) && (
                <div className="form_repeat-content">
                  {renderRepeatView(viewRepeat)}
                  <div className="hour-minute">
                    <label>
                      {t("schedule.label.hour")}, {t("schedule.label.minute")}
                    </label>
                    <input
                      value={
                        scheduleItemData.hour + ":" + scheduleItemData.minute
                      }
                      type="time"
                      onChange={handleChangeHourMinute}
                    />
                    <span style={{ font: "11px 400 'Inter'", color: "#E51739" }} className={isCheckNotHouseMinute ? "" : "hidden"}>{t("schedule.pls_choose_house_minute")}</span>
                  </div>
                </div>
              )}
            </div>

            {action === 'edit' &&
              <button
                className="delete"
                onClick={(e) => handleDelete( e, editScheduleDataItem)}
              >
                <img src={require("../../assets/images/delete.png")} alt="" />
              </button>
            }
          </div>
        </Form>
      )
    } else{
      return (
        <div className="loading">
          <img
            alt="loading"
            src={require("../../assets/images/loading.gif")}
          />
        </div>
      )
    }
  }

  return (
    renderFormScheduleAction()
  );
}
