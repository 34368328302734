import React, { memo, useContext, useState } from 'react';
import { CSVLink } from 'react-csv';
import { isMobileOnly } from 'react-device-detect';
import * as Images from "../images";
import SensorChartComponent from './sensorChart';
import SensorTable from './SensorTable';

const SensorInfo = (props) => {
  const {
    isViewChart, 
    setIsViewChart, 
    sensorCsv, 
    sensorsChart, 
    sensorsInfo, 
    planNo, 
    context,
    isResultPage,
    isPreview,

    // setting sensor deviceStatus Page
    typeSetting,
    isConvertHour,
    minX, maxX, stepSizeX,
    minY, maxY, stepSizeY,
    settingMinX, settingMaxX, settingStepSizeX,
    settingMinY, settingMaxY, settingStepSizeY,
    changeOptionViewXAxis
  } = props;

  const {
    updateIsSettingLegendMB
  } = useContext(context);

  const [isViewFromSetting, setIsViewFromSetting] = useState(false);
  const [isSettingTime, setIsSettingTime] = useState(true);

  return (
    <div className={"sensors-result" + (isMobileOnly && isViewChart ? "" : " table-active")}>
      {!isPreview &&
        <div className="sensors-result-header result-main-header">
          <div className="actions">
            {!isPreview && isViewChart &&
              <>
                {isMobileOnly &&
                  <button className="btn-dataset" onClick={() => updateIsSettingLegendMB(true)}>
                    Dataset
                  </button>
                }
                <button className={'btn-setting' + (isViewFromSetting ? " active" : "")} onClick={() => setIsViewFromSetting(!isViewFromSetting)}>
                  <Images.IconSetting color="#5F55C4" />
                </button>
                {isViewFromSetting &&
                  <div className='form-setting'>
                    <div className='form-setting-header'>
                      <div className="switch-setting">
                        <button className={isSettingTime ? "active" : ""} onClick={() => setIsSettingTime(true)}>Time</button>
                        <button className={!isSettingTime ? "active" : ""} onClick={() => setIsSettingTime(false)}>Value</button>
                      </div>
                      <button onClick={() => setIsViewFromSetting(false)}>
                        <Images.IconClose />
                      </button>
                    </div>

                    <div className='form-setting-body'>
                    {isSettingTime ?
                      <>
                        <div className='form-item'>
                          <label>Min</label>
                          <input type={typeSetting} step={isConvertHour ? 2 : 1} value={minX} onChange={(e) => settingMinX(e)} />
                        </div>
                        <div className='form-item'>
                          <label>Max</label>
                          <input type={typeSetting} step={isConvertHour ? 2 : 1} value={maxX} onChange={(e) => settingMaxX(e)} />
                        </div>
                        <div className='form-item'>
                          <label>Step size</label>
                          <input type="number" value={stepSizeX} onChange={(e) => settingStepSizeX(e.target.value)} />
                        </div>
                      </>
                      :
                      <>
                        <div className='form-item'>
                          <label>Min</label>
                          <input type="number" value={minY} onChange={(e) => settingMinY(e.target.value)} />
                        </div>
                        <div className='form-item'>
                          <label>Max</label>
                          <input type="number" value={maxY} onChange={(e) => settingMaxY(e.target.value)} />
                        </div>
                        <div className='form-item'>
                          <label>Step size</label>
                          <input type="number" value={stepSizeY} onChange={(e) => settingStepSizeY(e.target.value)} />
                        </div>
                      </>
                    }
                      
                    </div>

                    <div className='form-setting-footer' style={!isSettingTime ? { opacity: 0, visibility: "hidden" } : undefined}>
                      <label className="form-radio">
                        <input type="radio" name="x-option" checked={!isConvertHour} defaultChecked onChange={() => changeOptionViewXAxis(false)} />
                        <span className='checkmark'></span>
                        Elapsed Time
                      </label>
                      <label className="form-radio">
                        <input type="radio" name="x-option" checked={isConvertHour} onChange={() => changeOptionViewXAxis(true)} />
                        <span className='checkmark'></span>
                        Hour and Minute
                      </label>
                    </div>
                  </div>
                }
              </>
            }
            {!isMobileOnly && 
              <CSVLink
                className="btn_csv"
                filename={`${sensorsChart.droneId}_${planNo}_${sensorsChart.flightNo}`}
                data={sensorCsv}
              >
                <Images.IconDownloadCsv color='#5F55C4' /> CSV
              </CSVLink>
            }
            <div className="switch-sensor-view">
              <button 
                className={!isViewChart ? "active" : undefined} 
                onClick={() => setIsViewChart(false)}
              >
                <Images.IconTableSensors />
                {!isMobileOnly && "Table"}
              </button>
              <button 
                className={isViewChart ? "active" : undefined} 
                onClick={() => setIsViewChart(true)}
              >
                <Images.IconChartSensors />
                {!isMobileOnly && "Chart"}
              </button>
            </div>
          </div>
        </div>
      }

      <SensorChartComponent
        context={context}
        isViewChart={isViewChart}
        isResultPage={isResultPage}
        isPreviewStatusPage={isPreview}
      />

      <SensorTable
        isViewChart={isViewChart}
        sensorsInfo={sensorsInfo}
      />
    </div>
  );
};

export default memo(SensorInfo);