import { memo, useEffect, useState, useRef } from "react";
import { IconSelect } from "../images";

const SelectCustomComponent = (props) => {
  const {
    data,
    value,
    defaultValue,
    className,
    id,
    onChange,
    firstTitle
  } = props;

  const [textSelected, setTextSelected] = useState("");
  const [isShowSelectList, setIsShowSelectList] = useState(false);

  const selectList = useRef(null);
  const selectWraper = useRef(null);

  useEffect(() => {
    function handleClick(event) {
      if (id) {
        if (!event.target.closest(`#${id}`)) {
          setIsShowSelectList(false);
        }
      } else {
        if (!event.target.closest(".dps-select-custom")) {
          setIsShowSelectList(false);
        }
      }
    }

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  useEffect(() => {
    if (selectList.current) {
      const windowHeight = window.innerHeight;
      const { 
        top, 
        bottom: dropdownPosition 
      } = selectWraper.current.getBoundingClientRect();
      const dropdownHeight = selectList.current.clientHeight;

      if (dropdownPosition + dropdownHeight > windowHeight) {
        selectList.current.classList.add("drop-up");

        if (dropdownHeight > top) {
          selectList.current.style.maxHeight = top + 'px';
        }
      } else {
        selectList.current.classList.remove("drop-up");
      }
    }
  }, [isShowSelectList])

  useEffect(() => {
    const itemSelected = data.find(item => String(item.value) === String(value));
    itemSelected ? setTextSelected(itemSelected.label) : setTextSelected("");
  }, [value])

  const handleChoose = (e, item) => {
    e.stopPropagation();

    setTextSelected(item.label);
    onChange(item.value);
    setIsShowSelectList(false);
  }

  return (
    <div className={"dps-select-custom" + (className ? " " + className : "")} id={id ? id : ""}>
      <div 
        className="dps-select-custom-content"
        ref={selectWraper}
        onClick={() => setIsShowSelectList(!isShowSelectList)}
      >
        <p className="item-selected">{textSelected}</p>

        {isShowSelectList &&
          <ul className="select-list ct-scroll" ref={selectList}>
            {firstTitle && 
              <li className="item title" onClick={e => e.stopPropagation()}>{firstTitle}</li>
            }
            {data.map(item => (
              <li 
                className={"item" + (item.value === value ? " selected" : "")}
                onClick={(e) => handleChoose(e, item)} 
              >
                {item.label}
              </li>
            ))}
          </ul>
        }
      </div>

      <IconSelect />
    </div>
  )
}

export default memo(SelectCustomComponent);