import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import FormInput from "react-bootstrap/Form";
import { withTranslation } from "react-i18next";
import AuthService from "../../services/auth.service";
import { NotificationManager } from "react-notifications";
import { useFormik } from "formik";
import * as Yup from "yup";

function SendEmail(props) {
  const { t, i18n, showModal, handleClose } = props;

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required(t("reset_password.validate_send_email"))
        .matches(/^\S+@\S+\.\S+$/, t("reset_password.error_invalid_email")),
    }),
    validateOnChange: false,
    onSubmit: async (values, { resetForm }) => {
      try {
        await AuthService.sendEmailGetNewPassword(values.email);
        NotificationManager.success(t("reset_password.message_send_email"));
        resetForm();
        handleClose();
      } catch (error) {
        console.log("test error login ", error.response.data.err_msg);
        NotificationManager.error(t("reset_password.error_response_data"));
      }
    },
  });

  const handleCloseModal = () => {
    handleClose();
    formik.resetForm();
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        // size="sm"
        // style={{ width: 400 }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title size="sm">
            {t("reset_password.header_modal_send_mail")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormInput.Label style={{ fontWeight: 500 }}>
            {t("reset_password.email_address")}
          </FormInput.Label>
          <FormInput onSubmit={formik.handleSubmit} id="my-form">
            <FormInput.Control
              // size="sm"
              //   name="sendEmail"
              name="email"
              type="email"
              placeholder="name@example.com"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </FormInput>
          <div className="message" style={{ marginBottom: 0, height: 15 }}>
            {formik.errors.email && formik.touched.email && (
              <p className="error" style={{ color: "red" }}>
                {formik.errors.email}
              </p>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" type="submit" variant="primary" form="my-form">
            {t("reset_password.send_request")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default withTranslation()(SendEmail);
