import React, {useEffect, useRef, useState} from 'react';
import {useDrop} from 'react-dnd';
import {MarkerDraggable} from './markerDraggable';
import update from 'immutability-helper';
import * as Constants from "../../utils/constants";
import {useTranslation} from "react-i18next";

const imageStyle = {
  objectFit: 'contain',
  maxWidth: '100%',
  maxHeight: '100%',
  borderRadius: '10px',
  margin: '0 auto',
  display: 'block'
};

const containerAreaStyle = {
  display: 'block',
  width: '100%',
  height: '100%',
  flexShrink: 0,
  flexGrow: 1,
};

const containerStyle = {
  position: 'relative',
  display: 'contents',
  margin: '0 auto',
};

let border = '1px solid rgba(180, 184, 188, 0.5)';

export const Map = (props) => {
  const {t, i18n} = useTranslation();
  const [markers, setMarkers] = useState(props.markers ? props.markers : []);
  const [loading, setLoading] = useState(false);
  const [imageSource, setImageSource] = useState("");
  const [zoom, setZoom] = useState(0);

  const ref = useMapDrop(markers, props);

  useEffect(() => {
    setLoading(false);
    setZoom(0);
    imageStyle.width = '';
    imageStyle.height = '';
    containerStyle.width = '';
    containerStyle.height = '';
    containerStyle.display = 'contents';
  }, [props.url]);

  useEffect(() => {
    setMarkers(props.markers ? props.markers : []);
  }, [props.markers]);

  useEffect(() => {
    if (props.url && props.tab === 'map') {
      setImageSource(props.url.includes('data:image') ?
          props.url
          : Constants.getResourceUrl() + props.url);
    }
  }, [props.url, props.tab]);

  useEffect(() => {
    let elem = document.getElementById('map-image-loaded-' + props.id);
    let containerRef = document.getElementById(
        'image-map-view-area-id-' + props.id);
    let zoom = 0;
    if (loading && elem && containerRef) {
      imageStyle.width = '';
      imageStyle.height = '';
      containerStyle.width = '';
      containerStyle.height = '';
      containerStyle.display = 'contents';

      let zoomX = containerRef.clientWidth / elem.width;
      let zoomY = containerRef.clientHeight / elem.height;

      zoom = Math.min(zoomX, zoomY);
      if (zoom !== 0) {
        containerStyle.height = elem.clientHeight * zoom;
        containerStyle.width = elem.clientWidth * zoom;
        containerStyle.display = 'block';
        imageStyle.height = elem.height * zoom;
        imageStyle.width = elem.width * zoom;

        zoom = imageStyle.height / elem.naturalHeight;
        setZoom(zoom);
      }
    }
  }, [loading]);
  const imageOnLoad = () => {
    setLoading(true);
  };

  return (
    props.url ?
      <div id={"image-map-view-area-id-" + props.id} style={{...containerAreaStyle}}>
        {
          !loading ?
            <div className='loading'>
              <img src={require('../../assets/images/loading.gif')} alt="loading"/>
            </div>
            : ''
        }
        <div style={{...containerStyle}} className={loading ? "image-map-view" : "invisible"}>
          <img 
            className="image-map-image" alt="imageMap" src={imageSource}
            style={{...imageStyle, border}}
            onLoad={imageOnLoad} ref={ref}
            id={"map-image-loaded-" + props.id}
          />
          {
            loading && props.markers && zoom !== 0 && props.action === 'setting_map' ?
              props.markers.map((marker, index) => {
                return (
                  <MarkerDraggable key={index} {...marker} zoom={zoom}/>
                );
              }) 
              : ''
          }
        </div>
      </div>
      :
      <p className="txt-red center notice"> {t('map.upload_image')}</p>
  );

};

function useMapDrop(markers, props) {
  const ref = useRef();
  const [{canDrop, isOver}, dropTarget] = useDrop({
    accept: 'marker',

    drop(item, monitor) {
      let zoom = ref.current.height / ref.current.naturalHeight;

      if (item.top !== undefined) {
        const delta = monitor.getDifferenceFromInitialOffset()
        const left = Math.round(item.left + delta.x / zoom);
        const top = Math.round(item.top + delta.y / zoom);
        moveBox(item, left, top);
      } else {
        const offset = monitor.getSourceClientOffset();
        if (offset && ref.current) {
          const dropTarget = ref.current.getBoundingClientRect();
          const left = (offset.x) - (dropTarget.left);
          const top = (offset.y) - (dropTarget.top);
          moveBox(item, left / zoom, top / zoom);
        }
      }
      border = '1px solid rgba(180, 184, 188, 0.5)';
      return undefined;
    },

    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const moveBox = (item, left, top) => {
    item['displayType'] = 'map';
    var index = markers.findIndex(function (c) {
      return c.id == item.id;
    });

    if (index >= 0) {
      props.onMarkerChange(update(markers, {
        [index]: {$merge: {left, top}},
      }));
    } else {
      props.onMarkerChange(update(markers, {
        $push: [{...item, left: left, top: top}],
      }));
    }
  };

  const isActive = canDrop && isOver;

  if (isActive) {
    border = '1px dashed #fc405b';
  } else if (canDrop) {
    border = '1px dashed #fc405b';
  }

  return elem => {
    ref.current = elem;
    dropTarget(ref);
  };
}