import React, {useEffect, useRef, useState} from "react";
import * as Images from '../images'

const MapSetScale = (props) => {
  const canvasRef = useRef(null);

  const [startPoint, setStartPoint] = useState({top: 0, left: 0});
  const [endPoint, setEndPoint] = useState({top: 0, left: 0});
  const [mouseDown, setMouseDown] = useState(false);
  const [isLineSet, setLineSet] = useState(false);
  const [isSet, setIsSet] = useState(false)

  useEffect(() => {
    if (props.scale && props.scale.startPoint) {
      setStartPoint(props.scale.startPoint);
      setEndPoint(props.scale.endPoint);
      setMouseDown(false);
      setLineSet(false)
      drawLine2(props.scale.startPoint, props.scale.endPoint);
      setIsSet(true)
    }
  }, [props.scale]);

  const drawLine2 = (start, end) => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.save();
    context.beginPath();
    context.moveTo(start.left, start.top);
    context.lineTo(end.left, end.top);
    context.lineWidth = 2;
    context.lineCap = "round";
    context.strokeStyle = '#ff0000';
    context.stroke();
    context.restore();
  };

  const drawLine = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.save();
    context.beginPath();
    context.moveTo(startPoint.left, startPoint.top);
    context.lineTo(endPoint.left, endPoint.top);
    context.lineWidth = 2;
    context.lineCap = "round";
    context.strokeStyle = '#ff0000';
    context.stroke();
    context.restore();
    setIsSet(true)
  };

  // MOUSE EVENTS
  const canvasMouseDown = (event) => {
    setStartPoint({
      left: parseInt(event.nativeEvent.offsetX),
      top: parseInt(event.nativeEvent.offsetY)
    });

    setMouseDown(true);
    setLineSet(false);
  };

  const canvasMouseUp = (event) => {
    setMouseDown(false);
    setLineSet(true);
    clickSet()
  };

  const canvasMouseOut = (event) => {
    console.log("Canvas mouse out")
  };

  const canvasMouseMove = (event) => {
    if (!isLineSet && mouseDown) {
      setEndPoint({
        left: parseInt(event.nativeEvent.offsetX),
        top: parseInt(event.nativeEvent.offsetY)
      });
    }
    if (mouseDown) {
      drawLine();
    }
  };
  // TOUCH EVENTS
  const canvasTouchStart = (event) => {
    event.preventDefault();
    event.stopPropagation();
    document.documentElement.style.overflow = 'hidden';
    const touchInCanvas = getTouchPos(canvasRef.current, event);
    setStartPoint(
        {left: parseInt(touchInCanvas.x), top: parseInt(touchInCanvas.y)});
    setMouseDown(true);
    setLineSet(false);

    return false;
  };

  const canvasTouchEnd = (event) => {
    event.preventDefault();
    event.stopPropagation();
    document.documentElement.style.overflow = 'auto';
    setMouseDown(false);
    setLineSet(true);
    clickSet()
    return false;
  };

  const canvasTouchMove = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const touchInCanvas = getTouchPos(canvasRef.current, event);
    if (!isLineSet && mouseDown) {
      setEndPoint(
          {left: parseInt(touchInCanvas.x), top: parseInt(touchInCanvas.y)});
    }
    if (mouseDown) {
      drawLine();
    }
    return false;
  };
  const canvasTouchCancel = (event) => {
    return false;
  };

  // Get the position of a touch relative to the canvas
  function getTouchPos(canvasDom, touchEvent) {
    var rect = canvasDom.getBoundingClientRect();
    return {
      x: touchEvent.touches[0].clientX - rect.left,
      y: touchEvent.touches[0].clientY - rect.top
    };
  }

  const clickSet = (event) => {
    props.updateScale({
      startPoint, endPoint
    });
  };

  return (
      <div className="position-absolute top-0">
        <canvas 
          className=" bg-white opacity-70  border border-dark"
          ref={canvasRef}
          width={props.style.width}
          height={props.style.height}
          onMouseDown={canvasMouseDown}
          onMouseUp={canvasMouseUp}
          onMouseMove={canvasMouseMove}
          onMouseOut={canvasMouseOut}

          onTouchStart={canvasTouchStart}
          onTouchEnd={canvasTouchEnd}
          onTouchCancel={canvasTouchCancel}
          onTouchMove={canvasTouchMove}
        />
        <div 
          style={{
            position: 'absolute', 
            transform: `translate(${isSet ? '-50%, -50%' : '0, 0'})`, 
            pointerEvents: 'none', 
            userSelect: 'none',
            top: `${isSet ? endPoint.top : 12}px`, 
            left: `${isSet ? endPoint.left : 12}px`, 
          }}
        >
          <Images.IconSetScale />
        </div>
      </div>
  );
};

export default MapSetScale;