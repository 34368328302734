import React, { useRef } from 'react';
import { isMobileOnly } from 'react-device-detect';

const SensorTable = (props) => {
  const {sensorsInfo, isViewChart} = props;

  const headerRefTableSensor = useRef(null);
  const scrollRefTableSensor = useRef(null);

  if(!isViewChart) {
    if (isMobileOnly) {
      return (
        <div className="table-sensor-custom-mb">
          {sensorsInfo.mapContentSensors?.length ?
            sensorsInfo.mapContentSensors.map(item => (
              <div className='table-sensor'>
                <div className='header'>
                  <strong>{item.marker && `Marker ${item.marker}`}</strong>
                  <span>{item.time}</span>
                </div>
                <div className='body'>
                  {Object.keys(sensorsInfo.unitSensor)?.map(sensorKey => (
                    <div className='row-item'>
                      <p>{sensorKey} {sensorsInfo.unitSensor[sensorKey]}</p>
                      <span>{item[sensorKey]}</span>
                    </div>
                  ))}
                </div>
              </div>
            ))
            :
            <p className='no-sensor-mb'>No sensor information</p>
          }
        </div>
      );
    }

    return (
      <div className="table-sensor-custom">
        <table className="header" ref={headerRefTableSensor}>
          <tbody>
            <tr>
              {sensorsInfo.uniqSensor.map(item => (
                <td key={'sensor-' + item}>
                  <span>{item}</span>
                  {
                    !(item === "marker" || item === "time") && 
                    <span style={{color: 'inherit', fontWeight: 'inherit', opacity: sensorsInfo.unitSensor[item] ? 1 : 0}}>
                      {"(" + sensorsInfo.unitSensor[item] + ")"}
                    </span>
                  }
                </td>
              ))}
            </tr>
          </tbody>
        </table>
        <div className="body" ref={scrollRefTableSensor}>
          <table>
            <tbody>
              {sensorsInfo.mapContentSensors.map(item => (
                <tr key={item.id + item.time}>
                  {sensorsInfo.uniqSensor.map(value => (
                    <td key={Date.now() + value}>
                      <span>{item[value]}</span>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
};

export default SensorTable;