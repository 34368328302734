import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Reorder, useDragControls, useMotionValue, animate } from "framer-motion";
import * as Images from "../../components/images";
import RadioComponent from "../form-action-utils/RadioComponent";

const inactiveShadow = "0px 0px 0px rgba(0,0,0,0.8)";

const FlightMarker = ({marker, key, position, chargingStation, addMoreMarker, updateMarker, deleteMarker, errorElement, errorMessage, onChangeKirihaMarker, isKirihaMode}) => {
  const { t } = useTranslation();
  const developerMode = Boolean(localStorage.getItem("developerMode") === "true");
  const KIRIHA_MODE = Boolean(JSON.parse(localStorage.getItem("user")).kirihaMode);

  const handleInputChange = (event) => {
    event.preventDefault();
    let { name, value } = event.target;
    if (name === "id") {
      value = parseInt(value);
    }
    if (name === 'angle') {
      if (!value) {
        value = '';
      }
      else {
        value = parseInt(value);
      }
    }
    let tempMarker = { ...marker, [name]: value };
    updateMarker(tempMarker, position);
    if (name === "action_type") {
      let tempMarker = { ...marker, action: { ...marker.action, "type": value } };
      updateMarker(tempMarker, position);
    }
    if (name === "action_hold_t") {
      let tempMarker = { ...marker, action: { ...marker.action, "hold_t": value } };
      updateMarker(tempMarker, position);
    }
    if (name === "action_rotate") {
      let tempMarker = { ...marker, action: { ...marker.action, "rotate": value } };
      updateMarker(tempMarker, position);
    }
  };

  const handleChangeKirihaMarker = () => {
    onChangeKirihaMarker(position);
  }
  
  const y = useMotionValue(0);
  const dragControls = useDragControls();
  const boxShadow = useMotionValue(inactiveShadow);

  useEffect(() => {
    let isActive = false;
    y.onChange((latest) => {
      const wasActive = isActive;
      if (latest !== 0) {
        isActive = true;
        if (isActive !== wasActive) {
          animate(boxShadow, "5px 5px 10px rgba(0,0,0,0.3)");
        }
      } else {
        isActive = false;
        if (isActive !== wasActive) {
          animate(boxShadow, inactiveShadow);
        }
      }
    });
  }, [y, boxShadow]);

  return (
    <Reorder.Item 
      key={key}
      value={marker} 
      className='marker_item' 
      dragListener={false} 
      dragControls={dragControls} 
      style={{ y, boxShadow , background: '#fff', userSelect: 'none'}}
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, x: 100 }}
      transition={{ duration: 0.25 }}
    >
      <div className="marker_item-number" style={
        errorElement === position + ".id" ? 
        {paddingBottom: '20px'}
        : undefined
      }>
        <span onPointerDown={(event) => dragControls.start(event)} className="icon-drag">
          <Images.IconChangeMarker />
        </span>

        <div className="form-marker">
          <label>Marker No.</label>
          <div className="form-marker-input">
            <Images.IconScan color="currentColor" />
            <input
              type="number"
              min={1}
              max={100}
              step={1}
              name="id"
              onChange={handleInputChange}
              value={marker.id}
            />
          </div>
          {KIRIHA_MODE && isKirihaMode &&
            <div className="rtn">
              <RadioComponent 
                name="rtn"
                subTitle="Return"
                onChange={handleChangeKirihaMarker}
                checked={marker.rtn}
              />
            </div>
          }
          <span 
            className="mt-1 error-message" 
          >
            {errorElement === position + ".id" ? errorMessage : ""}
          </span>
        </div>
      </div>
      
      <div className="marker_item-content">
        <div className="form-item form-marker-drone-angle">
          <span>{t('plan.marker.drone.angle')}</span>
          <label>
            <input
              className="dps-input-primary with-50percent"
              type="number"
              step={1}
              min={-180}
              max={180}
              name="angle"
              onChange={handleInputChange}
              value={marker.angle}
            />
            {errorElement === position + ".angle" && <span className="error-message d-inline">{errorMessage}</span>}
          </label>
        </div>

        <div className="form-item form-marker-drone-height">
          <span>{t('plan.marker.drone.height')}</span>
          <label>
            <input
              className="dps-input-primary with-50percent"
              type="number"
              min={0}
              name="height"
              onChange={handleInputChange}
              value={marker.height}
            />
            {errorElement === position + ".height" && <span className="error-message d-inline">{errorMessage}</span>}
          </label>
        </div>

        <div className="form-item form-marker-drone-wall_distance">
          <span>{t('plan.marker.drone.wall_distance')}</span>
          <label>
            <input
              className="dps-input-primary with-50percent"
              type="number"
              min={0}
              name="wall_distance"
              onChange={handleInputChange}
              value={marker.wall_distance}
            />
            {errorElement === position + ".wall_distance" && <span className="error-message d-inline">{errorMessage}</span>}
          </label>
        </div>

        <div className="form-item form-marker-drone-landing_port">
          <span>{t('plan.marker.drone.landing_port')}</span>
          <label>
            <select
              value={marker.isStation}
              name="isStation"
              onChange={handleInputChange}
            >
              <option value="" disabled selected>
                {t("common.please_select")}
              </option>
              <option value="false">{t("common.no")}</option>
              {chargingStation.map((item, index) => {
                return (
                  <option key={index} value={item.stationId}>
                    {item.stationId}
                  </option>
                );
              })}
              <option value="else">{t("common.others")}</option>
            </select>
            <Images.IconArrowDown />
            {errorElement === position + ".station" && <span className="error-message d-inline">{errorMessage}</span>}
          </label>
        </div>

        <div className="form-item form-marker-drone-direction">
          <span>{t('plan.marker.drone.direction')}</span>
          <label>
            <select
              value={marker.direction}
              name="direction"
              onChange={handleInputChange}
            >
              <option value="" disabled selected>
                {t("common.please_select")}
              </option>
              <option value="right">{t("common.right")}</option>
              <option value="left">{t("common.left")}</option>
              <option value="turn">{t("common.turn")}</option>
              <option value="up">{t("common.up")}</option>
              <option value="down">{t("common.down")}</option>
              <option value="landed">{t("common.landing")}</option>
            </select>
            <Images.IconArrowDown />
            {errorElement === position + ".direction" && <span className="error-message d-inline">{errorMessage}</span>}
          </label>
        </div>

        <div className="form-item form-marker-drone-marker_distance">
          <span>{t('plan.marker.drone.marker_distance')}</span>
          <label>
            <input
              type="number"
              min={0}
              name="marker_distance"
              onChange={handleInputChange}
              value={marker.marker_distance}
            />
            {errorElement === position + ".marker_distance" && <span className="error-message d-inline">{errorMessage}</span>}
          </label>
        </div>

        {developerMode && 
          <div className="form-item form-marker-drone-mTemp">
            <span>{t('plan.marker.drone.mTemp')}</span>
            <label>
              <input
                disabled={!developerMode}
                className="dps-input-primary"
                type="text"
                name="mTemp1"
                onChange={handleInputChange}
                value={marker.mTemp1 || ""}
              />
              {errorElement === position + ".mTemp" && <span className="error-message d-inline">{errorMessage}</span>}
            </label>
          </div>
        }
      </div>

      <div className="marker_item-action">
        <button className="btn-remove_marker" onClick={deleteMarker}>－</button>
        <button className="btn-add_marker" onClick={() => { addMoreMarker(marker, position + 1) }}>＋</button>
      </div>
    </Reorder.Item>
  );
};

export default FlightMarker;
