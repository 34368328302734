import React, {useEffect, useState} from 'react';
import {useDrag} from 'react-dnd';
import {getEmptyImage} from 'react-dnd-html5-backend';
import {Marker} from './marker';
import * as Images from '../images'
import {useTranslation} from "react-i18next";

function getStyles(props, isDragChange) {
  if (props.displayType === 'drag-area') {
    const transform = `translate3d(0px, 0px, 0)`;
    return {
      transform,
      WebkitTransform: transform,
    };
  }
  if (props.displayType === "dropped") {
    return {
      display: 'block'
    };
  } else {
    let transform = isDragChange ? 
      `translate3d(${props.left * props.zoom}px, ${props.top * props.zoom}px, 0)` : 
      `translate3d(30px, 30px, 0)`
    return {
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: props.index === 0 ? 1 : 0,
      transform,
      WebkitTransform: transform,
    };
  }
}

function getStyleButtonClear (isChangeFirstMarker, isSetFirstMarker) {
  const display = isChangeFirstMarker && isSetFirstMarker ? 'block' : 'none'
  return {
    display
  }
}

export const MarkerDraggable = (props) => {  
  const {t, i18n} = useTranslation();

  const [{isDragging}, preview ] = useDrag({
    item: {type: 'marker', ...props},

    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  let isDragChange = props.top && props.left ? true : false

  useEffect(() => {
    preview(getEmptyImage(), {captureDraggingState: true});
  }, []);

  const handleDirectionClick = (direction) => {
    props.selectDirection(direction);
  };

  const handleDoubleClick = () => {
    if (props.canDoubleClick) {
      props.openModal(props.id);
    }
  };

  return (
    <>
      <div className={props.index === 0 ? 'direction-container' : 'mask_marker'} style={getStyles(props, isDragChange)} onDoubleClick={handleDoubleClick}>
        <div className='temp-marker-drag' style={isDragChange ? {pointerEvents: 'all', opacity: 1} : null}>
          <div 
            className={"position-absolute arrow-left " + (props.index === 0 && props.isSetFirstMarker ? "visible" : "invisible")}
            onClick={() => handleDirectionClick('left')}
            style={{color: props.direction === 'left' ? '##FCD13E' : '#ffffff80'}}></div>
          <div 
            className={"position-absolute arrow-right " + (props.index === 0 && props.isSetFirstMarker ? "visible" : "invisible")}
            onClick={() => handleDirectionClick('right')}
            style={{color: props.direction === 'right' ? '##FCD13E' : '#ffffff80'}}
            ></div>
          <div 
            className={"position-absolute arrow-up " + (props.index === 0 && props.isSetFirstMarker ? "visible" : "invisible")}
            onClick={() => handleDirectionClick('up')}
            style={{color: props.direction === 'up' ? '##FCD13E' : '#ffffff80'}}
            ></div>
          <div 
            className={"position-absolute arrow-down " + (props.index === 0 && props.isSetFirstMarker ? "visible" : "invisible")}
            onClick={() => handleDirectionClick('down')}
            style={{color: props.direction === 'down' ? '##FCD13E' : '#ffffff80'}}
            ></div>
        </div>
        <Marker {...props} isDragChange={isDragChange}/>
        <div className="temp-marker-no-drag" style={{display: isDragChange || props.displayType || props.direction ? 'none' : 'block'}}>
          <Images.IconSetScale /> 
        </div>
      </div>
      <button className='btn_rs_first-marker' style={getStyleButtonClear(props.isChangeFirstMarker, props.isSetFirstMarker)} onClick={props.handleClearSetingFirstMarker}>{t('plan.tab_2.set_first_marker.btn_clear')}</button>
    </>
  );
};
