import React, { Component, Suspense } from "react";
import Menu from "../components/menu";
import * as Images from "../components/images";
import * as Constants from "../utils/constants";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import axios from "axios";
import * as Validator from "../utils/validator";
import { NotificationManager } from "react-notifications";
import CheckButton from "react-validation/build/button";
import { confirmAlert } from "react-confirm-alert";
import PlanContent from "../components/plan/planContent";
import { withTranslation } from "react-i18next";
import { getHeader } from "../services/api.service";
import { BrowserView, isTablet, TabletView } from "react-device-detect";
import update from "immutability-helper";
import { FE_VER } from "../utils/constants";
import { generateUUIDv4, timeConverter } from "../utils/utils";
import PaginationComponent from "../components/pagination";
import SearchTable from "../components/searchTable";

const tableHeaderDrone = [
  {
    title: "plan.drone.table_1",
  },
  {
    title: "plan.drone.table_2",
  },
  {
    title: "plan.drone.table_4",
  },
  {
    title: "plan.drone.table_5",
  },
  {
    title: "plan.drone.table_6",
  },
];
const tableHeaderRobot = [
  {
    title: "plan.robot.table_1",
  },
  {
    title: "plan.robot.table_2",
  },
  {
    title: "plan.robot.table_4",
  },
  {
    title: "plan.robot.table_5",
  },
  {
    title: "plan.robot.table_6",
  },
];

class Plan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: JSON.parse(localStorage.getItem("user")),
      accountType: JSON.parse(localStorage.getItem("user")).accountType,
      addPlan: {
        planNo: "",
        name: "",
        file: "",
      },
      isOpenDetail: false,
      isShowMenu: false,
      plans: null,
      active: 0,
      planDetail: null,
      loadingStatus: "loading",
      action: "view",
      tab: "view",
      isSort: false,
      uniqPlanNo: [],
      // Pagination
      planTotal: 0,
      pageSize: 20,
      pageNumber: 1,
      formSearch: {
        direction: 'asc',
        sort: 'planNo',
        planName: null,
        planNo: null,
        dateFrom: null,
        dataTo: null
      },
      // resizeWindow
      width: 0,
      height: 0,
    };
    this.inputRef = React.createRef();
    this.refBodyTable = React.createRef();
    this.refHeaderTable = React.createRef();

    //action: view, add, edit, map, map_scale, map_animation
    this.getPlanList();

    this.handleEdit.bind(this);
    this.handleDownload.bind(this);
    this.handleDelete.bind(this);
    this.addPlan.bind(this);
    this.renderFlightPlanArea.bind(this);
    this.handleShowMenu.bind(this);
    this.fileUploadButton.bind(this);

    document.documentElement.style.overflow = "hidden";
  }

  componentDidMount() {
    this.getUniqData()
    window.addEventListener('resize', this.useWindowSize.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.useWindowSize.bind(this));
  }

  componentDidUpdate(prevProps, prevStates) {
    if(prevStates.pageSize !== this.state.pageSize || 
       prevStates.pageNumber !== this.state.pageNumber || 
       prevStates.formSearch !== this.state.formSearch) {
      this.getPlanList()
    }

    if(prevStates.width !== this.state.width || prevStates.height !== this.state.height || this.state.width === 0 || this.state.loadingStatus === 'OK') {
      this.resizeTable();
    }
  }

  useWindowSize() {
    if (this.state.action === "view") {
      this.setState({ width: window.innerWidth, height: window.innerHeight });
    }
  };

  resizeTable() {
    const scrollHeight = this.refBodyTable.current?.scrollHeight;
    const clientHeight = this.refBodyTable.current?.clientHeight;
    const headerTable = this.refHeaderTable.current;
    if(headerTable) {
      if(scrollHeight > clientHeight) {
        headerTable.style.paddingRight = '16px';
      } else {
        headerTable.style.paddingRight = 0;
      }
    }
  }

  getPlanList() {
    const params = {
      ...this.state.formSearch,
      pageSize: this.state.pageSize,
      pageNumber: this.state.pageNumber,
    }

    axios
      .get(Constants.PLAN_SEARCH_URL, {params: params}, getHeader("application/json"))
      .then((res) => {
        if (!res.data.items) {
          this.setState({
            loadingStatus: "NOK",
            plans: null,
            planTotal: 0
          });
        } else {
          let data = res.data.items;
          this.setState({
            loadingStatus: "OK",
            plans: data,
            planTotal: res.data.totalItems
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  getUniqData () {
    axios.get(Constants.PLAN_LIST_URL, getHeader()).then(res => {
      if(res.data) {
        const uniqPlanNo = res.data.map(item => item.planNo);
        this.setState({
          uniqPlanNo
        })
      }
    })
  }

  addPlan(e, t) {
    e.preventDefault();
    //validate input data
    this.form.validateAll();

    if (this.checkBtnAdd.context._errors.length === 0) {
      if (this.state.addPlan.file[0]) {
        var formData = new FormData();

        formData.append("file", this.state.addPlan.file[0]);
        formData.append("name", this.state.addPlan.name);
        formData.append("planNo", this.state.addPlan.planNo);

        axios
          .post(
            Constants.PLAN_ADD_URL,
            formData,
            getHeader("multipart/form-data")
          )
          .then((response) => {
            this.getPlanList();
            this.inputRef.current.value = null;
            if (this.state.accountType === "robot") {
              NotificationManager.success(
                t("plan.robot.added_move_plan"),
                "",
                2000
              );
            } else {
              NotificationManager.success(
                t("plan.drone.added_flight_plan"),
                "",
                2000
              );
            }
          })
          .catch((error) => {
            this.inputRef.current.value = null;
            const isPlanExisted = error.response.data.err_code?.includes("exist") ?? false;
            if (this.state.accountType === "robot") {
              NotificationManager.error(
                t("plan.robot.error_move_plan"),
                "",
                2000
              );
            } else {
              NotificationManager.error(
                t(isPlanExisted ? "plan.drone.error_flight_plan_existed" : "plan.drone.error_flight_plan"), 
                "", 
                2000
              );
            }
          });
      } else {
        if (this.state.accountType === "robot") {
          NotificationManager.error(t("plan.robot.select_file_plan"), "", 2000);
        } else {
          NotificationManager.error(t("plan.drone.select_file_plan"), "", 2000);
        }
      }
    } else {
      if (this.state.accountType === "robot") {
        NotificationManager.error(t("plan.robot.select_file_plan"), "", 2000);
      } else {
        NotificationManager.error(t("plan.drone.select_file_plan"), "", 2000);
      }
    }
  }

  handleChangeFile(field, e) {
    let object = this.state.addPlan;
    object[field] = e.target.files;
    this.setState({ object });
    setTimeout(() => {
      document.getElementById("btn-sub").click();
    }, 10);
  }

  handleDelete(e, item, index, t) {
    e.stopPropagation();

    confirmAlert({
      title: t("dialog_delete.message_text"),
      message: "",
      buttons: [
        {
          label: t("common.ok"),
          onClick: () => {
            let request = Constants.PLAN_DELETE_URL + item.id;
            axios
              .delete(request, { headers: getHeader().headers })
              .then((response) => {
                if (this.state.active >= index && this.state.active > 0) {
                  this.setState({
                    active: this.state.active - 1,
                  });
                }
                this.getPlanList();
                NotificationManager.success(
                  t("dialog_delete.confirm_success"),
                  "",
                  2000
                );
              })
              .catch(function (error) {
                NotificationManager.error(
                  t("dialog_delete.confirm_error"),
                  "",
                  2000
                );
              });
          },
        },
        {
          label: t("dialog_delete.option_no"),
        },
      ],
    });
  }

  handleEdit(e, item, index) {
    e.stopPropagation();
    axios.get(Constants.PLAN_URL + item.id, getHeader()).then(res => {
      const planDetail = this.convertFlightMarkerFromPlanDetail(res.data);
      this.setState({
        planDetail,
        active: index,
        tab: "view",
        action: "edit",
        isOpenDetail: true,
      });
    })
  }

  handleDownload(e, item, t) {
    e.stopPropagation();
    let request = Constants.PLAN_DOWNLOAD_URL + item.id;
    axios
      .get(request, { headers: getHeader("application/octet-stream").headers })
      .then((response) => {
        let contentType = "application/json;charset=utf-8;";

        const downloadData = response.data;
        console.log("downloadData", downloadData);

        downloadData.user.corpId = Math.floor(downloadData.user.corpId);

        const frontendVer = FE_VER;
        if (downloadData.app.ver === "") {
          downloadData.app.ver = frontendVer;
        }
        const flightMarkers = downloadData.flight.flightMarker;
        downloadData.flight.flightMarker = flightMarkers.map((marker) => {
          marker.id = Math.floor(marker.id);
          if (marker.markerDistanceMechanism) {
            delete marker.markerDistanceMechanism;
          }
          if (!marker?.markerDistanceMechanism) {
            marker.markerDistanceMechanism = "sensor";
          }
          if (!marker?.markerSize) {
            marker.markerSize = 20;
          }
          return marker;
        });

        var blob = new Blob(
          [decodeURIComponent(encodeURI(JSON.stringify(downloadData)))],
          { type: contentType }
        );

        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = "plan-" + item.planNo + ".json";
        a.click();
      })
      .catch(function (error) {
        NotificationManager.error(
          t("download.download_failed"),
          "",
          2000
        );
      });
  }

  fileUploadButton(e) {
    e.preventDefault();
    this.inputRef.current.click();
  }

  addNewPlan(e) {
    e.preventDefault();
    this.setState({
      action: "add",
      active: -1,
      tab: "view",
      isOpenDetail: true,
    });
  }

  handleShowMenu() {
    this.setState({ isShowMenu: !this.state.isShowMenu });
  }

  render() {
    const { t, i18n } = this.props;
    const { isOpenDetail, isShowMenu } = this.state;

    return (
      <Suspense fallback="loading">
        <div
          className={
            "dps-menu-hamburger-button " +
            (isTablet
              ? "dps-menu-hamburger-button-tablet " +
                (isShowMenu ? "" : "dps-menu-hamburger-button-not-show")
              : "")
          }
          onClick={() => this.handleShowMenu()}
        >
          <Images.HamburgerButton />
        </div>
        <div
          className={
            "dps-menu " +
            (isTablet
              ? "dps-menu-tablet " + (isShowMenu ? "" : "dps-menu-not-show")
              : "")
          }
        >
          <Menu currentRouter={this.props.location.pathname} />
        </div>
        {isShowMenu && isTablet && <div className="dps-menu-back-ground"></div>}
        <div
          className={"dps-content " + (isTablet ? "dps-content-tablet" : "")}
        >
          <div className="dps-content-area">
            {!isOpenDetail && 
              <div className="plan-header">
                <SearchTable 
                  data={[
                    {
                      name: 'planName',
                      label: t('Search_Pagination.plan_name'),
                      typeSearch: 'text',
                    },
                    {
                      name: 'planNo',
                      label: t('Search_Pagination.plan_no'),
                      typeSearch: 'select',
                      subData: this.state.uniqPlanNo
                    },
                    {
                      name: 'dateFrom dateTo',
                      label: t('Search_Pagination.creation_date'),
                      typeSearch: 'date',
                    },
                  ]}
                  formSearch={this.state.formSearch}
                  onSearch={this.handleSearch}
                  className={this.state.action === 'view' ? undefined : 'hidden'}
                />

                <div className="actions-plan">
                  <button
                    onClick={this.addNewPlan.bind(this)}
                    className="btn-add-plan"
                  >
                    <Images.Icons name="IconAddSquare" />
                    {t("common.add")}
                  </button>
                  <Form
                    className="import-plan"
                    onSubmit={(e) => this.addPlan(e, t)}
                    ref={(c) => {
                      this.form = c;
                    }}
                  >
                    <div hidden>
                      <input
                        id="fileUpload"
                        ref={this.inputRef}
                        type="file"
                        className="dps-input"
                        name="planName"
                        placeholder={
                          this.state.accountType === "robot"
                            ? t("plan.robot.table_2")
                            : "plan.drone.plan_name"
                        }
                        message={
                          this.state.accountType === "robot"
                            ? t("plan.robot.message1")
                            : t("plan.drone.message1")
                        }
                        accept=".json"
                        validations={[Validator.required]}
                        onChange={this.handleChangeFile.bind(this, "file")}
                      />
                    </div>
                    <CheckButton
                      style={{ display: "none" }}
                      ref={(c) => {
                        this.checkBtnAdd = c;
                      }}
                    />
                    <button onClick={(e) => this.fileUploadButton(e)}>
                      {t("common.import")}
                    </button>
                    <button className="hidden" type="submit" id="btn-sub">
                      sub
                    </button>
                  </Form>
                </div>
              </div>
            }

            <div className=" dps-main-content ">
              <div className="dps-main-content-area flight-plan">
                {this.state.loadingStatus === "loading" ? (
                  <div className="loading">
                    <img
                      src={require("../assets/images/loading.gif")}
                      alt="loading"
                    />
                  </div>
                ) : (
                  this.renderFlightPlanArea(t)
                )}
              </div>
            </div>
          </div>
        </div>
      </Suspense>
    );
  }

  renderFlightPlanArea(t) {
    return (
      <>
        {!isTablet ? (
          <BrowserView className={"w-100"} style={{height: '100%'}}>
            {!this.state.isOpenDetail ? (
              <div className="flight-plan-area">{this.renderPlanList(t)}</div>
            ) : (
              <div className="flight-plan-detail">
                {this.renderTabView(t)}
                {this.state.action !== "add"
                  ? {
                      view: (
                        <button
                          className="btn-next_tab"
                          onClick={() => this.setState({ tab: "map-advance" })}
                        >
                          {t("plan.next_tab-2")}
                        </button>
                      ),
                      "map-advance": (
                        <button
                          className="btn-next_tab"
                          onClick={() => this.setState({ tab: "map" })}
                        >
                          {t("plan.next_tab-3")}
                        </button>
                      ),
                    }[this.state.tab]
                  : null}
              </div>
            )}
          </BrowserView>
        ) : (
          <TabletView style={{height: '100%'}}>
            <div className="flight-plan-area">
              {!this.state.isOpenDetail ? (
                this.renderPlanList(t)
              ) : (
                <div className="flight-plan-detail">
                  {this.renderTabView(t)}
                </div>
              )}
            </div>
          </TabletView>
        )}
      </>
    );
  }

  renderPlanList(t) {
    return (
      <div
        className={
          this.state.loadingStatus === "NOK"
            ? "flight-selector flight-selector-nodata"
            : "flight-selector"
        }
      > 
        {this.state.loadingStatus === "NOK" ? (
          <h1 className="txt-red txt-notice">
            {this.state.accountType === "robot"
              ? t("plan.robot.add_robot_plan")
              : t("plan.drone.no_data_flight_plan")}
          </h1>
        ) : (
          <>
            <div className="wrap_table">
              <div
                className="dps-list-f flight-plan-list"
              >
                <div className="header" ref={this.refHeaderTable}>
                  {this.renderItemHeaderTable(
                    this.state.accountType === "robot"
                      ? tableHeaderRobot
                      : tableHeaderDrone,
                    t
                  )}
                </div>
                <div className={"body " + (isTablet ? "tablet" : "")} ref={this.refBodyTable}>
                  {this.renderItemTable(this.state.plans, t)}
                </div>
              </div>
            </div>
            <div className="footer-page">
              <p className="total-list">{this.state.planTotal} {t('plan.total')}</p>
              <PaginationComponent 
                total={this.state.planTotal} 
                pageSize={this.state.pageSize} 
                pageNumber={this.state.pageNumber}
                handleChangePageSize={this.handleChangePageSize}
                handleChangePagination={this.handleChangePagination} 
              />
            </div>
          </>
        )}
      </div>
    );
  }

  handleChangePageSize = (e) => {
    this.setState({
      pageSize: e.target.value,
      pageNumber: 1
    })
  }

  handleChangePagination = (curentPage) => {
    this.setState({
      pageNumber: curentPage
    })
  }

  handleSearch = (event, dataSearch) => {
    event.preventDefault();
    
    this.setState({
      formSearch: {...this.state.formSearch, pageNumber: 1, ...dataSearch},
      pageNumber: 1
    })
  }

  createPlanSuccess(plan) {
    axios.get(Constants.PLAN_URL + 'detail/' + plan.flight.flight_plan_no, getHeader()).then(res => {
      this.setState({
        planDetail: update(res.data, { planContent: { flight: { flight_marker: {
          $set: plan.flight.flight_marker
        }}}}),
        tab: "view",
        action: "edit",
      });
    })
  }

  convertFlightMarkerFromPlanDetail(data) {
    const planContent = data.planContent;
    if (planContent.flight && Array.isArray(planContent.flight.flight_marker)) {
      const flightData = planContent.flight.flight_marker.map(marker => {
        return {
          ...marker,
          markerId: generateUUIDv4()
        };
      });

      const newPlanContent = {
        ...planContent,
        flight: {
          ...planContent.flight,
          flight_marker: flightData
        }
      };

      return { ...data, planContent: newPlanContent };
    }
    return data;
  }

  updatePlanSuccess(planContent) {
    // Update plancontent of current planDetail
    const name = planContent.flight
      ? planContent.flight.flight_plan_name
      : this.state.planDetail.name;
    const updatedPlan = {
      ...this.state.planDetail,
      planContent,
      kirihaMode: planContent.kirihaMode,
      name: name,
    };
    const index = this.state.plans.findIndex(
      (item) => item.id === updatedPlan.id
    );

    updatedPlan.mapContent = updatedPlan.mapContent?.filter(item => item.isTakeoff || item.isLanding || planContent.flight.flight_marker.some(it => it.id === item.id));

    this.setState({
      plans: update(this.state.plans, { [index]: { $set: updatedPlan } }),
      planDetail: updatedPlan,
    });
  }

  handleMarkerSettingSaveSuccess(data) {
    if (this.state.plans) {
      // Update this.state.planDetail : Image, MapContent
      const index = this.state.plans.findIndex((item) => item.id === data.id);
      const newData = update(data, { planContent: { flight: { flight_marker: { 
        $set: this.state.planDetail.planContent.flight.flight_marker
      }}}});
      this.setState({ planDetail: newData });
      this.setState({
        plans: update(this.state.plans, { [index]: { $set: data } }),
      });
    }
  }

  handleMarkerAdvanceSaveSuccess(data, act) {
    if (this.state.plans) {
      // Update this.state.planDetail : mapeditorImage, mapEditorContent
      const index = this.state.plans.findIndex((item) => item.id === data.id);
      const newData = update(data, { planContent: { flight: { flight_marker: { 
        $set: this.state.planDetail.planContent.flight.flight_marker
      }}}});
      this.setState({ planDetail: newData});
      this.setState({
        plans: update(this.state.plans, { [index]: { $set: data } }),
      });
    }
  }

  renderTabView(t) {
    return (
      <PlanContent
        action={this.state.action}
        tabView={this.state.tab}
        handleChangeTab={this.handleChangeTab.bind(this)}
        createCallBack={this.createPlanSuccess.bind(this)}
        updateCallBack={this.updatePlanSuccess.bind(this)}
        handleMarkerAdvanceSaveSuccess={this.handleMarkerAdvanceSaveSuccess.bind(this)}
        handleMarkerSettingSaveSuccess={this.handleMarkerSettingSaveSuccess.bind(this)}
        plan={this.state.action === "edit" ? this.state.planDetail : null}
        changeCsvSuccess={this.changeCsvSuccess.bind(this)}
      />
    );
  }

  handleChangeTab(tabName) {
    this.setState({
      tab: tabName,
    });
  }

  changeCsvSuccess(csvInfo, mode) {
    switch (mode) {
      case "face":
        this.setState({
          planDetail: update(this.state.planDetail, { faceCsvFile: { $set: csvInfo}, kirihaMode: { $set: true } }),
        });
        break;
      case "marker":
        this.setState({
          planDetail: update(this.state.planDetail, { markerCsvFile: { $set: csvInfo}, kirihaMode: { $set: true } }),
        });
        break;
      default: break;
    }
  }

  renderItemTable(data, t) {
    return data.map((item, index) => {
      return (
        <div
          className="list-item"
          key={index}
          onClick={(e) => this.handleEdit(e, item, index)}
          style={{ cursor: "pointer" }}
        >
          <div className="clmn">
            <Images.IconScan />
            {item.name}
          </div>
          <div className="clmn">{item.planNo}</div>
          <div className="clmn">{timeConverter(Date.parse(item.createdTime) / 1000, t)}</div>
          <div className="clmn">
            <button
              className="table-btn"
              onClick={(e) => this.handleDownload(e, item, t)}
            >
              <Images.Icons name="IconDownload" color="currentColor" />
            </button>
          </div>
          <div className="clmn">
            <button
              className="table-btn"
              onClick={(e) => this.handleDelete(e, item, index, t)}
            >
              <Images.Icons name="IconDelete" color="currentColor" />
            </button>
          </div>
        </div>
      );
    });
  }

  renderItemHeaderTable(data, t) {
    if (data != null) {
      return data.map((item, index) => {
        if (item.title.includes("table_1") || item.title.includes("table_2") || item.title.includes("table_4")) {
          return (
            <div
              onClick={this.sort.bind(this, item.title)}
              className="list_sort clmn"
              key={index}
              style={{
                cursor: "pointer",
                userSelect: "none",
                color: "#473DAF",
              }}
            >
              <div className="l-sort">
                {t(item.title)}
                <span
                  style={{
                    transform: `${
                      this.state.isSort && this.state.sortName === item.title ? "rotate(180deg)" : ""
                    }`,
                  }}
                >
                  <Images.IconSort color="currentColor" />
                </span>
              </div>
            </div>
          );
        }
        return <div className="clmn" key={index}>{t(item.title)}</div>;
      });
    }
  }

  sort(item) {
    let sortName, direction, isSort = false

    if(item.includes("table_1")) {
      sortName = "name"
    } else if(item.includes("table_2")) {
      sortName = "planNo"
    } else if(item.includes("table_4")) {
      sortName = "createdTime"
    }

    if(this.state.sortName === item) {
      isSort = !this.state.isSort
    } else {
      isSort = true
    }

    direction = isSort ? 'asc' : 'desc'

    this.setState({
      formSearch: {...this.state.formSearch, sort: sortName, direction},
      isSort,
      sortName: item
    });
  }
}

export default withTranslation()(Plan);