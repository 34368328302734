import React from "react";
import * as Images from "../images";

const MarkerEditPlan = (props) => {
  const { zoom } = props;
  const styleNormal = {
    width: "15px",
    height: "15px",
    color: "#ffffff",
    lineHeight: "15px",
    textAlign: "center",
    borderRadius: "50%",
    cursor: "move",
    position: props.displayType ? "static" : "absolute",
    top: 0,
    left: 0,
    zIndex: 1,
  };
  const styleTransform = {
    top: props.top * zoom,
    left: props.left * zoom,
  };

  const accountType = JSON.parse(localStorage.getItem("user")).accountType;

  return (
    <div
      className="marker"
      style={
        props.styleTransform
          ? { ...styleNormal, ...styleTransform }
          : { ...styleNormal }
      }
    >
      {accountType === "robot" ? (
        <Images.IconRobotStart color="#ffffff" />
      ) : (
        <Images.Icons name="IconFlight" color="#FCD13E" />
      )}
      <span
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          font: "700 7px 'Inter', sans-serif",
        }}
      >
        {props.id === 0 ? (
          <Images.IconArrow color="currentColor" />
        ) : props.id === -1 ? (
          <Images.IconArrow
            color="currentColor"
            style={{ transform: "rotate(180deg)", marginTop: "3px" }}
          />
        ) : (
          props.id
        )}
      </span>
    </div>
  );
};

export default MarkerEditPlan;
