import { memo, useCallback, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { isMobile, isMobileOnly } from "react-device-detect";
import { t } from "i18next";
import axios from "axios";

import * as Constants from "../../utils/constants";
import { getHeader } from "../../services/api.service";

const VideoResultComponent = (props) => {
  const { data, currentTime, updateCurrentTime, elementMainView, isFirstPlayVideo, setIsFirstPlayVideo, setIsDisplayAllSensor } = props;

  const [isMutilVideo, setIsMutilVideo] = useState(false);
  const [videoSrc, setVideoSrc] = useState(null);
  const [zoom, setZoom] = useState(false);
  const [videoToBeDisplayed, setVideoToBeDisplayed] = useState(0);
  const [isZoomImage, setIsZoomImage] = useState(false);
  const [zoomImage, setZoomImage] = useState('');

  const videoRef = useRef(null);
  const zoomButtonRef = useRef(null);

  useEffect(() => {
    const { videoUrl } = data;

    if (videoUrl) {
      let media = videoUrl[videoToBeDisplayed];
      let zoom = false;
      let currentVideoUrl = Constants.getResourceUrl() + media?.raw;
      if (media?.hd) {
        // currentVideoUrl = Constants.getResourceUrl() + media?.hd;
        zoom = true;
      } else {
        // currentVideoUrl = Constants.getResourceUrl() + media?.raw;
        zoom = false;
      }

      setIsMutilVideo(videoUrl.length > 1);
      setVideoSrc(currentVideoUrl);
      setZoom(zoom);
    } else {
      setIsMutilVideo(false);
      setVideoSrc(null);
    }
  }, [videoToBeDisplayed, data])

  useEffect(() => {
    setVideoToBeDisplayed(0)
  }, [data])

  useEffect(() => {
    onVideoCanPlay();
  }, [elementMainView])

  const onVideoPlay = (e) => {
    e.target.play();
    sessionStorage.setItem("isVideoPlay", 1)

    if (!isFirstPlayVideo) {
      setIsDisplayAllSensor(false);
      setIsFirstPlayVideo(true);
    }
  }

  const onVideoCanPlay = useCallback(() => {
    const isVideoPlay = sessionStorage.getItem("isVideoPlay");

    if (videoRef.current && isVideoPlay === "1") {
      videoRef.current.play();
    }
  }, [elementMainView])
  
  const onvideoLoaded = (e) => {
    e.target.currentTime = localStorage.getItem("currentTimeVideo");
  }

  const onVideoPause = (e) =>  {
    e.target.pause();
    sessionStorage.setItem("isVideoPlay", 0)
  }

  const onSeeking = (e) => {
    updateCurrentTime(e.target.currentTime);
  }

  const onTimeUpdate = (e) => {
    let currentTimeVideo = e.target.currentTime;
    if (currentTimeVideo !== currentTime) {
      updateCurrentTime(currentTimeVideo);
    }
    if (currentTimeVideo > 0.01) {
      localStorage.setItem("currentTimeVideo", currentTimeVideo);
    }
  }

  const onEnded = (e) => {
    e.target.currentTime = 0;
  }

  const handleZoomImage = (e, url) => {
    e.preventDefault();
    e.stopPropagation();

    setIsZoomImage(true);

    axios.get(
      Constants.ZOOM_IMAGE_URL, 
      {
        headers: getHeader().headers,
        params: { second: currentTime, videoName: url },
      }
    ).then((res) => {
      //get first data
      if (res.data && res.data.imageUrl) {
        setZoomImage(res.data.imageUrl);
      }
    });
  }

  const handleImageModalClose = () => {
    setIsZoomImage(false);
    setZoomImage("");
  }

  if (videoSrc) {
    return (
      <div className="streaming" style={isMutilVideo ? { paddingBottom: "30px" } : undefined}>
        <div className="video-gallery">
          <div className="video-area">
            <video
              controls
              onLoadedData={onvideoLoaded}
              onCanPlay={onVideoCanPlay}
              onPlay={onVideoPlay}
              onPause={onVideoPause}
              onSeeked={onSeeking}
              playsInline="inline"
              preload="metadata"
              src={videoSrc}
              onTimeUpdate={onTimeUpdate}
              onEnded={onEnded}
              ref={videoRef}
            >
              <source src={videoSrc} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            {zoom && 
              <button
                className="zoom-button"
                ref={zoomButtonRef}
                onClick={(e) => handleZoomImage(e, videoSrc)}
              >
                {t("video.button_zoom")}
              </button>
            }
          </div>
  
          <Modal
            className="edit-form image-zom-preview"
            show={isZoomImage}
            onHide={handleImageModalClose}
          >
            <Modal.Body>
              {zoomImage ?
                <img src={zoomImage} alt="" />
              :
                <h1>{t('result.text_zoom')}</h1>
              }
            </Modal.Body>
  
            <Modal.Header closeButton />
          </Modal>
        </div>

        {isMutilVideo && data.videoUrl &&
          <div className="action-change-video">
            {data.videoUrl.map((item, index) => {
              return (
                <label
                  className="input-radio-container"
                  key={index}
                >
                  <input
                    type="radio"
                    name="video-result"
                    value={index + 1}
                    key={item.id}
                    checked={index === videoToBeDisplayed}
                    onChange={() => setVideoToBeDisplayed(index)}
                  />
                  <span className="checkmark" style={isMobile ? { borderColor: '#3e3a39' } : undefined}></span>
                </label>
              );
            })}
          </div>
        }
      </div>
    )
  } else {
    return isMobileOnly && <p className="no-video">Not Video uploaded</p>
  }
}

export default memo(VideoResultComponent);